import "./SecondBtn.scss";
// import ArrowLeft from "../../../assets/images/arrowLeft.svg";
import Icon from "../../../../assets/icons/Icon";
type Props = {
  label: string;
  iconLeft?: string;
  iconRight?: string;
  width?: number;
  style?: any;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const SecondBtn: React.FC<Props> = ({
  label,
  iconLeft,
  iconRight,
  style,
  onClick,
}) => {
  return (
    <div className='second-btn' onClick={onClick} style={style}>
      {iconLeft && (
        <span className='icon'>
          <Icon icon={iconLeft} viewBox='0 0 320 512' color='white' size={16} />
        </span>
      )}

      <div className='label'>{label}</div>

      {iconRight && (
        <span className='icon'>
          <Icon
            icon={iconRight}
            viewBox='0 0 320 512'
            color='white'
            size={16}
          />
        </span>
      )}
    </div>
  );
};

export default SecondBtn;
