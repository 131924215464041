import cls from "./MyCourses.module.scss";
import Loading from "../../components/common/loading/Loading";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import AppHeader from "../../components/appHeader/AppHeader";
import MainBtn from "../../components/common/buttons/mainBtn/MainBtn";
import CourseItem from "../../components/courses/courseItem/CourseItem";
import { CollapsedSidebarContext } from "../../context/collapsedSidebarContext";
import { useContext, useEffect } from "react";

import { useCourses } from "../../api/course/course";
import { ICourse } from "../../interfaces/ICourse";
import { useUserProgress } from "../../api/user/user";

const Courses: React.FC = () => {
  const { collapsed } = useContext(CollapsedSidebarContext);

  const { data: enrolledCourses, isLoading: isLoadingCourses } = useCourses();
  const {
    // data: activityProgressData,
    // error: activityProgressError,
    // isLoading: isLoadingActivityProgress,
    refetch: refetchActivityProgress,
  } = useUserProgress();

  useEffect(() => {
    refetchActivityProgress();
  }, []);

  return (
    <div className={cls.root}>
      <SidebarMenu />
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />
        <div className={cls.container}>
          {isLoadingCourses && <Loading />}
          <div className={cls.header}>
            <div className={cls.name}>
              <h1 className={cls.title}>My Courses</h1>
              <h4 className={cls.subtitle}>
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. */}
              </h4>
            </div>
            <a href='https://visign.com/courses' target='_blank'>
              {" "}
              <MainBtn label='Buy New Course' width={230} height={42} />
            </a>
          </div>

          {enrolledCourses?.length === 0 && (
            <p
              style={{
                textAlign: "center",
                color: "white",
                padding: "30px",
                fontSize: "1.5rem",
              }}>
              No active courses were found!
            </p>
          )}

          <div className={cls.contents}>
            {enrolledCourses?.map((course: ICourse, index: number) => (
              <CourseItem data={course} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
