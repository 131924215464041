import cls from "./CallToActionPopUp.module.scss";
type Props = {
  width?: number;
  height?: number;
  message: string;
  onConfirm?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDeny?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};
const CallToActionPopUp: React.FC<Props> = ({
  width,
  height,
  message,
  onConfirm,
  onDeny,
}) => {
  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      <div className={cls.wrapper} style={{ width: width, height: height }}>
        <div className={cls.message}>{message}</div>
        <div className={cls.actions}>
          <button onClick={onDeny}>No</button>
          <button onClick={onConfirm}>Yes</button>
        </div>
      </div>
    </div>
  );
};

export default CallToActionPopUp;
