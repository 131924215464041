import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { CollapsedSidebarProvider } from "./context/collapsedSidebarContext";

import Activity from "./pages/activity/Activity";
import Courses from "./pages/myCourses/MyCourses";
import CourseOverview from "./pages/courseOverview/CourseOverview";
// import ProgramTasks from "./pages/programTasks/ProgramTasks";
import Settings from "./pages/settings/Settings";
import Help from "./pages/help/Help";
import Support from "./pages/support/Support";
import Login from "./pages/login/Login";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../src/context/authContext";
import MaintenanceService from "./api/maintenance/maintenanceService";
import { useQueryClient } from "react-query";
// import { GlobalChatService } from "./api/chat/chatService";
import { GlobalNotificationService } from "./api/notifications/notificationService";
// import UserProgressTrackingService from "./api/userProgressTracking/userProgressTrackingService";

import Maintenance from "./pages/maintenance/Maintenance";
import Faqs from "./pages/faqs/Faqs";
import { NotificationContextProvider } from "./context/notificationContext";
import Terms from "./pages/terms/Terms";
import Privacy from "./pages/privacy/Privacy";
import Walktrough from "./pages/walktrough/Walktrough";

const App: React.FC = () => {
  //to do -> refactor auth props and types
  const authInstance = useContext(AuthContext);
  const queryClient = useQueryClient();

  const [isMaintenance, setIsMaintenance] = useState<boolean>(false);

  const InitializeSystems = (onMaintenance: () => void): void => {
    // console.log("InitializeSystems......");

    // START MAINTENANCE SERVICE
    MaintenanceService.SubscribeForMessages(onMaintenance);
    MaintenanceService.Initialize().catch((err) =>
      console.log("START MAINTENANCE SERVICE", err)
    );

    // START GLOBAL CHAT SERVICE
    // GlobalChatService.Initialize().catch((err) =>
    //   console.log("START GLOBAL CHAT SERVICE", err)
    // );
    // START GLOBAL NOTIFICATION SERVICE
    GlobalNotificationService.Initialize().catch((err) =>
      console.log("START GLOBAL NOTIFICATION SERVICE", err)
    );
    // START USER PROGRESS TRACKING SERVICE
    // UserProgressTrackingService.GetProgress().catch((err) =>
    //   console.log("USER PROGRESS TRACKING SERVICE", err)
    // );
  };

  const UnInitializeSystems = (): void => {
    console.log("UninitializeSystems......");
    // GlobalChatService.Destruct();
    GlobalNotificationService.Destruct();
    queryClient.clear();
  };

  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    if (isMaintenance) return <Navigate to='/maintenance' />;
    return authInstance?.currentUser ? children : <Navigate to='/login' />;
  };

  const AlreadyAuth = ({ children }: { children: JSX.Element }) => {
    if (isMaintenance) return <Navigate to='/maintenance' />;
    return authInstance?.currentUser ? <Navigate to='/activity' /> : children;
  };

  const MaintenanceStatus = ({ children }: { children: JSX.Element }) => {
    return !isMaintenance ? <Navigate to='/activity' /> : children;
  };

  useEffect(() => {
    if (authInstance?.currentUser) {
      // console.log("currentUser", authInstance.currentUser);

      InitializeSystems(() => setIsMaintenance(true));
    } else {
      UnInitializeSystems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInstance]);

  return (
    <div className='VisignApp' style={{ height: "100%" }}>
      <NotificationContextProvider>
        <CollapsedSidebarProvider>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Navigate to='/login' />} />
              <Route
                path='/login'
                element={
                  <AlreadyAuth>
                    <Login />
                  </AlreadyAuth>
                }
              />
              <Route
                path='/activity'
                element={
                  <RequireAuth>
                    <Activity />
                  </RequireAuth>
                }
              />
              <Route
                path='/my-courses'
                element={
                  <RequireAuth>
                    <Courses />
                  </RequireAuth>
                }
              />

              <Route
                path='/course-overview/:courseId/:classId/:termId/:weekId/:videoId'
                element={
                  <RequireAuth>
                    <CourseOverview />
                  </RequireAuth>
                }></Route>

              {/* <Route
              path='/program-tasks'
              element={
                <RequireAuth>
                  <ProgramTasks />
                </RequireAuth>
              }
            /> */}
              <Route
                path='/settings'
                element={
                  <RequireAuth>
                    <Settings />
                  </RequireAuth>
                }
              />
              <Route
                path='/help/'
                element={
                  <RequireAuth>
                    <Help />
                  </RequireAuth>
                }
              />
              <Route
                path='/walktrough/'
                element={
                  <RequireAuth>
                    <Walktrough />
                  </RequireAuth>
                }
              />
              <Route
                path='/help/:faqCategoryId'
                element={
                  <RequireAuth>
                    <Faqs />
                  </RequireAuth>
                }
              />
              <Route
                path='/help/tickets'
                element={
                  <RequireAuth>
                    <Help />
                  </RequireAuth>
                }
              />

              <Route
                path='/help/tickets/:ticketId'
                element={
                  <RequireAuth>
                    <Help />
                  </RequireAuth>
                }
              />
              <Route
                path='/help/support'
                element={
                  <RequireAuth>
                    <Support />
                  </RequireAuth>
                }
              />

              <Route
                path='/terms'
                element={
                  <RequireAuth>
                    <Terms />
                  </RequireAuth>
                }
              />
              <Route
                path='/privacy'
                element={
                  <RequireAuth>
                    <Privacy />
                  </RequireAuth>
                }
              />

              <Route
                path='/maintenance'
                element={
                  <MaintenanceStatus>
                    <Maintenance />
                  </MaintenanceStatus>
                }
              />

              <Route
                path='*'
                element={
                  <RequireAuth>
                    <Activity />
                  </RequireAuth>
                }
              />
            </Routes>
          </BrowserRouter>
        </CollapsedSidebarProvider>
      </NotificationContextProvider>
    </div>
  );
};

export default App;
