import { useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  deleteComment,
  editComment,
} from "../../../api/classQuestions/classQuestions";
import {
  EReportedContentType,
  EReportType,
  IReport,
  submitReport,
} from "../../../api/report/reportService";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { IChatMessage } from "../../../interfaces/IChatMessage";

import { IClass } from "../../../interfaces/IClass";
import { CEditPayload, CPayload } from "../../../interfaces/IQuestion";
import { EServiceTier } from "../../../interfaces/IServiceTier";

// import { QEditPayload, QPayload } from "../../../interfaces/IQuestion";
import { UrlParams } from "../../../interfaces/UrlParams";
import CallToActionPopUp from "../../common/callToActionPopUp/CallToActionPopUp";
import ConfirmationPopUp from "../../common/confirmationPopUp/ConfirmationPopUp";
import EditComment from "./EditComment";
import cls from "./QuestionModMenu.module.scss";
import ReportTypeSelector from "./ReportTypeSelector";

type Props = {
  edit: boolean;
  remove: boolean;
  notify: boolean;
  questionId: string;
  chatMessage: IChatMessage;
  classTier: EServiceTier;
};

const ModMenu: React.FC<Props> = ({
  edit,
  remove,
  notify,
  questionId,
  chatMessage,
  classTier,
}) => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, classId, termId, weekId } = routeParameters;

  const queryClient = useQueryClient();

  const [modMenu, showModMenu] = useState<boolean>(false);

  const modMenuRef = useRef(null);
  const handleClickOutsideModMenu = (e: MouseEvent) => {
    showModMenu(false);
  };
  useOnClickOutside(modMenuRef, handleClickOutsideModMenu);

  const handleShowModMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    showModMenu(!modMenu);
  };

  /////////////// POPUPS ////////////////
  const [editCommentForm, showEditCommentForm] = useState<boolean>(false);
  const [successConfirmationUpdated, showSuccessConfirmationUpdated] =
    useState<boolean>(false);

  const [confirmationPopUpDelete, showConfirmationPopUpDelete] =
    useState<boolean>(false);
  const [successConfirmationDeleted, showSuccessConfirmationDeleted] =
    useState<boolean>(false);

  const [submitReportForm, showSubmitReportForm] = useState<boolean>(false);
  const [successConfirmationReported, showSuccessConfirmationReported] =
    useState<boolean>(false);

  const [errorConfirmationRequest, showErrorConfirmationRequest] =
    useState<boolean>(false);

  const invalidateQueries = () => {
    return queryClient.invalidateQueries([
      "comments-for-question",
      weekId,
      termId,
      {
        classId,
        serviceTier: classTier,
        courseId,
      },
      questionId,
    ]);
  };

  //////////////////////// DELETE COMMENT ////////////////////////

  const deleteCommentRQ = useMutation(deleteComment, {
    onSuccess: () => {
      console.log("Comment deleted successfully!");
      showSuccessConfirmationDeleted(true);
    },
    onError: (err) => {
      console.log("error comment deleted", err);
      showErrorConfirmationRequest(true);
    },
    onSettled: () => {
      //   setButtonDisabled(false);
    },
  });

  const handleConfirmDeleteComment = () => {
    const payload: CPayload = {
      questionId,
      classId,
      termId,
      courseId,
      weekId,
      commentId: chatMessage.id,
    };

    deleteCommentRQ.mutate(payload);
    console.log(payload);
    showConfirmationPopUpDelete(false);
  };

  const handleDenyConfirmation = () => {
    showConfirmationPopUpDelete(false);
  };

  //////EDIT QUESTION////////

  const updateCommentRQ = useMutation(editComment, {
    onSuccess: () => {
      console.log("Comment updated successfully!");
      showSuccessConfirmationUpdated(true);
    },
    onError: (err) => {
      console.log("error comment updated", err);
      showErrorConfirmationRequest(true);
    },
    onSettled: () => {
      //   setButtonDisabled(false);
    },
  });

  const handleEditComment = (data: string) => {
    if (!data) return;

    const payload: CEditPayload = {
      questionId,
      classId,
      termId,
      courseId,
      weekId,
      commentId: chatMessage.id,
      comment: data,
    };
    updateCommentRQ.mutate(payload);
    console.log(payload);
    showEditCommentForm(false);
  };

  //////////////////////// SUBMIT REPORT ////////////////////////

  const handleSubmitReport = async (val: EReportType) => {
    console.log("reporting");
    const qID = chatMessage.id;

    let payload: IReport = {
      questionId: questionId,
      messageId: qID,
      contentReported: EReportedContentType.CLASS_QUESTION_COMMENT,
      reportTypes: [val],
      classId,
      courseId,
      termId,
      weekId,
    };

    showSubmitReportForm(false);

    await submitReport(payload)
      .then((res) => {
        showSuccessConfirmationReported(true);
      })
      .catch((err) => {
        console.log(err);
        showErrorConfirmationRequest(true);
      });
  };

  if (!notify && !remove && !edit) return null;

  return (
    <div className={cls.root} ref={modMenuRef}>
      {editCommentForm && (
        <EditComment
          width={600}
          chatMessage={chatMessage}
          onSave={handleEditComment}
          onDeny={() => showEditCommentForm(false)}
        />
      )}

      {confirmationPopUpDelete && (
        <CallToActionPopUp
          message='Are you sure you want to delete this comment?'
          onConfirm={() => handleConfirmDeleteComment()}
          onDeny={() => handleDenyConfirmation()}
        />
      )}

      {successConfirmationDeleted && (
        <ConfirmationPopUp
          maxWidth={400}
          type='success'
          message='Comment successfully deleted!'
          onClick={() => [
            showSuccessConfirmationDeleted(false),
            invalidateQueries(),
          ]}
        />
      )}

      {successConfirmationUpdated && (
        <ConfirmationPopUp
          maxWidth={400}
          type='success'
          message='Comment successfully updated!'
          onClick={() => [
            showSuccessConfirmationUpdated(false),
            invalidateQueries(),
          ]}
        />
      )}

      {submitReportForm && (
        <ReportTypeSelector
          onDeny={() => showSubmitReportForm(false)}
          onSubmit={handleSubmitReport}
        />
      )}

      {successConfirmationReported && (
        <ConfirmationPopUp
          maxWidth={400}
          type='success'
          message='Report successfully submitted!'
          onClick={() => showSuccessConfirmationReported(false)}
        />
      )}

      {errorConfirmationRequest && (
        <ConfirmationPopUp
          maxWidth={400}
          type='error'
          message='Something went wrong! Please, try again later!'
          onClick={() => showErrorConfirmationRequest(false)}
        />
      )}

      <div className={cls.dots} onClick={handleShowModMenu}>
        ...
      </div>
      {modMenu && (
        <div className={cls.modList}>
          {edit && (
            <span
              onClick={() => [showEditCommentForm(true), showModMenu(false)]}>
              - Edit
            </span>
          )}
          {remove && (
            <span
              onClick={() => [
                showConfirmationPopUpDelete(true),
                showModMenu(false),
              ]}>
              - Delete
            </span>
          )}
          {notify && (
            <span
              onClick={() => [showSubmitReportForm(true), showModMenu(false)]}>
              - Notify Admin
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default ModMenu;
