import "./MainVideoPlayer.scss";
import { IVideo } from "../../../interfaces/IVideo";
import React, { useEffect, useRef, useState } from "react";
import UserProgressTrackingService, {
  TrackingVideo,
} from "../../../api/userProgressTracking/userProgressTrackingService";
import { useParams } from "react-router-dom";
import { UrlParams } from "../../../interfaces/UrlParams";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import { useUser } from "../../../api/user/user";
import { IUser } from "../../../interfaces/IUser";
import {
  IProgress,
  LectureProgress,
  VideoProgress,
} from "../../../interfaces/IProgress";

type Props = {
  videoData: IVideo | null;
  autoplay: boolean;
  videoHasEnded: () => void;
};
const MainVideoPlayer: React.FC<Props> = ({
  videoData,
  videoHasEnded,
  autoplay,
}) => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, termId, weekId, videoId } = routeParameters;

  const playerRef = useRef<ReactPlayer>(null);

  const { data: userData } = useUser();

  const [error, setError] = useState<boolean>(false);

  const getCurentVideoProgress = (
    user: IUser,
    courseId: string,
    termId: string,
    weekId: string,
    videoId: string
  ): number => {
    const termProgress: IProgress | undefined = user.progress?.find(
      (vProgress: IProgress) => vProgress.termId === termId
    );
    const weekProgress: LectureProgress | undefined =
      termProgress?.lectureProgress[weekId];

    const videoProgress: VideoProgress | undefined =
      weekProgress?.videoProgress[videoId];

    return videoProgress?.secondsWatched || 0;
  };

  const updateProgress = (e: OnProgressProps) => {
    if (!userData || !videoData || !weekId || !videoId || !termId || !courseId)
      return;

    const currentVideoProgress = getCurentVideoProgress(
      userData,
      courseId,
      termId,
      weekId,
      videoId
    );

    const trackingData: TrackingVideo = {
      weekId,
      videoId,
      termId,
      courseId,
      videoTimestamp: e.playedSeconds,
    };
    if (
      Math.round(e.playedSeconds) % 10 === 0 &&
      Math.round(e.playedSeconds) !== 0 &&
      e.playedSeconds !== videoData.lengthInSeconds &&
      e.playedSeconds > currentVideoProgress
    ) {
      // console.log("UserProgressTrackingService... ", trackingData);
      UserProgressTrackingService.Submit(trackingData).catch((e) =>
        console.log(e, "error .....UserProgressTrackingService")
      );
    }
  };

  const updateProgressOnSeek = (e: number) => {
    if (!userData || !videoData || !weekId || !videoId || !termId || !courseId)
      return;

    const currentVideoProgress = getCurentVideoProgress(
      userData,
      courseId,
      termId,
      weekId,
      videoId
    );

    const trackingData: TrackingVideo = {
      weekId,
      videoId,
      termId,
      courseId,
      videoTimestamp: e,
    };
    if (e > currentVideoProgress) {
      // console.log("UserProgressTrackingService...seeked ", trackingData);
      UserProgressTrackingService.Submit(trackingData).catch((e) =>
        console.log(e, "error .....UserProgressTrackingService")
      );
    }
  };

  const updateProgressOnEnded = () => {
    if (!userData || !videoData || !weekId || !videoId || !termId || !courseId)
      return;

    const trackingData: TrackingVideo = {
      weekId,
      videoId,
      termId,
      courseId,
      videoTimestamp: videoData.lengthInSeconds,
    };

    // console.log("UserProgressTrackingService...ended ", trackingData);
    UserProgressTrackingService.Submit(trackingData).catch((e) =>
      console.log(e, "error .....UserProgressTrackingService")
    );
    videoHasEnded();
  };

  useEffect(() => {
    setError(false);
  }, [courseId, termId, weekId, videoId]);

  if (!videoData) {
    return (
      <div
        className='course-video'
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
        }}>
        <p>No video found!</p>
      </div>
    );
  }
  return (
    <div className='player-wrapper'>
      <div className='ambientBg'>
        <img src={videoData.thumbnailSource || "/placeholder.webp"} alt='' />
      </div>

      {/* <iframe
        ref={playerRef}
        loading='lazy'
        id={videoData.id}
        src={videoData.source}
        frameBorder={0}
        allowFullScreen
        title={videoData.title}
        allow='autoplay; fullscreen; picture-in-picture'
      /> */}

      <ReactPlayer
        ref={playerRef}
        className='react-player'
        url={videoData?.source}
        width='100%'
        height='100%'
        controls
        config={{
          vimeo: {
            title: videoData?.title,
            playerOptions: {
              autoplay: autoplay,
              muted: false,
            },
          },
        }}
        onReady={() => {
          console.log("ready");
        }}
        onPlay={() => console.log("play")}
        onPause={() => console.log("pause")}
        onSeek={updateProgressOnSeek}
        onProgress={updateProgress}
        onEnded={updateProgressOnEnded}
        onDuration={(e) => console.log(e, "duration")}
        onError={() => setError(true)}
      />

      {error && (
        <p
          style={{
            display: "flex",
            position: "absolute",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}>
          Cannot load video or video was not found!
        </p>
      )}
    </div>
  );
};

export default MainVideoPlayer;
