import cls from "./Support.module.scss";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import AppHeader from "../../components/appHeader/AppHeader";
import React, { useContext, useState } from "react";
import { CollapsedSidebarContext } from "../../context/collapsedSidebarContext";

import MainBtn from "../../components/common/buttons/mainBtn/MainBtn";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/common/loading/Loading";

import { useMutation, useQueryClient } from "react-query";
import {
  submitSupportTicket,
  useSupportDepartments,
} from "../../api/support/support";
import { useUser } from "../../api/user/user";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "../../components/common/formInput/FormInput";
import TextareaInput from "../../components/common/textareaInput/TextareaInput";
import DepartmentSelector from "../../components/support/departmentSelector/DepartmentSelector";
import PrioritySelector from "../../components/support/prioritySelector/PrioritySelector";
import { ESupportTicketPriority } from "../../interfaces/ISupportTicket";
import ConfirmationPopUp from "../../components/common/confirmationPopUp/ConfirmationPopUp";

type FormInputs = {
  priority: string;
  department: string;
  message: string;
};

const schema = yup.object().shape({
  priority: yup.string(),
  department: yup.string().required("Support department is required!"),
  message: yup
    .string()
    .required("Message is required!")
    .min(20, "Message must be at least 20 characters long!")
    .max(1100, "Message must be at most 1100 characters long!"),
});
const Support: React.FC = () => {
  //get user data
  const { data: userData, isLoading: isLoadingUser } = useUser();
  // get support departments
  const { data: supportDepartments, isLoading: isLoadingSupportDepartments } =
    useSupportDepartments();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { collapsed } = useContext(CollapsedSidebarContext);
  const [newTicketId, setNewTicketId] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  ///
  const [successConfirmationSubmitted, showSuccessConfirmationSubmitted] =
    useState<boolean>(false);
  const [errorConfirmation, showErrorConfirmation] = useState<boolean>(false);
  ///

  const submitTicketRQ = useMutation(submitSupportTicket, {
    onMutate: () => setIsLoading(true),
    onSuccess: (data) => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("support-tickets");
      setNewTicketId(data.data.ticket.id);
      showSuccessConfirmationSubmitted(true);
    },
    onError: (err) => {
      console.log("error submit support ticket", err);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  ///////////////// handle submit form
  const { handleSubmit, control } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (data: FormInputs) => {
    console.log(data);
    submitTicketRQ.mutate({
      message: data.message,
      categoryId: data.department,
      // priority: data.priority as ESupportTicketPriority,
    });
  };

  return (
    <div className={cls.root}>
      {successConfirmationSubmitted && (
        <ConfirmationPopUp
          maxWidth={400}
          type='success'
          message='Thank you for submiting the ticket! Your ticket is currently under review.'
          onClick={() => navigate(`/help/tickets/${newTicketId}`)}
        />
      )}

      {errorConfirmation && (
        <ConfirmationPopUp
          maxWidth={400}
          type='error'
          message='Could not update submit your ticket!'
          onClick={() => showErrorConfirmation(false)}
        />
      )}

      <SidebarMenu />
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {(isLoadingUser || isLoadingSupportDepartments || isLoading) && (
            <Loading />
          )}

          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Support</div>
              <div className={cls.subtitle}>
                {/* Lorem ipsum dolor amet. */}
              </div>
            </div>
          </div>

          <div className={cls.contents}>
            <div className={cls.form}>
              <div className={cls.formTitle}>Open a ticket</div>
              {userData && supportDepartments && (
                <form action=''>
                  <div className={cls.row}>
                    <div className={cls.fields}>
                      <div className={cls.field}>
                        <FormInput
                          type='text'
                          label='First Name'
                          disabled={true}
                          defaultValue={userData.firstName}
                        />
                      </div>
                      <div className={cls.field}>
                        <FormInput
                          type='text'
                          label='Last Name'
                          disabled={true}
                          defaultValue={userData.lastName}
                        />
                      </div>

                      <div className={cls.field}>
                        <FormInput
                          type='email'
                          label='Email'
                          disabled={true}
                          defaultValue={userData.email}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={cls.row}>
                    <div className={cls.fields}>
                      <div className={cls.field}>
                        <FormInput
                          type='text'
                          label='Phone Number'
                          disabled={true}
                          defaultValue={userData.phoneNumber}
                        />
                      </div>
                      <div className={cls.field}>
                        <Controller
                          name='priority'
                          defaultValue={ESupportTicketPriority.LOW}
                          control={control}
                          render={({
                            field: { onChange },
                            fieldState: { error },
                          }) => (
                            <PrioritySelector
                              defaultValue={ESupportTicketPriority.LOW}
                              onChange={onChange}
                              error={error}
                            />
                          )}
                        />
                      </div>
                      <div className={cls.field}>
                        <Controller
                          name='department'
                          defaultValue=''
                          control={control}
                          render={({
                            field: { onChange },
                            fieldState: { error },
                          }) => (
                            <DepartmentSelector
                              onChange={(event) => {
                                const val = event.currentTarget.id;
                                return onChange(val);
                              }}
                              error={error}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={cls.row}>
                    <Controller
                      name='message'
                      defaultValue=''
                      control={control}
                      render={({
                        field: { onChange, onBlur, ref },
                        fieldState: { isTouched, isDirty, error },
                      }) => (
                        <TextareaInput
                          maxLength={1100}
                          height={300}
                          label='Message'
                          defaultValue=''
                          onChange={onChange}
                          onBlur={onBlur}
                          inputRef={ref}
                          isTouched={isTouched}
                          isDirty={isDirty}
                          error={error}
                        />
                      )}
                    />
                  </div>
                </form>
              )}

              <div className={cls.actions}>
                <MainBtn
                  label='Back'
                  height={44}
                  width={120}
                  transparent={true}
                  onClick={() => navigate("/help/tickets")}
                />

                <MainBtn
                  label='Submit'
                  width={140}
                  height={44}
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
