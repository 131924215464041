import cls from "./HelpTabPanel.module.scss";
import { useNavigate } from "react-router-dom";
import { IFaqCategory } from "../../interfaces/IFaq";

type Props = {
  faqCategories: IFaqCategory[];
  filter: string;
};

const HelpTabPanel: React.FC<Props> = ({ filter, faqCategories }) => {
  const navigate = useNavigate();

  const matchesFilter = (faqCategory: IFaqCategory, filter: string) => {
    return faqCategory.name.toLowerCase().includes(filter.toLowerCase());
  };

  return (
    <div className={cls.helpTab}>
      <div className={cls.list}>
        {/* <div className={cls.title}>More resources</div> */}

        {(filter.length > 2
          ? faqCategories.filter((u: IFaqCategory) => matchesFilter(u, filter))
          : faqCategories
        )?.map((faqCategory: IFaqCategory, index: number) => (
          <div
            className={cls.categoryItem}
            key={index}
            onClick={() => {
              navigate(`/help/${faqCategory.id}`);
            }}>
            {faqCategory.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HelpTabPanel;
