import cls from "./FormInput.module.scss";
import { FieldError } from "react-hook-form";
import React, { useState } from "react";

type Props = {
  type: "text" | "email" | "password" | "date";
  label?: string;
  width?: number;
  height?: number;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  maxLength?: number;
  isTouched?: boolean;
  isDirty?: boolean;
  error?: FieldError | undefined;
};

const FormInput: React.FC<Props> = ({
  type,
  width,
  height,
  defaultValue,
  label,
  placeholder,
  disabled,
  id,
  inputRef,
  onChange,
  onBlur,
  maxLength,
  isTouched,
  isDirty,
  error,
}) => {
  const [_type, setType] = useState(type);

  const handleShowHidePassword = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    if (_type === "password") {
      setType("text");
    }
    if (_type === "text") {
      setType("password");
    }
  };

  return (
    <div
      className={`${cls.root} ${type === "password" ? cls.passwordType : ""}`}
      style={{ width: width, height: height }}>
      {!placeholder && <label className={cls.label}>{label}</label>}
      <input
        maxLength={maxLength}
        id={id}
        type={_type}
        ref={inputRef}
        defaultValue={defaultValue}
        className={cls.input}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
      {type === "password" && (
        <span className={cls.showHidePassword} onClick={handleShowHidePassword}>
          {`${_type === "password" ? "SHOW" : "HIDE"}`}
        </span>
      )}

      <p className={cls.error}>{`${error ? error.message : ""}`}</p>
    </div>
  );
};

export default FormInput;
