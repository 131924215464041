import cls from "./ReportTypeSelector.module.scss";
// import cls from "./CategorySelector.module.scss";
import React, { useRef, useState } from "react";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import Icon from "../../../assets/icons/Icon";
import { EReportType } from "../../../api/report/reportService";

type Props = {
  width?: number;
  height?: number;
  onDeny: () => void;
  onSubmit: (val: EReportType) => void;
  defaultValue?: EReportType;
  // error?: FieldError | undefined;
};

const ReportTypeSelector: React.FC<Props> = ({
  width,
  height,
  defaultValue,
  onDeny,
  onSubmit,

  // error,
}) => {
  const refField = useRef(null);
  const [list, showList] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<EReportType>(
    defaultValue || EReportType.SPAM
  );

  const handleClickOutsideMenu = (e: MouseEvent) => {
    showList(false);
  };
  useOnClickOutside(refField, handleClickOutsideMenu);

  /////
  const handleShowList = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    showList(!list);
  };
  /////
  const handleSelectOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const val = e.target.value as EReportType;
    if (val === selectedOption) return;
    setSelectedOption(val);
    showList(false);
  };

  return (
    <div className={cls.root}>
      <div className={cls.wrapper} style={{ width: width, height: height }}>
        <div className={cls.body} ref={refField}>
          <div className={cls.label}>Notify Admin</div>

          <div className={cls.select} onClick={handleShowList}>
            <Icon
              icon='ArrowLeft'
              viewBox='0 0 320 512'
              size={18}
              className={list ? cls.expanded : ""}
            />

            <div className={cls.value}>{selectedOption}</div>
          </div>

          <div className={`${cls.options} ${list ? cls.show : ""}`}>
            {Object.values(EReportType).map(
              (val: EReportType, index: number) => {
                return (
                  <div key={index} className={cls.option}>
                    <label htmlFor={val}>{val}</label>
                    <input
                      name='select'
                      type='radio'
                      value={val}
                      id={val}
                      onChange={handleSelectOption}
                    />
                  </div>
                );
              }
            )}
          </div>
          <div className={cls.actions}>
            <button onClick={onDeny}>Back</button>
            <button onClick={() => onSubmit(selectedOption)}>Submit</button>
          </div>
        </div>
      </div>
      {/* <p className={cls.error}>{`${error ? error.message : ""}`}</p> */}
    </div>
  );
};

export default ReportTypeSelector;
