export enum ApiEndpoints {
  REGISTER = "api/public/register",
  ENROLLED_COURSES_PREVIEW = "api/user/enrolled-courses",
  GET_COURSE = "api/user/get-course",
  MODULES_FOR_COURSE = "api/user/modules",
  LECTURES = "api/user/lectures",
  USER = "api/user/",
  PUBLIC_USER = "api/public/user",
  PUBLIC_MULTIPLE_USERS = "api/public/user-list",
  SUBMIT_COMMENT = "api/user/submit-comment",
  TOGGLE_HOMEWORK_LIKE = "api/user/toggle-like",
  GET_NOTIFICATIONS = "api/user/notifications",
  UPDATE_NOTIFICATIONS = "api/user/update-notifications",
  UPDATE_NOTIFICATIONS_SETTINGS = "api/user/update-notifications-settings",
  ADD_MESSAGE_CHAT = "api/user/chat",
  ADD_FEEDBACK_MESSAGE = "api/user/homework-feedback",
  UPDATE_FEEDBACK_MESSAGE = "api/user/homework-feedback-update",
  ADD_HOMEWORK = "api/user/homework-upload",
  TOGGLE_HOMEWORK_REVIEW_STATE = "api/user/homework-review-state",
  ADD_CLASS_QUESTION = "api/user/add-question",
  ADD_COMMENT_TO_CLASS_QUESTION = "api/user/add-question-answer",
  SIGN_ZOOM_MEETING = "api/public/sign-zoom-meeting",
  CREATE_MEETING = "api/public/create-meeting",
  SCHEDULES = "api/user/schedules",
  MANUALLY_SUBMIT_MEETING_INVITE = "api/user/manual-zoom-meeting",
  MANUALLY_REMOVE_MEETING_INVITE = "api/user/manual-remove-zoom-meeting",
  GET_SCHEDULE_MAINTENANCE_LIST = "api/user/maintenance-list",
  GET_WEEKS = "api/user/weeks",
  GET_VIDEOS = "api/user/videos",
  GET_CLASSES = "api/user/classes",
  GET_ALL_ENROLLED_CLASSES = "api/user/all-enrolled-classes",
  GET_MODULE = "api/user/term",
  GET_HOMEWORKS_FOR_LECTURE_AND_CLASS = "api/user/class-homeworks",
  GET_HOMEWORKS_COMMENTS_FOR_LECTURE_AND_CLASS = "api/user/class-homeworks-comments",

  GET_CLASS_QUESTIONS = "api/user/class-questions",
  EDIT_CLASS_QUESTION = "api/user/edit-question",
  DELETE_CLASS_QUESTION = "api/user/delete-question",

  GET_CLASS_QUESTIONS_COMMENTS = "api/user/class-questions-comments",
  EDIT_CLASS_QUESTIONS_COMMENTS = "api/user/edit-question-answer",
  DELETE_CLASS_QUESTIONS_COMMENTS = "api/user/delete-question-answer",

  GET_HOMEWORK_FEEDBACK = "api/user/get-feedback",

  // GET_SCHEDULE = "api/user/get-schedule",
  // GET_MULTIPLE_SCHEDULES = "api/user/get-multiple-schedules",
  GET_SCHEDULED_COURSES = "api/user/get-scheduled-courses",

  TRACK_VIDEO_PROGRESS = "api/user/track-progress",
  GET_PROGRESS = "api/user/get-progress",
  SET_CHAT_LAST_MESSAGE = "api/user/set-chat-last-message",
  SUBMIT_SUPPORT_TICKET = "api/user/submit-support-ticket",
  SUBMIT_SUPPORT_TICKET_MESSAGE = "api/user/submit-support-ticket-message",
  GET_SUPPORT_TICKETS = "api/user/get-support-tickets",
  GET_SUPPORT_TICKET = "api/user/get-support-ticket",
  GET_SUPPORT_TICKET_MESSAGES = "api/user/get-support-ticket-messages",
  GET_SUPPORT_TICKET_CATEGORIES = "api/public/support-ticket-categories",
  GET_HELP_QUESTION_CATEGORIES = "api/public/help-question-categories",
  GET_HELP_QUESTIONS_LIST = "api/public/help-questions",
  GET_TRANSACTIONS = "api/transaction/get-all",
  SUBMIT_REPORT = "api/user/reports/submit",
}
