export enum ENotificationType {
  LIVE_QA = "LIVE_QA",
  CLASS_QUESTION = "CLASS_QUESTION",
  CLASS_QUESTION_ANSWERED = "CLASS_QUESTION_ANSWERED",
  CLASS_QUESTION_EDITED = "CLASS_QUESTION_EDITED",
  CLASS_QUESTION_REMOVED = "CLASS_QUESTION_REMOVED",
  CLASS_QUESTION_ANSWER_EDITED = "CLASS_QUESTION_ANSWER_EDITED",
  CLASS_QUESTION_ANSWER_REMOVED = "CLASS_QUESTION_ANSWER_REMOVED",
  HOMEWORK_UPLOADED = "HOMEWORK_UPLOADED",
  HOMEWORK_ASSET_FEEDBACK_UPLOADED = "HOMEWORK_ASSET_FEEDBACK_UPLOADED",
  INSTRUCTOR_VIDEO_FEEDBACK_UPLOADED = "INSTRUCTOR_VIDEO_FEEDBACK_UPLOADED",
  HOMEWORK_REVIEWED = "HOMEWORK_REVIEWED",
  SUPPORT_TICKET_CREATED = "SUPPORT_TICKET_CREATED",
  SUPPORT_TICKET_ANSWERED = "SUPPORT_TICKET_ANSWERED",
  SUPPORT_TICKET_CLOSED = "SUPPORT_TICKET_CLOSED",
  CHAT_MESSAGE_REPORTED = "CHAT_MESSAGE_REPORTED",
  CLASS_QUESTION_REPORTED = "CLASS_QUESTION_REPORTED",
  SUPPORT_TICKET_STATUS_UPDATED = "SUPPORT_TICKET_STATUS_UPDATED",
}

export enum NotificationPriority {
  CRITICAL,
  URGENT,
  IMPORTANT,
  NORMAL,
}
type ENotificationSource = "SYSTEM" | "USER" | "ADMIN";

export default interface INotification {
  description: string;
  id: string;
  isRead: boolean;

  metadata: {
    courseId?: string;
    classId?: string;
    termId?: string;
    weekId?: string;
    questionId?: string;
    /////////support ticket////////////
    adminUserId?: string;
    ticketId?: string;
    userId?: string;
    //////////general////////////
    source: ENotificationSource;
    avatarUrl?: string;
    firstName?: string;
    lastName?: string;
  };

  priority: NotificationPriority;
  source: ENotificationSource;
  title: string;
  type: ENotificationType;
  unixTimestamp: number;
}
