import "./ProgressBar1.scss";

type Props = {
  percentage: number;
  total: number;
};

const ProgressBar1: React.FC<Props> = ({ total, percentage }) => {
  const progress = Math.round((percentage / 100) * total);

  return (
    <div className='progress-bar-1'>
      <div className='label'>
        Progress: <span>{Math.round(percentage) || 0}%</span>
      </div>

      <div className='progress-bar' style={{ width: total || 0 }}>
        <div className='progress-value' style={{ width: progress || 0 }} />
      </div>
    </div>
  );
};

export default ProgressBar1;
