import { Link } from "react-router-dom";
import "./SidebarMenu.scss";

// load imgs
import Logo from "../../assets/images/logo@2x.png";

// load icons
import Icon from "../../assets/icons/Icon";

import { CollapsedSidebarContext } from "../../context/collapsedSidebarContext";
import { useContext, useRef, useState } from "react";

import useOnClickOutside from "../../hooks/useOnClickOutside";

import NotificationList from "../notificationList/NotificationList";

import MenuList from "./MenuList";
import CourseSelector from "./courseSelector/CourseSelector";

import AdminCourseSelector from "./adminCourseSelector/AdminCourseSelector";
import { AuthContext } from "../../context/authContext";
import { NotificationContext } from "../../context/notificationContext";
import { GlobalNotificationService } from "../../api/notifications/notificationService";

const SidebarMenu: React.FC = () => {
  const notificationsInstance = useContext(NotificationContext);
  const { collapsed, handleSidebarState } = useContext(CollapsedSidebarContext);

  const authInstance = useContext(AuthContext);

  const toggleSidebarState = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    handleSidebarState();
  };
  //////////////////NOTIFICATIONS///////////////////////
  const refNotification = useRef(null);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  // const [allNotificationsRead, setAllNotificationsRead] =
  //   useState<boolean>(false);
  const [clickedOutsideNotifications, setClickedOutsideNotifications] =
    useState<boolean>(false);

  const handleNotificationsPopup = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (!clickedOutsideNotifications) setShowNotifications(true);
    setClickedOutsideNotifications(false);
  };

  const handleClickOutsideNotifications = (e: MouseEvent) => {
    e.preventDefault();
    setClickedOutsideNotifications(true);
    setShowNotifications(false);
  };
  useOnClickOutside(refNotification, handleClickOutsideNotifications);

  const handleMarkAllAsRead = async (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    if (
      !notificationsInstance?.notifications ||
      notificationsInstance?.notifications?.length === 0
    )
      return;

    await GlobalNotificationService.MarkNotificationsAsRead();
  };

  return (
    <div
      className={`sidebar-menu ${collapsed ? "collapsed" : ""} ${
        showNotifications ? "show-notifications" : ""
      }`}>
      {/* ------------ LOGO ------------ */}
      <Link
        to={"/activity"}
        className={`sidebar-logo ${collapsed ? "collapsed" : ""}`}>
        <img alt='Visign' src={Logo} />
      </Link>

      {/* ------------ SIDEBAR EXPANDER ------------ */}
      <div className='sidebar-expander'>
        <span onClick={toggleSidebarState}>
          <Icon icon='Expand' />
        </span>
      </div>

      {/* ------------ NOTIFICATIONS------------ */}
      <div
        className={`sidebar-notifications ${collapsed ? "collapsed" : ""}`}
        onClick={handleNotificationsPopup}>
        <div className='bell'>
          {notificationsInstance?.numberOfUnreadNotifications &&
            notificationsInstance?.numberOfUnreadNotifications > 0 && (
              <span className='count'>
                {notificationsInstance?.numberOfUnreadNotifications}
              </span>
            )}
          <Icon icon='Bell' size={26} />
        </div>
        {!collapsed && <div className='label'>Notifications</div>}
      </div>

      {/* ------------ CLASS/COURSE SELECT------------ */}
      {!collapsed && authInstance?.userRole === "USER" && <CourseSelector />}
      {!collapsed &&
        (authInstance?.userRole === "ADMIN" ||
          authInstance?.userRole === "OWNER") && <AdminCourseSelector />}

      {/* ------------ MENU LIST ------------ */}
      <MenuList collapsed={collapsed} />

      {/* ------------ NOTIFICATIONS LIST ------------ */}
      {showNotifications && (
        <div
          className={`notification-container ${collapsed ? "collapsed" : ""}`}
          ref={refNotification}>
          <div className='title'>Notifications</div>
          <span className='markAllAsRead' onClick={handleMarkAllAsRead}>
            Mark All As Read
          </span>
          <NotificationList
            // markAllAsRead={handleMarkAllAsRead}
            closeNotifications={() => setShowNotifications(false)}
          />
        </div>
      )}
    </div>
  );
};

export default SidebarMenu;
