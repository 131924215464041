export class Dispatcher<T> {
  private observers: Array<(value: T) => void> = [];

  public Subscribe(callback: (value: T) => void): () => void {
    this.observers.push(callback);

    return () =>
      (this.observers = this.observers.filter(
        (cb: (value: T) => void) => cb !== callback
      ));
  }

  public Dispatch(value: T): void {
    for (const callback of this.observers) callback(value);
  }
}
