import "./Activity.scss";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import AppHeader from "../../components/appHeader/AppHeader";
import MainBtn from "../../components/common/buttons/mainBtn/MainBtn";
import ProgressBar1 from "../../components/common/progressBars/progressBar1/ProgressBar1";
import { CollapsedSidebarContext } from "../../context/collapsedSidebarContext";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderBg from "../../assets/images/activityImg.jpeg";

import Loading from "../../components/common/loading/Loading";
import SecondBtn from "../../components/common/buttons/secondBtn/SecondBtn";
import { useUserProgress } from "../../api/user/user";
import { ProgressTitles } from "../../interfaces/IProgress";
import { useEnrolledClasses } from "../../api/class/class";
import { IClass } from "../../interfaces/IClass";
import CourseTimeline from "../../components/courseTimeline";
import { useCourseSchedules } from "../../api/course/course";

const Activity: React.FC = () => {
  const { data: enrolledClasses } = useEnrolledClasses();
  const { collapsed } = useContext(CollapsedSidebarContext);
  const navigate = useNavigate();
  const {
    data: activityProgressData,
    error: activityProgressError,
    isLoading: isLoadingActivityProgress,
    refetch: refetchActivityProgress,
  } = useUserProgress();

  const { data: courseSchedules, isLoading: loadingCourseSchedules } =
    useCourseSchedules();

  const getPathToCourse = (currentProgress: ProgressTitles) => {
    if (!enrolledClasses || !currentProgress) return "/activity";

    const classByCourseId: IClass = enrolledClasses.find(
      (c: IClass) => c.courseId === currentProgress.courseId
    );

    const destructuredPath = currentProgress.pathToLectureVideo.split("/");

    const path: string = `/course-overview/${destructuredPath[2]}/${classByCourseId.id}/${destructuredPath[3]}/${destructuredPath[4]}/${destructuredPath[5]}`;
    console.log(destructuredPath);

    return path;
  };

  useEffect(() => {
    refetchActivityProgress();
  }, []);

  if (activityProgressError)
    return (
      <h2 style={{ textAlign: "center", color: "white", padding: "30px" }}>
        Something went wrong. Please try again later.
      </h2>
    );

  if (activityProgressData?.length === 0)
    return (
      <div className='activity'>
        <SidebarMenu />

        <div className={`activity-wrapper ${collapsed ? "collapsed" : ""}`}>
          {isLoadingActivityProgress && <Loading />}

          <AppHeader />

          <div
            className='static-slide'
            style={{
              background: `url(${HeaderBg})`,
            }}>
            <h4>Your progress will be displayed here.</h4>
          </div>

          <div className='course-timeline'>
            {courseSchedules && <CourseTimeline dataSeries={courseSchedules} />}
          </div>
        </div>
      </div>
    );

  return (
    <div className='activity'>
      <SidebarMenu />

      <div className={`activity-wrapper ${collapsed ? "collapsed" : ""}`}>
        {isLoadingActivityProgress && <Loading />}

        <AppHeader />

        <div
          style={{
            marginTop: "-100px",
          }}>
          <Carousel
            autoPlay
            infiniteLoop
            interval={4000}
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            stopOnHover
            emulateTouch>
            {activityProgressData?.map(
              (currentProgress: ProgressTitles, index: number) => (
                <div
                  className='activity-header'
                  key={index}
                  style={{
                    backgroundImage: `url(${currentProgress?.imageUrl})`,
                  }}>
                  <div className='activity-header-info'>
                    <ProgressBar1
                      total={75}
                      percentage={currentProgress?.percentageCompleted}
                    />
                    <h3 className='subtitle'>{currentProgress?.moduleTitle}</h3>
                    <h2 className='title'>{currentProgress?.courseTitle}</h2>
                    {/* <Link to='/course-overview/1'> */}
                    <a href='https://visign.com/courses' target='_blank'>
                      <MainBtn
                        label='Learn something new'
                        iconLeft='Warning'
                        iconColor='white'
                        iconPadding='7px 0 0 7px'
                        iconSize={24}
                      />
                    </a>

                    {/* </Link> */}
                    <div className='go-to-course'>
                      <SecondBtn
                        label='Go To Course'
                        iconLeft='ArrowLeft'
                        onClick={(e) =>
                          navigate(getPathToCourse(currentProgress))
                        }
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          </Carousel>
        </div>

        <div className='course-timeline'>
          {courseSchedules && <CourseTimeline dataSeries={courseSchedules} />}
        </div>
      </div>
    </div>
  );
};

export default Activity;
