import "./VideoItem.scss";
import { IVideo } from "../../../interfaces/IVideo";
import { useNavigate, useParams } from "react-router-dom";
import { UrlParams } from "../../../interfaces/UrlParams";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ProgressBar2 from "../../common/progressBars/progressBar2/ProgressBar2";

type Props = {
  data: IVideo;
  courseTitle: string;
  videoProgress: number;
};
const VideoItem: React.FC<Props> = ({ data, courseTitle, videoProgress }) => {
  const navigate = useNavigate();
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, classId, termId, weekId, videoId } = routeParameters;

  const videoItemRef = useRef<HTMLDivElement>(null);

  const [videoSecondsProgress, setVideoProgress] = useState<number>(0);

  //// FORMAT TIME DURATION
  const formatDuration = (duration: number) => {
    if (!duration) return "00:00";

    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);

    let hoursString = "";
    let minutesString = "";
    let secondsString = "";

    if (hours > 0) {
      hoursString = `${hours}:`;
    }

    if (minutes < 10) {
      minutesString = `0${minutes}:`;
    } else {
      minutesString = `${minutes}:`;
    }

    if (seconds < 10) {
      secondsString = `0${seconds}`;
    } else {
      secondsString = `${seconds}`;
    }

    return `${hoursString}${minutesString}${secondsString}`;
  };

  const handleClickVideo = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (videoId !== data.id)
      navigate(
        `/course-overview/${courseId}/${classId}/${termId}/${weekId}/${data.id}`
      );
  };

  const activeClass = () => {
    if (videoId) {
      if (videoId === data.id) return "active";
    } else {
      if (data.orderIndex === 0) return "active";
    }
    return "";
  };

  useLayoutEffect(() => {
    if (videoId === data.id && videoItemRef.current)
      videoItemRef.current.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  useEffect(() => {
    const progressVideo: number = Math.floor(
      (videoProgress / data.lengthInSeconds) * 100
    );

    setVideoProgress(progressVideo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoProgress]);

  return (
    <div
      ref={videoItemRef}
      className={`playlist-item ${activeClass()}`}
      onClick={handleClickVideo}>
      <div className='left'>
        <div
          className='avatar'
          style={{
            background: `url(${data.thumbnailSource || "/placeholder.webp"})`,
          }}></div>
        <ProgressBar2 total={68} percentage={videoSecondsProgress} hideLabel />
      </div>
      <div className='details'>
        <div className='subtitle'>{courseTitle}</div>
        <div className='title'>{data.title}</div>
        <div className='duration'>{formatDuration(data.lengthInSeconds)}</div>
      </div>
    </div>
  );
};

export default VideoItem;
