import { useState } from "react";
import { ICourseReference } from "../../../interfaces/ICourseReference";
import cls from "./CourseReferences.module.scss";

type Props = {
  references: ICourseReference[];
  courseTitle: string;
};

const CourseReferences: React.FC<Props> = ({ references, courseTitle }) => {
  const [referenceIndex, setReferenceIndex] = useState<number>(0);

  const handleSetReferenceIndex = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => {
    event.preventDefault();
    if (referenceIndex !== index) setReferenceIndex(index);
  };

  if (references.length === 0)
    return (
      <h2 style={{ textAlign: "center", color: "white", padding: "30px" }}>
        There are no references for this course.
      </h2>
    );

  return (
    <div className={cls.root}>
      <div className={cls.left}>
        <div className={cls.title}>References</div>
        <div className={cls.list}>
          {references.map((reference: ICourseReference, index: number) => (
            <div
              className={`${cls.item} ${
                referenceIndex === reference.orderIndex ? cls.active : ""
              }`}
              key={index}
              onClick={(e) => handleSetReferenceIndex(e, reference.orderIndex)}>
              <div className={cls.itemSubtitle}>{courseTitle}</div>
              <div className={cls.itemTitle}>{reference.title}</div>
            </div>
          ))}
        </div>
      </div>

      <div className={cls.right}>
        {references.map((reference: ICourseReference, index: number) => (
          <div
            className={`${cls.refItem} ${
              referenceIndex === reference.orderIndex ? cls.show : ""
            }`}
            key={index}
            dangerouslySetInnerHTML={{ __html: reference.html }}
          />
        ))}
      </div>
    </div>
  );
};

export default CourseReferences;
