import { useState } from "react";
import cls from "./CourseSyllabus.module.scss";
import { ICourseSyllabus } from "../../../interfaces/ICourseSyllabus";
import { FaFilePdf } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { UrlParams } from "../../../interfaces/UrlParams";
import StorageService from "../../../api/storage/storageService";

type Props = {
  syllabuses: ICourseSyllabus[];
  courseTitle: string;
};

const CourseSyllabus: React.FC<Props> = ({ syllabuses, courseTitle }) => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId } = routeParameters;
  const [syllabusIndex, setSyllabusIndex] = useState<number>(0);

  const handleSetSyllabusIndex = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => {
    event.preventDefault();
    if (syllabusIndex !== index) setSyllabusIndex(index);
  };

  const handleDownloadPdf = async (pdfFileName: string) => {
    try {
      await StorageService.DownloadSyllabusPdfFile(pdfFileName, courseId);
    } catch (error) {
      console.log(error);
    }
  };

  if (syllabuses.length === 0)
    return (
      <h2 style={{ textAlign: "center", color: "white", padding: "30px" }}>
        There are no syllabuses for this course.
      </h2>
    );

  return (
    <div className={cls.root}>
      <div className={cls.left}>
        <div className={cls.title}>Syllabus</div>
        <div className={cls.list}>
          {syllabuses.map((syllabus: ICourseSyllabus, index: number) => (
            <div
              className={`${cls.item} ${
                syllabusIndex === syllabus.orderIndex ? cls.active : ""
              }`}
              key={index}
              onClick={(e) => handleSetSyllabusIndex(e, syllabus.orderIndex)}>
              <div className={cls.itemSubtitle}>{courseTitle}</div>
              <div className={cls.itemTitle}>{syllabus.title}</div>
            </div>
          ))}
        </div>
      </div>

      <div className={cls.right}>
        {syllabuses.map((syllabus: ICourseSyllabus, index: number) => (
          <div
            className={`${cls.refItem} ${
              syllabusIndex === syllabus.orderIndex ? cls.show : ""
            }`}
            key={index}>
            <div dangerouslySetInnerHTML={{ __html: syllabus.html }} />
            <div
              style={{
                padding: "20px 0",
              }}>
              {syllabus.pdfFiles?.map((pdfFile) => (
                <div key={pdfFile}>
                  <span
                    onClick={() => handleDownloadPdf(pdfFile)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#cc33ff",
                      gap: "14px",
                      cursor: "pointer",
                      padding: "7px 0",
                    }}>
                    {" "}
                    <FaFilePdf size={22} color='#cc33ff' /> {pdfFile}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseSyllabus;
