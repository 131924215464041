import cls from "./CourseOverview.module.scss";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import { CollapsedSidebarContext } from "../../context/collapsedSidebarContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import AppHeader from "../../components/appHeader/AppHeader";
import MainVideoPlayer from "../../components/courses/mainVideoPlayer/MainVideoPlayer";
import MainBtn from "../../components/common/buttons/mainBtn/MainBtn";
import CourseOverviewDetails from "../../components/courses/courseOverviewDetails/CourseOverviewDetails";
import CourseOverviewAssignments from "../../components/courses/courseOverviewAsignments/CourseOverviewAssignments";
import VideoPlaylist from "../../components/videoPlaylist/VideoPlaylist";

import CourseChat from "../../components/courses/courseChat/CourseChat";
import Modal from "../../components/common/modal/Modal";
import ClassQuestions from "../../components/courses/classQuestions/ClassQuestions";
import CourseReferences from "../../components/courses/courseReferences/CourseReferences";
import useOnClickOutside from "../../hooks/useOnClickOutside";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loading from "../../components/common/loading/Loading";
import {
  useCourse,
  useTerms,
  useVideos,
  useWeeks,
} from "../../api/course/course";
import { IChatService, GetChatServiceForId } from "../../api/chat/chatService";

import { useEnrolledClasses } from "../../api/class/class";
import { IVideo } from "../../interfaces/IVideo";
import { IWeek } from "../../interfaces/IWeek";

import { IClass } from "../../interfaces/IClass";
import { UrlParams } from "../../interfaces/UrlParams";
import SecondBtn from "../../components/common/buttons/secondBtn/SecondBtn";
import { useUser } from "../../api/user/user";
import { AuthContext } from "../../context/authContext";
import CourseSyllabus from "../../components/courses/courseSyllabus/CourseSyllabus";

// import LmsVideoPlayer from "../../components/courses/lmsVideoPlayer/LmsVideoPlayer";

const CourseOverview: React.FC = () => {
  const authInstance = useContext(AuthContext);
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, classId, termId, weekId, videoId } = routeParameters;
  const [searchParams] = useSearchParams();

  const questionId = searchParams.get("questionId");

  const { collapsed } = useContext(CollapsedSidebarContext);

  const refModal = useRef(null);
  const syllabusModal = useRef(null);
  const navigate = useNavigate();

  const [mainVideoData, setMainVideoData] = useState<IVideo | null>(null);

  ////////////////
  const [courseTab, setCourseTab] = useState<string>("overview");
  const [playlistChatTab, setPlaylistChatTab] = useState<string>("playlist");
  const [showReferences, setShowReferences] = useState<boolean>(false);
  const [showSyllabus, setShowSyllabus] = useState<boolean>(false);

  ////
  const { refetch: refetchUserData } = useUser();

  //get course data
  const { data: courseData, isLoading: isLoadingCourseData } = useCourse(
    courseId,
    !!courseId
  );

  // fetch enrolled class for this course
  const { data: enrolledClasses, isLoading: isLoadingEnrolledClasses } =
    useEnrolledClasses(!!courseId);

  //get terms for this course
  const { data: termsData, isLoading: isLoadingTerms } = useTerms(
    courseId,
    !!courseId
  );

  //get weeks for this term
  const {
    data: weeksData,
    isLoading: isLoadingWeeks,
    // refetch: refetchWeekList,
  } = useWeeks(courseId, termId, !!courseId && !!termId);

  //get video list for this week
  const {
    data: videosList,
    isLoading: isLoadingVideosList,
    // refetch: refetchVideoList,
  } = useVideos(courseId, termId, weekId, !!courseId && !!termId && !!weekId);

  const [isAutoPlay, setIsAutoPlay] = useState<boolean>(false);

  const handleVideoHasEnded = () => {
    refetchUserData();
    if (!isAutoPlay) return;
    if (videosList) {
      const currentVideoIndex = videosList.findIndex(
        (vid: IVideo) => vid.id === videoId
      );
      const nextVideoIndex = currentVideoIndex + 1;
      const nextVideo = videosList[nextVideoIndex];
      if (nextVideo) {
        navigate(
          `/course-overview/${courseId}/${classId}/${termId}/${weekId}/${nextVideo.id}`
        );
      }
    }
  };

  const handleClickOutsideModal = (e: MouseEvent) => {
    e.preventDefault();
    setShowReferences(false);
  };
  useOnClickOutside(refModal, handleClickOutsideModal);

  const handleTabChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    console.log(e.currentTarget.value);
    setCourseTab(e.currentTarget.value);
  };

  const handlePlaylistChatTab = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setPlaylistChatTab(e.currentTarget.value);
  };

  const handleShowReferences = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowReferences(true);
  };

  const handleShowSyllabus = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowSyllabus(true);
  };

  /////////////////////////

  const handleWeekSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const urlVal = event.target.value.split("/");

    const newTermId = urlVal[0];
    const newWeekId = urlVal[1];
    const newVideoId = urlVal[2];

    if (!newTermId || !newWeekId || !newVideoId) return;

    navigate(
      `/course-overview/${courseId}/${classId}/${newTermId}/${newWeekId}/${newVideoId}`
    );
  };

  ////////////////// CHAT FUNCTIONS //////////////////////
  // const [classUnreadMessages, setClassUnreadMessages] = useState<number>(0);
  // const [courseUnreadMessages, setCourseUnreadMessages] = useState<number>(0);

  const [numberOfNewMessages, setNumberOfNewMessages] = useState<number>(0);

  const [unsubscriber, setChatUnsubscriber] = useState<{
    unsubscribe: null | (() => void);
  }>({ unsubscribe: null } as { unsubscribe: null | (() => void) });

  const [classChatService, setChatClassService] = useState<IChatService | null>(
    null
  );
  // const [courseChatService, setCourseChatService] =
  //   useState<IChatService | null>(null);

  const updateNumberOfUnreadMessages = (
    courseChat?: IChatService,
    classChat?: IChatService
  ) => {
    let courseChatMessagesUnread: number = 0;
    let classChatMessagesUnread: number = 0;

    if (courseChat) {
      courseChatMessagesUnread = courseChat?.GetNumberOfUnreadMessages() || 0;

      setNumberOfNewMessages(numberOfNewMessages + courseChatMessagesUnread);
    }

    if (classChat) {
      classChatMessagesUnread = classChat?.GetNumberOfUnreadMessages() || 0;
      setNumberOfNewMessages(numberOfNewMessages + classChatMessagesUnread);
    }

    setNumberOfNewMessages(courseChatMessagesUnread + classChatMessagesUnread);
  };
  //// INITIALIZE CHAT
  const InitializeChatService = async () => {
    if (!classId || !courseId) return;

    // const [classChat, courseChat]: [IChatService, IChatService] =
    // await Promise.all([
    //   GetChatServiceForId(classId),
    //   GetChatServiceForId(courseData?.id),
    // ]);

    const [classChat]: [IChatService] = await Promise.all([
      GetChatServiceForId(classId),
      // GetChatServiceForId(courseData?.id),
    ]);

    const unsubscribeFromClassMessages: () => void =
      classChat.SubscribeForMessages(() =>
        // setImmediate(() => updateNumberOfUnreadMessages(undefined, classChat))
        // updateNumberOfUnreadMessages(undefined, classChat)
        setTimeout(() => updateNumberOfUnreadMessages(undefined, classChat), 0)
      );

    // const unsubscribeFromCourseMessages: () => void =
    //   courseChat.SubscribeForMessages(() =>
    //     // setImmediate(() => updateNumberOfUnreadMessages(classChat, courseChat))
    //     updateNumberOfUnreadMessages(classChat, courseChat)
    //   );

    setChatClassService(classChat);
    // setCourseChatService(courseChat);

    setChatUnsubscriber({
      unsubscribe: () => {
        unsubscribeFromClassMessages();
        // unsubscribeFromCourseMessages();
      },
    });
  };

  useEffect(() => {
    InitializeChatService().catch((e) => console.log(e));

    return () => {
      unsubscriber.unsubscribe?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classId]);

  useEffect(() => {
    if (questionId) setCourseTab("questions");
  }, [questionId]);

  useEffect(() => {
    if (videosList) {
      if (videoId) {
        const mainVideoToPlay = videosList.find(
          (vid: IVideo) => vid.id === videoId
        );
        setMainVideoData(mainVideoToPlay);
      } else {
        const mainVideoToPlay = videosList.find(
          (vid: IVideo) => vid.orderIndex === 0
        );
        setMainVideoData(mainVideoToPlay);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId, videosList]);

  useEffect(() => {
    refetchUserData();
  }, []);

  return (
    <div className={cls.root}>
      <SidebarMenu />

      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />
        <div className={cls.container} style={{ position: "relative" }}>
          {(isLoadingCourseData ||
            isLoadingEnrolledClasses ||
            isLoadingTerms ||
            isLoadingWeeks ||
            isLoadingVideosList) && <Loading />}
          <SecondBtn
            label='Go to Courses'
            iconLeft='ArrowLeft'
            style={{ position: "absolute", top: -35, left: 20, zIndex: 77 }}
            onClick={(e) => navigate("/my-courses")}
          />
          <div className={cls.header}>
            <div className={cls.videoPlayer}>
              <MainVideoPlayer
                autoplay={isAutoPlay}
                videoData={mainVideoData}
                videoHasEnded={handleVideoHasEnded}
              />
              {/* <LmsVideoPlayer videoData={mainVideoData} /> */}
            </div>
            {/* /////////////// */}
            {/* {`Question ID: ${questionId}`} */}
            {/* /////////////// */}

            <div className={cls.tabSwitch}>
              <div className={cls.top}>
                <div className={cls.tabs}>
                  <button
                    className={`${cls.tab} ${
                      playlistChatTab === "playlist" ? cls.active : ""
                    }`}
                    value='playlist'
                    onClick={handlePlaylistChatTab}>
                    Playlist
                  </button>
                  <button
                    className={`${cls.tab} ${
                      playlistChatTab === "chat" ? cls.active : ""
                    }`}
                    value='chat'
                    onClick={handlePlaylistChatTab}>
                    Chat
                    {numberOfNewMessages > 0 && (
                      <span>{numberOfNewMessages}</span>
                    )}
                  </button>

                  <label className={cls.toggle} htmlFor='toggle'>
                    <span
                      className={`${cls.slider} ${cls.round} ${
                        !isAutoPlay ? "" : cls.on
                      }`}></span>
                    <input
                      type='checkbox'
                      id='toggle'
                      onChange={() => setIsAutoPlay(!isAutoPlay)}
                      checked={isAutoPlay}
                    />
                    <span
                      className={`${cls.label} ${!isAutoPlay ? "" : cls.isOn}`}>
                      {/* {!isAutoPlay ? "No" : "Yes"} */}
                      AutoPlay
                    </span>
                  </label>
                </div>
              </div>

              {playlistChatTab === "playlist" && (
                <VideoPlaylist
                  termList={termsData}
                  weekList={weeksData}
                  videoList={videosList}
                  courseTitle={courseData?.title}
                  onSelectWeek={handleWeekSelect}
                />
              )}

              {playlistChatTab === "chat" && (
                <CourseChat classChatService={classChatService} />
              )}
            </div>
          </div>

          <div className={cls.body}>
            <div className={cls.left}>
              <div className={cls.heading}>
                <div className={cls.title}>
                  <h5>{courseData && courseData.title}</h5>
                  <h1>{mainVideoData && mainVideoData.title}</h1>
                </div>
                <MainBtn
                  label='References'
                  onClick={handleShowReferences}
                  height={42}
                  width={180}
                />
                <MainBtn
                  label='Syllabus'
                  onClick={handleShowSyllabus}
                  height={42}
                  width={160}
                />
              </div>
              <div className={cls.content}>
                <div className={cls.tabs}>
                  <button
                    value='overview'
                    onClick={handleTabChange}
                    className={`${cls.tab} ${
                      courseTab === "overview" ? cls.active : ""
                    }`}>
                    Overview
                  </button>
                  <button
                    value='assignments'
                    onClick={handleTabChange}
                    className={`${cls.tab} ${
                      courseTab === "assignments" ? cls.active : ""
                    }`}>
                    Assignments
                  </button>
                  <button
                    value='questions'
                    onClick={handleTabChange}
                    className={`${cls.tab} ${
                      courseTab === "questions" ? cls.active : ""
                    }`}>
                    Class Questions
                  </button>
                </div>

                <div className={cls.tabContent}>
                  {courseTab === "overview" && (
                    <CourseOverviewDetails
                      html={
                        weeksData?.find((wk: IWeek) => wk.id === weekId)
                          ?.overview
                      }
                    />
                  )}
                  {courseTab === "assignments" && (
                    <CourseOverviewAssignments
                      html={
                        weeksData?.find((wk: IWeek) => wk.id === weekId)
                          ?.homeworkDescription
                      }
                    />
                  )}

                  {courseTab === "questions" && enrolledClasses && (
                    <ClassQuestions
                      questionIdInUrl={questionId}
                      classTier={
                        enrolledClasses.find((cl: IClass) => cl.id === classId)
                          .tier
                      }
                      userRole={authInstance?.userRole}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={cls.right}>
              <div style={{ minHeight: 600, display: "flex" }}>
                <div className={cls.tabSwitch}>
                  <div className={cls.top}>
                    <div className={cls.tabs}>
                      <button
                        className={`${cls.tab} ${
                          playlistChatTab === "playlist" ? cls.active : ""
                        }`}
                        value='playlist'
                        onClick={handlePlaylistChatTab}>
                        Playlist
                      </button>
                      <button
                        className={`${cls.tab} ${
                          playlistChatTab === "chat" ? cls.active : ""
                        }`}
                        value='chat'
                        onClick={handlePlaylistChatTab}>
                        Chat
                        {numberOfNewMessages > 0 && (
                          <span>{numberOfNewMessages}</span>
                        )}
                      </button>
                      <label className={cls.toggle} htmlFor='toggle'>
                        <span
                          className={`${cls.slider} ${cls.round} ${
                            !isAutoPlay ? "" : cls.on
                          }`}></span>
                        <input
                          type='checkbox'
                          id='toggle'
                          onChange={() => setIsAutoPlay(!isAutoPlay)}
                          checked={isAutoPlay}
                        />
                        <span
                          className={`${cls.label} ${
                            !isAutoPlay ? "" : cls.isOn
                          }`}>
                          {/* {!isAutoPlay ? "No" : "Yes"} */}
                          AutoPlay
                        </span>
                      </label>
                    </div>
                  </div>

                  {playlistChatTab === "playlist" && (
                    <VideoPlaylist
                      termList={termsData}
                      weekList={weeksData}
                      videoList={videosList}
                      courseTitle={courseData?.title}
                      onSelectWeek={handleWeekSelect}
                    />
                  )}

                  {playlistChatTab === "chat" && (
                    <CourseChat classChatService={classChatService} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isVisible={showReferences} fullScreen={true}>
        <div ref={refModal}>
          <div className='modal-header'>
            <button onClick={(e) => setShowReferences(false)}>X</button>
          </div>

          <CourseReferences
            references={courseData?.reference || []}
            courseTitle={courseData?.title}
          />
        </div>
      </Modal>

      {/* //////////////         */}
      <Modal isVisible={showSyllabus} fullScreen={true}>
        <div ref={syllabusModal}>
          <div className='modal-header'>
            <button onClick={(e) => setShowSyllabus(false)}>X</button>
          </div>

          <CourseSyllabus
            syllabuses={courseData?.syllabus || []}
            courseTitle={courseData?.title}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CourseOverview;
