import { ChangeEvent, useRef, useState } from "react";
import "./MessageSubmitter.scss";
import EmojiPicker, {
  EmojiStyle,
  Theme,
  EmojiClickData,
  SuggestionMode,
} from "emoji-picker-react";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import badWords from "bad-words";

import { FaPaperPlane } from "react-icons/fa";
import { useUser } from "../../../api/user/user";

type Props = {
  emojiEnabled: boolean;
  autoFocus?: boolean;
  submitMessage: (textToSubmit: string) => void;
};

const MessageSubmitter: React.FC<Props> = ({
  emojiEnabled,
  autoFocus,
  submitMessage,
}) => {
  const { data: user } = useUser();
  const cleanMessage = new badWords();
  const [userMessage, setUserMessage] = useState<string>("");
  const rootRef = useRef<HTMLDivElement>(null);
  const [showEmojiSelector, setShowEmojiSelector] = useState<boolean>(false);
  const [cursorPos, setCursorPos] = useState<number>(0);
  const [error, setError] = useState<string>(" ");

  const onChatTextInputValueChanged = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const text: string = event.target.value;
    setCursorPos(event.currentTarget.selectionStart || 0);
    setUserMessage(text);
  };

  const updateSelectionStart = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    setCursorPos(e.currentTarget.selectionStart || 0);
  };

  function onClickEmoji(emojiData: EmojiClickData, event: MouseEvent) {
    const newVal =
      userMessage.slice(0, cursorPos) +
      emojiData.emoji +
      userMessage.slice(cursorPos);
    setUserMessage(newVal);
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const handleSubmitMessage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // if (userMessage.length > 1100)
    //   return setError("Message Must Be At Most 1100 Characters Long!");
    if (!userMessage) return;
    if (user?.isMuted) return setError("You are muted from chat");
    setShowEmojiSelector(false);
    submitMessage(cleanMessage.clean(userMessage));
    setUserMessage("");
    // setError("");
  };
  const handleClickOutsideMenu = (e: MouseEvent) => {
    setShowEmojiSelector(false);
  };
  useOnClickOutside(rootRef, handleClickOutsideMenu);
  return (
    <div className='message-submitter' ref={rootRef}>
      {showEmojiSelector && (
        <EmojiPicker
          onEmojiClick={onClickEmoji}
          autoFocusSearch={false}
          theme={Theme.DARK}
          height={300}
          width='100%'
          emojiVersion='5.0'
          lazyLoadEmojis={true}
          previewConfig={{
            showPreview: false,
          }}
          suggestedEmojisMode={SuggestionMode.RECENT}
          skinTonesDisabled
          searchPlaceHolder='Search your emoji'
          emojiStyle={EmojiStyle.NATIVE}
        />
      )}

      <form action='' onSubmit={handleSubmitMessage}>
        {emojiEnabled && (
          <span
            className='emojiShow'
            onClick={(e) => setShowEmojiSelector(!showEmojiSelector)}>
            &#x1F600;
          </span>
        )}

        <input
          type='text'
          maxLength={1100}
          value={userMessage}
          autoFocus={autoFocus}
          placeholder='Send a message'
          onClick={updateSelectionStart}
          onKeyDown={handleKeyPress}
          onChange={onChatTextInputValueChanged}></input>

        <button type='submit' className='submitBtn'>
          <FaPaperPlane color='#ffffff' />
        </button>
      </form>
      <p className='error'>{error}</p>
    </div>
  );
};

export default MessageSubmitter;
