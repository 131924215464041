type Path = {
  d: string;
  transform?: string;
};

type IconsLib = {
  [key: string]: Array<Path>;
};
//////// BARS ////////
const Bars: Array<Path> = [
  {
    d: "M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z",
  },
];
//////// DASHBOARD ////////
const Dashboard: Array<Path> = [
  {
    d: "M16.246,21.253H4.973A4.979,4.979,0,0,1,0,16.28V9.306A5,5,0,0,1,1.957,5.352l5.636-4.3a4.985,4.985,0,0,1,6.032,0l1.791,1.365V1.36a.829.829,0,1,1,1.658,0V4.092a.829.829,0,0,1-1.331.659L12.62,2.371a3.324,3.324,0,0,0-4.022,0L2.962,6.67a3.334,3.334,0,0,0-1.3,2.636V16.28A3.319,3.319,0,0,0,4.973,19.6H16.246a3.319,3.319,0,0,0,3.315-3.315V9.306a3.337,3.337,0,0,0-1.29-2.645.829.829,0,0,1,1.005-1.318,5,5,0,0,1,1.942,3.963V16.28A4.979,4.979,0,0,1,16.246,21.253Z",
    transform: "translate(0 -0.037)",
  },
  {
    d: "M193.072,232a1.036,1.036,0,1,1-1.036-1.036A1.036,1.036,0,0,1,193.072,232Zm2.279-1.036A1.036,1.036,0,1,0,196.388,232,1.036,1.036,0,0,0,195.352,230.962Zm-3.315,3.315a1.036,1.036,0,1,0,1.036,1.036A1.036,1.036,0,0,0,192.036,234.277Zm3.315,0a1.036,1.036,0,1,0,1.036,1.036A1.036,1.036,0,0,0,195.352,234.277Z",
    transform: "translate(-183.084 -221.392)",
  },
];
//////// COURSES ////////
const Courses: Array<Path> = [
  {
    d: "M43.289,22.6H38.212a.883.883,0,1,1,0-1.766h5.077a3.536,3.536,0,0,0,3.532-3.532V5.3a3.506,3.506,0,0,0-1.413-2.826A.883.883,0,1,1,46.468,1.06,5.257,5.257,0,0,1,48.586,5.3V17.305A5.3,5.3,0,0,1,43.289,22.6Zm-7.726-.883V.883A.883.883,0,0,0,34.68,0H33.8a5.3,5.3,0,0,0-5.3,5.3V17.305a5.3,5.3,0,0,0,5.3,5.3h.883A.883.883,0,0,0,35.563,21.72ZM33.8,20.837a3.536,3.536,0,0,1-3.532-3.532V5.3A3.536,3.536,0,0,1,33.8,1.766Z",
    transform: "translate(-28.5)",
  },
  {
    d: "M233.172,10.241a1.507,1.507,0,0,1-1.029-.406l-.552-.51-.552.51a1.508,1.508,0,0,1-2.538-1.1V.883A.883.883,0,0,1,229.383,0H233.8a.883.883,0,0,1,.883.883V8.732a1.509,1.509,0,0,1-1.508,1.509Zm-1.582-3a.88.88,0,0,1,.6.234l.725.67V1.766h-2.649V8.144l.725-.67a.881.881,0,0,1,.6-.234Z",
    transform: "translate(-219.671)",
  },
];

//////// PROGRAM TASK ////////
const Task: Array<Path> = [
  {
    d: "M58.644,24.664H49.781A5.787,5.787,0,0,1,44,18.884V5.781A5.787,5.787,0,0,1,49.781,0h8.864a5.787,5.787,0,0,1,5.781,5.781v13.1A5.787,5.787,0,0,1,58.644,24.664ZM49.781,1.927a3.858,3.858,0,0,0-3.854,3.854v13.1a3.858,3.858,0,0,0,3.854,3.854h8.864A3.858,3.858,0,0,0,62.5,18.884V5.781a3.858,3.858,0,0,0-3.854-3.854ZM60.523,8.864A.963.963,0,0,0,59.56,7.9H57.922a1.447,1.447,0,0,1-1.445-1.445V4.817a.963.963,0,1,0-1.927,0V6.455a3.376,3.376,0,0,0,3.372,3.372H59.56A.963.963,0,0,0,60.523,8.864Z",
    transform: "translate(-44)",
  },
  {
    d: "M153.245,261.358a2.967,2.967,0,0,1-2.122-.886l-1.844-1.86a.963.963,0,0,1,1.368-1.357l1.844,1.86a1.057,1.057,0,0,0,1.507,0l3.808-3.832a.963.963,0,0,1,1.367,1.358l-3.807,3.831A2.967,2.967,0,0,1,153.245,261.358Z",
    transform: "translate(-143.942 -242.716)",
  },
];
//////// SETTINGS ////////
const Settings: Array<Path> = [
  {
    d: "M17.023,9.055a.923.923,0,0,1-.923-.923V3.7a1.847,1.847,0,0,0-1.845-1.845H9.319A1.847,1.847,0,0,0,7.474,3.7V8.132a.923.923,0,1,1-1.845,0V3.7A3.7,3.7,0,0,1,9.319.014h4.936A3.7,3.7,0,0,1,17.946,3.7V8.132A.923.923,0,0,1,17.023,9.055ZM23.2,6.714a.923.923,0,0,0-1.278.265L19.354,10.9H4.226L1.7,6.985a.923.923,0,1,0-1.55,1l2.5,3.866-1.033,1.5-.015.022A2.463,2.463,0,0,0,2.861,17.04v2.9a3.7,3.7,0,0,0,3.691,3.691H17.023a3.7,3.7,0,0,0,3.691-3.691.923.923,0,0,0-1.845,0,1.847,1.847,0,0,1-1.845,1.845H6.551a1.847,1.847,0,0,1-1.845-1.845V16.252a.923.923,0,0,0-.923-.923H3.662a.617.617,0,0,1-.521-.943l1.128-1.641H19.306L20.43,14.38a.617.617,0,0,1-.521.95H7.474a.923.923,0,0,0,0,1.845H19.909a2.463,2.463,0,0,0,2.059-3.814l-.011-.017-1.024-1.489,2.535-3.864A.923.923,0,0,0,23.2,6.714Z",
    transform: "translate(0 -0.014)",
  },
  {
    d: "M17.023,9.055a.923.923,0,0,1-.923-.923V3.7a1.847,1.847,0,0,0-1.845-1.845H9.319A1.847,1.847,0,0,0,7.474,3.7V8.132a.923.923,0,1,1-1.845,0V3.7A3.7,3.7,0,0,1,9.319.014h4.936A3.7,3.7,0,0,1,17.946,3.7V8.132A.923.923,0,0,1,17.023,9.055ZM23.2,6.714a.923.923,0,0,0-1.278.265L19.354,10.9H4.226L1.7,6.985a.923.923,0,1,0-1.55,1l2.5,3.866-1.033,1.5-.015.022A2.463,2.463,0,0,0,2.861,17.04v2.9a3.7,3.7,0,0,0,3.691,3.691H17.023a3.7,3.7,0,0,0,3.691-3.691.923.923,0,0,0-1.845,0,1.847,1.847,0,0,1-1.845,1.845H6.551a1.847,1.847,0,0,1-1.845-1.845V16.252a.923.923,0,0,0-.923-.923H3.662a.617.617,0,0,1-.521-.943l1.128-1.641H19.306L20.43,14.38a.617.617,0,0,1-.521.95H7.474a.923.923,0,0,0,0,1.845H19.909a2.463,2.463,0,0,0,2.059-3.814l-.011-.017-1.024-1.489,2.535-3.864A.923.923,0,0,0,23.2,6.714Z",
    transform: "translate(0 -0.014)",
  },
];

//////// HELP ////////
const Help: Array<Path> = [
  {
    d: "M222.889,343.22a1.238,1.238,0,0,0,0,2.476,1.238,1.238,0,1,0,0-2.476Z",
    transform: "translate(-211.441 -327.386)",
  },
  {
    d: "M187.19,140c-2.171,0-3.168,1.286-3.168,2.155a.922.922,0,0,0,.965.917c.868,0,.515-1.238,2.155-1.238.8,0,1.447.354,1.447,1.093,0,.868-.9,1.367-1.431,1.817a2.944,2.944,0,0,0-1.077,2.444c0,.836.225,1.077.884,1.077.788,0,.949-.354.949-.659a2.02,2.02,0,0,1,.9-2.01,4.116,4.116,0,0,0,1.8-2.943C190.615,141.144,189.248,140,187.19,140Z",
    transform: "translate(-175.533 -133.543)",
  },
  {
    d: "M11.81,0A11.8,11.8,0,0,0,0,11.81V22.7a.923.923,0,0,0,.923.923H11.81A11.81,11.81,0,0,0,11.81,0Zm0,21.775H1.845V11.81a9.965,9.965,0,1,1,9.965,9.965Z",
  },
];
//////// CHAT ////////
const Chat: Array<Path> = [
  {
    d: "M10,25.465h13c0.553,0,1-0.448,1-1s-0.447-1-1-1H10c-0.553,0-1,0.448-1,1S9.447,25.465,10,25.465z",
  },
  {
    d: "M36,29.465H10c-0.553,0-1,0.448-1,1s0.447,1,1,1h26c0.553,0,1-0.448,1-1S36.553,29.465,36,29.465z",
  },
  {
    d: "M36,35.465H10c-0.553,0-1,0.448-1,1s0.447,1,1,1h26c0.553,0,1-0.448,1-1S36.553,35.465,36,35.465z",
  },
  {
    d: "M54.072,2.535L19.93,2.465c-3.27,0-5.93,2.66-5.93,5.93v5.124l-8.07,0.017c-3.27,0-5.93,2.66-5.93,5.93v21.141 c0,3.27,2.66,5.929,5.93,5.929H12v10c0,0.413,0.254,0.784,0.64,0.933c0.117,0.045,0.239,0.067,0.36,0.067 c0.276,0,0.547-0.115,0.74-0.327l9.704-10.675l16.626-0.068c3.27,0,5.93-2.66,5.93-5.929v-0.113l5.26,5.786 c0.193,0.212,0.464,0.327,0.74,0.327c0.121,0,0.243-0.022,0.36-0.067c0.386-0.149,0.64-0.52,0.64-0.933v-10h1.07 c3.27,0,5.93-2.66,5.93-5.929V8.465C60,5.196,57.341,2.536,54.072,2.535z M44,40.536c0,2.167-1.763,3.929-3.934,3.929l-17.07,0.07 c-0.28,0.001-0.548,0.12-0.736,0.327L14,53.949v-8.414c0-0.552-0.447-1-1-1H5.93c-2.167,0-3.93-1.763-3.93-3.929V19.465 c0-2.167,1.763-3.93,3.932-3.93l9.068-0.019c0,0,0,0,0,0c0.001,0,0.001,0,0.002,0l25.068-0.052c2.167,0,3.93,1.763,3.93,3.93 v18.441V40.536z M58,29.606c0,2.167-1.763,3.929-3.93,3.929H52c-0.553,0-1,0.448-1,1v8.414l-5-5.5V19.395 c0-3.27-2.66-5.93-5.932-5.93L16,13.514v-5.12c0-2.167,1.763-3.93,3.928-3.93l34.141,0.07c0.001,0,0.001,0,0.002,0 c2.167,0,3.93,1.763,3.93,3.93V29.606z",
  },
];
//////// SETTINGS ////////
const Expand: Array<Path> = [
  {
    d: "M7.187,12.493H3.4a.4.4,0,0,1,0-.791H8.142a.4.4,0,0,1,.4.4v4.747a.4.4,0,0,1-.791,0V13.052L3.675,17.124a.4.4,0,0,1-.559-.559Zm5.865-4.747h3.792a.4.4,0,0,1,0,.791H12.1a.4.4,0,0,1-.4-.4V3.4a.4.4,0,0,1,.791,0V7.187l4.071-4.071a.4.4,0,0,1,.559.559Z",
    transform: "translate(-3 -3)",
  },
];
//////// BELL ////////
const Bell: Array<Path> = [
  {
    d: "M18.51,14.649l-1.047-1.506a1.225,1.225,0,0,1-.22-.7V8.123A6.123,6.123,0,1,0,5,8.123v4.323a1.225,1.225,0,0,1-.22.7L3.73,14.649a1.225,1.225,0,0,0,.906,2.045H8.12a3.061,3.061,0,0,0,6,0H17.6a1.225,1.225,0,0,0,.906-2.045Zm-7.39,3.269a1.837,1.837,0,0,1-1.727-1.225h3.453a1.837,1.837,0,0,1-1.727,1.225ZM4.636,15.47a.5.5,0,0,0,.073-.086l1.071-1.543a2.449,2.449,0,0,0,.441-1.4V8.123a4.9,4.9,0,1,1,9.8,0v4.323a2.449,2.449,0,0,0,.441,1.4l1.071,1.543a.5.5,0,0,0,.073.086Z",
    transform: "translate(-3.415 -2)",
  },
];
//////// WARNING ////////
const Warning: Array<Path> = [
  {
    d: "M219.155,333.382a.93.93,0,1,0,0,1.859.93.93,0,0,0,0-1.859Z",
    transform: "translate(-210.11 -321.762)",
  },
  {
    d: "M17.648,35.8a3.174,3.174,0,0,0,.007-3.213L11.833,22.5a3.208,3.208,0,0,0-5.57,0l-5.83,10.1a3.22,3.22,0,0,0,2.789,4.827H14.852A3.2,3.2,0,0,0,17.648,35.8Zm-1.264-.729a1.757,1.757,0,0,1-1.536.889H3.217a1.733,1.733,0,0,1-1.517-.87,1.76,1.76,0,0,1,0-1.774l5.83-10.092a1.751,1.751,0,0,1,3.042,0L16.4,33.316A1.736,1.736,0,0,1,16.384,35.068Z",
    transform: "translate(0 -20.882)",
  },
  {
    d: "M218.443,157.2a1.011,1.011,0,0,0-.718,1.015c.022.294.041.591.063.885.063,1.119.126,2.216.19,3.335a.681.681,0,0,0,.7.654.7.7,0,0,0,.7-.677c0-.231,0-.442.022-.677.041-.718.086-1.435.126-2.153.022-.465.063-.93.086-1.394a1.148,1.148,0,0,0-.086-.465A.932.932,0,0,0,218.443,157.2Z",
    transform: "translate(-209.629 -152.108)",
  },
];
//////// LOG OUT ////////
const LogOut: Array<Path> = [
  {
    d: "M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z",
  },
];

//////// ARROW LEFT ////////
const ArrowLeft: Array<Path> = [
  {
    d: "M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z",
  },
];

export default {
  Bars,
  Dashboard,
  Courses,
  Task,
  Settings,
  Help,
  Chat,
  Expand,
  Bell,
  Warning,
  LogOut,
  ArrowLeft,
} as IconsLib;
