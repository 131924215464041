import { useEffect, useState } from "react";
import MaintenanceService from "../../api/maintenance/maintenanceService";
import { IMaintenance } from "../../interfaces/IMaintenance";
import "./Maintenance.scss";
import { timeFormatter } from "../../utils/common";

const Maintenance: React.FC = () => {
  const [maintenance, setMaintenance] = useState<IMaintenance | null>(null);

  useEffect(() => {
    MaintenanceService.SubscribeForMessages(() => {
      setMaintenance(MaintenanceService.GetScheduledMaintenanceIfExists());
    });
  });

  return (
    <div className='root'>
      <div className='wrapper'>
        <div className='loader_cogs'>
          <div className='COGfirst'>
            <div className='firstPart'></div>
            <div className='firstPart'></div>
            <div className='firstPart'></div>
            <div className='firstHole'></div>
          </div>
          <div className='COGsecond'>
            <div className='secondPart'></div>
            <div className='secondPart'></div>
            <div className='secondPart'></div>
            <div className='secondHole'></div>
          </div>
          <div className='COGthird'>
            <div className='thirdPart'></div>
            <div className='thirdPart'></div>
            <div className='thirdPart'></div>
            <div className='thirdHole'></div>
          </div>
        </div>
        <h2>{maintenance?.notes}</h2>
        <h4>{maintenance?.message}</h4>
        {maintenance?.endTime && (
          <h5>
            We shall be back online untill{" "}
            <b> {timeFormatter(maintenance?.endTime)}</b>
          </h5>
        )}
      </div>
    </div>
  );
};

export default Maintenance;
