import { QueryFunctionContext, useQuery, useQueryClient } from "react-query";
import { IChatMessage } from "../../interfaces/IChatMessage";
import { IClassQuestionTopic } from "../../interfaces/IClassQuestionTopic";
import {
  CEditPayload,
  CPayload,
  ICommentPayload,
  IQuestionPayload,
  QEditPayload,
  QPayload,
} from "../../interfaces/IQuestion";
import { EServiceTier } from "../../interfaces/IServiceTier";
import { EnrolledClass } from "../../interfaces/IUser";
import { cacheTimes } from "../cacheTimesRQ";
import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";

/// QUESTIONS TOPICS

const fetchClassQuestions = ({ queryKey }: QueryFunctionContext) => {
  const weekId: string = queryKey[1] as string;
  const termId: string = queryKey[2] as string;
  const classId: string = queryKey[3] as string;
  const courseId: string = queryKey[4] as string;
  const serviceTier: EServiceTier = queryKey[5] as EServiceTier;

  return requestApi({
    url: ApiEndpoints.GET_CLASS_QUESTIONS,
    method: "post",
    data: {
      weekId,
      termId,
      classId,
      courseId,
      serviceTier,
    },
  });
};

export const useClassQuestions = (
  weekId: string,
  termId: string,
  classId: string,
  courseId: string,
  serviceTier: string,
  enabled?: boolean
) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["class-questions", weekId, termId, classId, courseId, serviceTier],
    fetchClassQuestions,
    {
      enabled: enabled,
      cacheTime: cacheTimes.CLASS_QUESTIONS_CACHE,
      staleTime: cacheTimes.CLASS_QUESTIONS_STALE,
      refetchOnWindowFocus: true,
      initialData: queryClient.getQueryData([
        "class-questions",
        weekId,
        termId,
        classId,
        courseId,
        serviceTier,
      ]),
      select: (data) =>
        data.data.questions.sort(
          (a: IClassQuestionTopic, b: IClassQuestionTopic) =>
            a.unixTimestamp - b.unixTimestamp
        ),
      onError: (err) => {
        console.log(err);
      },
    }
  );
};

export const addNewQuestion = (payload: IQuestionPayload) => {
  return requestApi({
    url: ApiEndpoints.ADD_CLASS_QUESTION,
    method: "post",
    data: payload,
  });
};

export const editQuestion = (payload: QEditPayload) => {
  return requestApi({
    url: ApiEndpoints.EDIT_CLASS_QUESTION,
    method: "post",
    data: payload,
  });
};

export const deleteQuestion = (payload: QPayload) => {
  return requestApi({
    url: ApiEndpoints.DELETE_CLASS_QUESTION,
    method: "post",
    data: payload,
  });
};

// COMMENTS

const fetchCommentsForQuestion = ({ queryKey }: QueryFunctionContext) => {
  const weekId: string = queryKey[1] as string;
  const termId: string = queryKey[2] as string;
  const userClass: EnrolledClass = queryKey[3] as EnrolledClass;
  const questionId: string = queryKey[4] as string;

  return requestApi({
    url: ApiEndpoints.GET_CLASS_QUESTIONS_COMMENTS,
    method: "post",
    data: {
      weekId,
      termId,
      ...userClass,
      questionId,
    },
  });
};

export const useComments = (
  weekId: string,
  termId: string,
  classId: string,
  serviceTier: string,
  courseId: string,
  questionId: string,
  enabled?: boolean
) => {
  const queryClient = useQueryClient();

  return useQuery(
    [
      "comments-for-question",
      weekId,
      termId,
      {
        classId: classId,
        serviceTier: serviceTier,
        courseId: courseId,
      },
      questionId,
    ],
    fetchCommentsForQuestion,
    {
      enabled: enabled,
      cacheTime: cacheTimes.CLASS_QUESTION_COMMENTS_CACHE,
      staleTime: cacheTimes.CLASS_QUESTION_COMMENTS_STALE,
      refetchOnWindowFocus: true,
      initialData: queryClient.getQueryData([
        "comments-for-question",
        weekId,
        termId,
        {
          classId: classId,
          serviceTier: serviceTier,
          courseId: courseId,
        },
        questionId,
      ]),
      select: (data) =>
        data.data.comments.sort(
          (a: IChatMessage, b: IChatMessage) =>
            a.unixTimestamp - b.unixTimestamp
        ),
      onError: (err) => {
        console.log(err);
      },
    }
  );
};

export const addCommentToQuestion = (payload: ICommentPayload) => {
  return requestApi({
    url: ApiEndpoints.ADD_COMMENT_TO_CLASS_QUESTION,
    method: "post",
    data: payload,
  });
};

export const editComment = (payload: CEditPayload) => {
  return requestApi({
    url: ApiEndpoints.EDIT_CLASS_QUESTIONS_COMMENTS,
    method: "post",
    data: payload,
  });
};

export const deleteComment = (payload: CPayload) => {
  return requestApi({
    url: ApiEndpoints.DELETE_CLASS_QUESTIONS_COMMENTS,
    method: "post",
    data: payload,
  });
};
