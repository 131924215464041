import { storage } from "../firebase";
import {
  getDownloadURL,
  ref,
  StorageReference,
  uploadBytes,
  UploadMetadata,
  UploadResult,
} from "firebase/storage";

class StorageServiceInstance {
  public async UploadProfilePicture(
    picture: File,
    userId: string
  ): Promise<string> {
    // const userId = auth.currentUser?.uid;

    const fileMetadata: UploadMetadata = {
      customMetadata: {
        userId: userId,
        usedFor: "PROFILE_PICTURE",
      },
      cacheControl: "public,max-age=604800",
      contentType: picture.type,
    };

    const homeworkRef: StorageReference = ref(
      storage,
      `Users/ProfilePictures/${userId}`
    );

    const uploadResult: UploadResult = await uploadBytes(
      homeworkRef,
      picture,
      fileMetadata
    );

    return getDownloadURL(uploadResult.ref);
  }

  public async DownloadSyllabusPdfFile(
    pdfFileName: string,
    courseId: string
  ): Promise<void> {
    const pdfRef: StorageReference = ref(
      storage,
      `Syllabuses/${courseId}/${pdfFileName}`
    );

    getDownloadURL(pdfRef)
      .then((url) => {
        // Create an XMLHttpRequest object
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";

        xhr.onload = function () {
          if (xhr.status === 200) {
            // Create a temporary anchor element
            const tempLink = document.createElement("a");
            const filename = pdfFileName;

            tempLink.href = window.URL.createObjectURL(xhr.response);
            tempLink.download = filename;

            // Programmatically click the temporary link to trigger the download
            tempLink.click();

            // Clean up the temporary object
            window.URL.revokeObjectURL(tempLink.href);
          }
        };

        // Send the XHR request
        xhr.send();
      })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  }
}

const StorageService: StorageServiceInstance = new StorageServiceInstance();

export default StorageService;
