import { ReactNode } from "react";
import cls from "./Modal.module.scss";
import "./Modal.scss";
type Props = {
  children: ReactNode;
  isVisible: boolean;
  width?: number;
  height?: number;
  fullScreen?: boolean;
};

const Modal: React.FC<Props> = ({
  children,
  isVisible,
  width,
  height,
  fullScreen,
}) => {
  if (!isVisible) return null;

  return (
    <div className={cls.modal}>
      <div
        className={`${cls.wrapper} ${fullScreen ? cls.fullScreen : ""}`}
        style={{ width: width, height: height }}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
