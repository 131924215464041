import cls from "./PrioritySelector.module.scss";
import React, { useRef, useState } from "react";
import { FieldError } from "react-hook-form/dist/types";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import Icon from "../../../assets/icons/Icon";

import { ESupportTicketPriority } from "../../../interfaces/ISupportTicket";

type Props = {
  width?: number;
  height?: number;
  defaultValue?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: FieldError | undefined;
};

const PrioritySelector: React.FC<Props> = ({
  width,
  height,
  defaultValue,
  onChange,
  error,
}) => {
  const refField = useRef(null);
  const [priorityList, showPriorityList] = useState<boolean>(false);
  const [selectedPriority, setSelectedPriority] = useState<string>(
    defaultValue || ""
  );

  const handleClickOutsideMenu = (e: MouseEvent) => {
    showPriorityList(false);
  };
  useOnClickOutside(refField, handleClickOutsideMenu);

  /////
  const handleShowComponentList = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    showPriorityList(!priorityList);
  };
  /////
  const handleSelectPriority = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const val = e.target.value;

    setSelectedPriority(val);
    showPriorityList(false);
    onChange(e);
  };

  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      <div className={cls.wrapper} ref={refField}>
        <div
          className={`${cls.select} ${priorityList ? cls.expanded : ""}`}
          onClick={handleShowComponentList}>
          <div className={cls.label}>Priority</div>
          <Icon
            icon='ArrowLeft'
            viewBox='0 0 320 512'
            size={18}
            className={priorityList ? cls.expanded : ""}
          />

          <div className={cls.value}>
            {selectedPriority.toLocaleLowerCase()}
          </div>
        </div>

        <div className={`${cls.options} ${priorityList ? cls.show : ""}`}>
          {Object.values(ESupportTicketPriority).map((priority) => (
            <div key={priority} className={cls.option}>
              <label htmlFor={priority}>{priority}</label>
              <input
                name='select'
                type='radio'
                value={priority}
                id={priority}
                onChange={handleSelectPriority}
              />
            </div>
          ))}
        </div>
      </div>
      <p className={cls.error}>{`${error ? error.message : ""}`}</p>
    </div>
  );
};

export default PrioritySelector;
