import "./ChatContainer.scss";

import { RefObject, useEffect, useRef, useState } from "react";

import {
  ChatMessageCallback,
  EMessageCallbackType,
  IChatService,
} from "../../../api/chat/chatService";
import { IChatMessage } from "../../../interfaces/IChatMessage";
import ChatMessage from "../chatMessage/ChatMessage";

import { GetUserId } from "../../../api/firebase";
import { useQueries, useQueryClient } from "react-query";
import { fetchPublicUser } from "../../../api/user/user";
import Loading from "../loading/Loading";
import { cacheTimes } from "../../../api/cacheTimesRQ";

import MessageSubmitter from "../messageSubmitter/MessageSubmitter";
// import { useParams } from "react-router-dom";
// import { UrlParams } from "../../../interfaces/UrlParams";
interface Props {
  chatService: IChatService | null;
  shouldMarkReceivingMessagesAsRead?: boolean;
  // isHidden?: boolean;
}

const ChatContainer: React.FC<Props> = ({
  chatService,
  shouldMarkReceivingMessagesAsRead = true,
}) => {
  // const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  // const { classId } = routeParameters;

  const queryClient = useQueryClient();

  const [usersToFetch, setUsersToFetch] = useState<string[]>([]);

  const messagesEndRef: RefObject<HTMLDivElement> = useRef(null);
  const chatContainerRef: RefObject<HTMLDivElement> = useRef(null);
  const [messages, setMessages] = useState<IChatMessage[]>([]);

  const scrollToLastMessage = () => {
    const lastMessageDiv: HTMLDivElement | null = messagesEndRef.current;

    if (!lastMessageDiv) {
      return;
    }

    lastMessageDiv.scrollIntoView({
      behavior: "auto",
      block: "end",
      inline: "nearest",
    });
  };

  const publicUserQueries = useQueries(
    usersToFetch.map((userId: string) => {
      return {
        queryKey: ["public-user", userId],
        queryFn: () => fetchPublicUser(userId),
        initialData: queryClient.getQueryData(["public-user", userId]),
        cacheTime: cacheTimes.PUBLIC_USER_DATA_CACHE,
        staleTime: cacheTimes.PUBLIC_USER_DATA_STALE,
        refetchOnMount: false,
        enabled: usersToFetch.length > 0,
      };
    })
  );

  const OnMessageReceived = async (
    chatMessageCallback: ChatMessageCallback
  ) => {
    const usersToFetch: string[] = [];

    for (const message of chatMessageCallback.messages)
      if (!queryClient.getQueryData(["public-user", message.userId]))
        if (!usersToFetch.includes(message.userId))
          usersToFetch.push(message.userId);

    setUsersToFetch(usersToFetch);

    if (
      shouldMarkReceivingMessagesAsRead &&
      chatMessageCallback.messages.length > 0
    ) {
      const lastMessageRead: IChatMessage =
        chatMessageCallback.messages[chatMessageCallback.messages.length - 1];

      chatService?.SetLastMessageRead(lastMessageRead);
    }

    setMessages([...chatMessageCallback.messages]);

    const divContainer: HTMLDivElement | null = chatContainerRef.current
      ?.children[0] as HTMLDivElement;

    if (!divContainer) {
      console.log("OnMessageReceived..", divContainer);
      return;
    }

    const isScrolledToBottom: boolean =
      divContainer.scrollHeight -
        (divContainer.scrollTop + divContainer.offsetHeight) <
      70;
    const isScrolledToTop: boolean = divContainer.scrollTop < 35;

    if (isScrolledToBottom) {
      return setTimeout(() => {
        scrollToLastMessage();
      }, 0);
    }

    if (
      isScrolledToTop &&
      chatMessageCallback.type === EMessageCallbackType.OLDER
    )
      divContainer.children
        .item(chatMessageCallback.numberOfMessagesAdded)
        ?.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "start",
        });
  };

  const SubmitMessage = (textToSubmit: string) => {
    if (textToSubmit.length < 1) return;

    setMessages([
      ...messages,
      {
        content: textToSubmit,
        id: "",
        userId: GetUserId() || "",
        unixTimestamp: Date.now(),
      },
    ]);

    chatService?.SubmitMessage(textToSubmit).catch((err) => console.log(err));

    setTimeout(() => {
      scrollToLastMessage();
    }, 0);
  };

  useEffect(() => {
    const unsubscribe = chatService?.SubscribeForMessages(OnMessageReceived);
    // if (classId) {
    chatService?.LoadPreviousMessagesEventSafe();
    chatService?.LoadNextMessagesEventSafe();

    return unsubscribe;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatService]);

  return (
    <div className='chat'>
      {!publicUserQueries && <Loading />}

      <div className='wrapper' ref={chatContainerRef}>
        <div className='chatlist'>
          {messages.map((message: IChatMessage, index: number) => (
            <ChatMessage comment={message} key={index} />
          ))}
          <div ref={messagesEndRef}></div>
        </div>
      </div>
      <div className='actions'>
        <MessageSubmitter emojiEnabled={true} submitMessage={SubmitMessage} />
      </div>
    </div>
  );
};

export default ChatContainer;
