import cls from "./AdminCourseSelector.module.scss";

import React, { useEffect, useRef, useState } from "react";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import Icon from "../../../assets/icons/Icon";
import { useCourses } from "../../../api/course/course";
import { ICourse } from "../../../interfaces/ICourse";
import { useEnrolledClasses } from "../../../api/class/class";
import { IClass } from "../../../interfaces/IClass";
import { useNavigate, useParams } from "react-router-dom";
import { UrlParams } from "../../../interfaces/UrlParams";
import { useQueryClient } from "react-query";
import { requestApi } from "../../../api/requestApi";
import { ApiEndpoints } from "../../../api/endpoints";

const AdminCourseSelector: React.FC = () => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, classId, termId, weekId, videoId } = routeParameters;

  const { data: courses } = useCourses();
  const { data: enrolledClasses } = useEnrolledClasses();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const refCourseField = useRef(null);
  const refClassField = useRef(null);

  const [courseList, showCourseList] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<ICourse>();

  const [classList, showClassList] = useState<boolean>(false);
  const [classListData, setClassListdata] = useState<IClass[]>();
  const [selectedClass, setSelectedClass] = useState<IClass>();

  const getPathToLecture = async (selectedClass: IClass) => {
    // //// CHECK IF ALREADY ON VIDEO PAGE
    // if (courseId && classId && termId && videoId)
    //   return `/course-overview/${courseId}/${selectedClass.id}/${termId}/${weekId}/${videoId}`;

    const _courseId = selectedClass.courseId;

    /////////////GET FIRST TERM/////////////////
    let firstTermId: string;

    const cacheTerms: any = queryClient.getQueryData([
      "terms-for-course",
      _courseId,
    ]);

    if (cacheTerms && cacheTerms.data.modules.length > 0) {
      firstTermId = cacheTerms.data.modules[0].id;
    } else {
      const termsData = await requestApi({
        url: ApiEndpoints.MODULES_FOR_COURSE,
        method: "post",
        data: { courseId: _courseId },
      });
      queryClient.setQueryData(["terms-for-course", _courseId], termsData);
      firstTermId =
        termsData.data.modules.length > 0
          ? termsData.data.modules[0].id
          : undefined;
    }

    if (!firstTermId) return;

    /////////////GET FIRST WEEK/////////////////
    let firstWeekId: string;

    const cacheWeeks: any = queryClient.getQueryData([
      "weeks",
      _courseId,
      firstTermId,
    ]);

    if (cacheWeeks && cacheWeeks.data.weeks.length > 0) {
      firstWeekId = cacheWeeks.data.weeks[0].id;
    } else {
      const weeksData = await requestApi({
        url: ApiEndpoints.GET_WEEKS,
        method: "post",
        data: { courseId: _courseId, termId: firstTermId },
      });
      queryClient.setQueryData(["weeks", _courseId, firstTermId], weeksData);
      firstWeekId =
        weeksData.data.weeks.length > 0
          ? weeksData.data.weeks[0].id
          : undefined;
    }

    if (!firstWeekId) return;

    /////////////GET FIRST VIDEO/////////////////
    let firstVideoId: string;

    const cacheVideos: any = queryClient.getQueryData([
      "videos",
      _courseId,
      firstTermId,
      firstWeekId,
    ]);

    if (cacheVideos && cacheVideos.data.videos.length > 0) {
      firstVideoId = cacheVideos.data.videos[0].id;
    } else {
      const videosData = await requestApi({
        url: ApiEndpoints.GET_VIDEOS,
        method: "post",
        data: { courseId: _courseId, termId: firstTermId, weekId: firstWeekId },
      });
      queryClient.setQueryData(["videos", _courseId, firstTermId], firstWeekId);
      firstVideoId =
        videosData.data.videos.length > 0
          ? videosData.data.videos[0].id
          : undefined;
    }

    if (!firstVideoId) return;

    return `/course-overview/${_courseId}/${selectedClass.id}/${firstTermId}/${firstWeekId}/${firstVideoId}`;
  };

  ///// CLICK OUTSIDE HANDLER //////
  const handleClickOutsideCourseMenu = (e: MouseEvent) => {
    showCourseList(false);
  };
  useOnClickOutside(refCourseField, handleClickOutsideCourseMenu);

  const handleClickOutsideClassMenu = (e: MouseEvent) => {
    showClassList(false);
  };
  useOnClickOutside(refClassField, handleClickOutsideClassMenu);

  ///////COURSE LIST HANDLERS /////////
  const handleShowCourseList = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    showCourseList(!courseList);
  };

  const handleSelectCourse = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!courses || !enrolledClasses) return;

    //set defaults
    setSelectedClass(undefined);

    const courseId = e.currentTarget.id;
    const course: ICourse = courses?.find(
      (course: ICourse) => course.id === courseId
    );
    const classListData: IClass[] = enrolledClasses?.filter(
      (c: IClass) => c.courseId === courseId
    );
    setSelectedCourse(course);
    setClassListdata(classListData);
    showCourseList(false);
  };

  ///////CLASS LIST HANDLERS /////////
  const handleShowClassList = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!selectedCourse) return;
    showClassList(!classList);
  };

  const handleSelectClass = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!classListData) return;
    const classId = e.currentTarget.id;
    const cls = classListData?.find((c: IClass) => c.id === classId);

    setSelectedClass(cls);
    showClassList(false);

    if (cls) {
      const path = await getPathToLecture(cls);
      console.log(path);
      if (path) navigate(path);
    }
  };

  useEffect(() => {
    if (!courses || !courseId) return;
    const course: ICourse = courses?.find(
      (course: ICourse) => course.id === courseId
    );
    setSelectedCourse(course);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, courses]);

  useEffect(() => {
    if (!enrolledClasses || !classId || !courseId) return;

    const classListData: IClass[] = enrolledClasses?.filter(
      (c: IClass) => c.courseId === courseId
    );
    setClassListdata(classListData);

    const cls = enrolledClasses?.find((c: IClass) => c.id === classId);
    setSelectedClass(cls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classId, enrolledClasses]);

  return (
    <div style={{ marginBottom: 60 }}>
      <div className={cls.root} ref={refCourseField}>
        <div
          className={`${cls.select} ${courseList ? cls.show : ""}`}
          onClick={handleShowCourseList}>
          <Icon
            icon='ArrowLeft'
            viewBox='0 0 320 512'
            size={18}
            className={courseList ? cls.expanded : ""}
          />
          <label className={cls.label}>Select Course</label>
          <div className={cls.value}>{selectedCourse?.title}</div>
        </div>

        <div className={`${cls.options} ${courseList ? cls.show : ""}`}>
          {courses?.map((course: ICourse, index: number) => {
            return (
              <div key={index} className={cls.option}>
                <input
                  name='select'
                  type='radio'
                  value={course.title}
                  id={course.id}
                  onChange={handleSelectCourse}
                />
                <label htmlFor={course.id}>{course.title}</label>
              </div>
            );
          })}
        </div>
      </div>

      {/* //////////////// */}
      <div
        className={`${cls.root} ${!selectedCourse ? cls.disabled : ""}`}
        ref={refClassField}>
        <div
          className={`${cls.select} ${classList ? cls.show : ""}`}
          onClick={handleShowClassList}>
          <Icon
            icon='ArrowLeft'
            viewBox='0 0 320 512'
            size={18}
            className={classList ? cls.expanded : ""}
          />
          <label className={cls.label}>Select Class</label>
          <div className={cls.value}>{selectedClass?.name}</div>
        </div>

        <div className={`${cls.options} ${classList ? cls.show : ""}`}>
          {classListData?.map((cl: IClass, index: number) => {
            return (
              <div key={index} className={cls.option}>
                <input
                  name='select'
                  type='radio'
                  value={cl.name}
                  id={cl.id}
                  onChange={handleSelectClass}
                />
                <label htmlFor={cl.id}>{cl.name}</label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminCourseSelector;
