export enum ESupportTicketPriority {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  URGENT = "URGENT",
}

export enum ESupportTicketStatus {
  OPEN = "OPEN",
  IN_PROGRESS = "IN_PROGRESS",
  CLOSED = "CLOSED",
}

export type SupportTicketCategory = {
  id: string;
  name: string;
  isArchived: boolean;
};

export interface ISupportTicket {
  categoryId: string;
  timestamp: number;
  status: ESupportTicketStatus;
  userId: string;
  message: string;
  id: string;
  priority?: ESupportTicketPriority;
}
