import cls from "./CourseItem.module.scss";

import Icon from "../../../assets/icons/Icon";
import TermItem from "../termItem/TermItem";
import { useEffect, useRef, useState } from "react";

import { fetchWeeks, useTerms } from "../../../api/course/course";
import { ILecturer } from "../../../interfaces/ILecturer";
import { ITerm } from "../../../interfaces/ITerm";
import { ICourse } from "../../../interfaces/ICourse";
import { useQueries, useQueryClient } from "react-query";
import { cacheTimes } from "../../../api/cacheTimesRQ";
import { IProgress, ProgressTitles } from "../../../interfaces/IProgress";
import LazyImage from "../../common/lazyImage/LazyImage";
import { useUserProgress } from "../../../api/user/user";
import ProgressBar2 from "../../common/progressBars/progressBar2/ProgressBar2";
import { useEnrolledClasses } from "../../../api/class/class";
import { IClass } from "../../../interfaces/IClass";

type Props = {
  data: ICourse;
  // progress: IProgress[]
};

const CourseItem: React.FC<Props> = ({ data }) => {
  const termsContainerRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const {
    data: activityProgressData,
    // refetch: refetchActivityProgress
  } = useUserProgress();
  const { data: enrolledClasses } = useEnrolledClasses();
  const { data: terms } = useTerms(data.id);

  const [termIds, setTermIds] = useState<string[]>();
  const [weekTotal, setWeeksTotal] = useState<number>();
  const [expandedDetails, setExpandedDetails] = useState<boolean>(false);
  const [courseProgress, setCourseProgress] = useState<number>(0);

  const LoadTerms = async () => {
    const termIds: string[] = [];
    if (terms) {
      for (const term of terms) termIds.push(term.id);
    }

    setTermIds(termIds);
  };

  const handleExpandDetails = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setExpandedDetails(!expandedDetails);
  };

  const calculateProgress = () => {
    let result: number = 0;
    if (terms && activityProgressData) {
      for (const term of terms) {
        const termHasProgress = activityProgressData?.filter(
          (progressData: IProgress) => progressData.termId === term.id
        );
        if (termHasProgress?.length > 0)
          result += termHasProgress[0].percentageCompleted;
      }
      result = result / terms.length;
    }
    return result;
  };

  // get weeks for each term ->  count for each course
  const weekQueries = useQueries(
    (termIds || []).map((termId: string) => {
      return {
        queryKey: ["weeks", data.id, termId],
        queryFn: fetchWeeks,
        enabled: !!termIds,
        cacheTime: cacheTimes.WEEK_LIST_CACHE,
        staleTime: cacheTimes.WEEK_LIST_STALE,
        initialData: queryClient.getQueryData(["weeks", data.id, termId]),
        refetchOnWindowFocus: false,
      };
    })
  );

  useEffect(() => {
    LoadTerms().catch((e) => console.log(e));
    setCourseProgress(calculateProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terms, activityProgressData]);

  useEffect(() => {
    if (weekQueries && termIds) {
      let wkCount = 0;
      for (const term of termIds) {
        const wkQuery: any = queryClient.getQueryData(["weeks", data.id, term]);
        if (wkQuery) wkCount += wkQuery.data.weeks.length;
      }
      setWeeksTotal(wkCount);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekQueries]);

  useEffect(() => {
    if (expandedDetails)
      termsContainerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedDetails]);

  return (
    <div className={cls.root}>
      <div className={cls.wrapper}>
        <div className={cls.avatar} onClick={handleExpandDetails}>
          {/* <img src={data.thumbnailUrl || noImgUrl} loading='lazy' /> */}
          <LazyImage
            src={data.thumbnailUrl || "/placeholder.webp"}
            type='course'
          />
        </div>
        <div
          className={`${cls.details} ${expandedDetails ? cls.expanded : ""}`}
          onClick={handleExpandDetails}>
          <h4 className={cls.subtitle}>{data.title}</h4>
          <h1 className={cls.title}>{data.title}</h1>
          <div className={cls.description}>
            {data.description.length > 460
              ? data.description.substring(0, 460) + "..."
              : data.description}
          </div>
          <div className={cls.footer}>
            {data.lecturers?.length !== 0 && (
              <div className={cls.instructor}>
                {data.lecturers.map((lecturer: ILecturer, idx: number) => (
                  <img key={idx} width='24px' src={lecturer.avatarUrl} alt='' />
                ))}
                <span>with </span>
                {data.lecturers.map((lecturer: ILecturer, idx: number) => (
                  <span key={idx}>
                    {lecturer.name}
                    {idx < (data.lecturers?.length || 0) - 1 && <span>,</span>}
                  </span>
                ))}
              </div>
            )}

            <div className={cls.terms}>
              Terms: <span>{terms?.length || 0}</span>
            </div>
            <div className={cls.weeks}>
              Weeks: <span>{weekTotal}</span>
            </div>

            <div className={cls.progress}>
              <ProgressBar2
                labelStyle={{
                  fontSize: "0.875rem",
                  fontWeight: 300,

                  color: "var(--text-color-1)",
                }}
                total={72}
                percentage={courseProgress}
              />
            </div>
          </div>

          <Icon icon='ArrowLeft' className={cls.arrow} viewBox='0 0 320 512' />
        </div>
      </div>

      <div
        className={`${cls.termList} ${expandedDetails ? cls.expanded : ""}`}
        ref={termsContainerRef}>
        <h2 className={cls.title}>Terms</h2>
        <h5 className={cls.subtitle}>
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. */}
        </h5>

        <div className={cls.list}>
          {terms?.map((term: ITerm, index: number) => {
            return (
              <TermItem
                id={term.id}
                courseId={data.id}
                // courseTitle={data.title}
                enrolledClass={enrolledClasses?.find(
                  (enrolledClass: IClass) => enrolledClass.courseId === data.id
                )}
                key={index}
                avatar={term.thumbnailUrl || "/placeholder.webp"}
                title={term.title}
                subtitle=''
                description={term.description}
                progress={activityProgressData?.filter(
                  (progress: ProgressTitles) => progress.termId === term.id
                )}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CourseItem;
