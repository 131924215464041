import { useEffect, useRef, useState } from "react";
import { useMutation, useQueries, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  addCommentToQuestion,
  useComments,
} from "../../../api/classQuestions/classQuestions";
import { GetUserId } from "../../../api/firebase";
import { fetchPublicUser } from "../../../api/user/user";

import { IChatMessage } from "../../../interfaces/IChatMessage";

import { IClassQuestionTopic } from "../../../interfaces/IClassQuestionTopic";

import { EServiceTier } from "../../../interfaces/IServiceTier";
import { IUser } from "../../../interfaces/IUser";
import { UrlParams } from "../../../interfaces/UrlParams";
import {
  isTimestampMoreThanOneHourAgo,
  timeFormatter,
} from "../../../utils/common";
import MessageSubmitter from "../../common/messageSubmitter/MessageSubmitter";
import cls from "./ClassQuestionTopic.module.scss";
import CommentModMenu from "./CommentModMenu";
import QuestionModMenu from "./QuestionModMenu";

type Props = {
  question: IClassQuestionTopic;
  classTier: EServiceTier;
  isQuestionIdInUrl?: string | null;
  user: {
    data: {
      user: IUser;
    };
  };
  userRole: string | null | undefined;
};

const ClassQuestionTopic: React.FC<Props> = ({
  question,
  user,
  classTier,
  isQuestionIdInUrl,
  userRole,
}) => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, classId, termId, weekId } = routeParameters;

  // const [searchParams, setSearchParams] = useSearchParams();

  // const questionUrlId = searchParams.get("questionId");

  const questionTopicRef = useRef<HTMLDivElement>(null);

  // fetch comments for question
  const { data: comments } = useComments(
    weekId,
    termId,
    classId,
    classTier,
    courseId,
    question.id
  );

  const queryClient = useQueryClient();

  const [areUsersLoaded, setUsersLoaded] = useState<boolean>(false);
  const [usersToFetch, setUsersToFetch] = useState<string[]>([]);

  const [replies, showReplies] = useState<boolean>(false);

  const [focusMessageInput, setFocusMessageInput] = useState<boolean>(false);

  const addCommentToQuestionRQ = useMutation(addCommentToQuestion, {
    onSuccess: () => {
      // Invalidates cache and refetch
      //   alert("reply added successfully!");
      queryClient.invalidateQueries([
        "comments-for-question",
        weekId,
        termId,
        {
          classId,
          serviceTier: classTier,
          courseId,
        },
        question.id,
      ]);
    },
    onError: (err) => {
      console.log("addCommentToQuestionRQ", err);
    },
    // onSettled: () => {
    //   setSubmitButtonEnabled(false);
    //   setReplyDescription("");
    // },
  });

  const handleSubmitReply = (textToSubmit: string) => {
    // e.preventDefault();
    if (textToSubmit.length === 0) return;
    // setSubmitButtonEnabled(true);
    const payload = {
      weekId,
      serviceTier: classTier,
      classId,
      courseId,
      termId: termId,
      questionId: question.id,
      comment: textToSubmit,
    };
    return addCommentToQuestionRQ.mutate(payload);
  };

  const handleShowReplies = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    showReplies(!replies);
    setFocusMessageInput(!replies);
  };

  const handleShowComments = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setFocusMessageInput(false);
    showReplies(!replies);
  };

  type UserTypeResponse = { data: { user: IUser } };

  const checkIfUserIsAdmin = (userId: string): boolean => {
    const _user: UserTypeResponse | undefined = (
      userId === GetUserId()
        ? queryClient.getQueryData<UserTypeResponse>(["user-data"])
        : queryClient.getQueryData<UserTypeResponse>(["public-user", userId])
    ) as UserTypeResponse;

    // console.log("GetUserId", GetUserId());

    return _user?.data?.user?.role?.name === "ADMIN";
  };

  const LoadUsers = async () => {
    const usersNotCached: string[] = [];

    for (const comment of comments)
      if (!queryClient.getQueryData(["public-user", comment.userId]))
        if (!usersNotCached.includes(comment.userId))
          usersNotCached.push(comment.userId);

    setUsersToFetch(usersNotCached);
    setUsersLoaded(true);
  };

  useEffect(() => {
    if (comments) LoadUsers().catch((e) => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  useQueries(
    usersToFetch.map((userId: string) => {
      return {
        queryKey: ["public-user", userId],
        queryFn: () => fetchPublicUser(userId),
        initialData: queryClient.getQueryData(["public-user", userId]),
        cacheTime: 2 * 3600000,
        refetchOnMount: false,
        enabled: areUsersLoaded,
      };
    })
  );

  useEffect(() => {
    if (!isQuestionIdInUrl) return;

    if (isQuestionIdInUrl !== question.id) showReplies(false);

    if (question.id === isQuestionIdInUrl && !!comments) {
      showReplies(true);
      setTimeout(() => {
        questionTopicRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }, 500);
    }
  }, [isQuestionIdInUrl, questionTopicRef, comments]);

  // useEffect(() => {
  //   if (replies)
  //     queryClient.invalidateQueries([
  //       "comments-for-question",
  //       weekId,
  //       termId,
  //       {
  //         classId,
  //         serviceTier: classTier,
  //         courseId,
  //       },
  //       question.id,
  //     ]);
  // }, [replies]);

  return (
    <div className={cls.root} ref={questionTopicRef}>
      <div className={cls.comment}>
        <div className={cls.left}>
          <img src={user?.data.user.avatarUrl || "/user-avatar.webp"} alt='' />
        </div>
        <div className={cls.center}>
          <div className={cls.header}>
            <div className={cls.name}>
              {user?.data.user.firstName} {user?.data.user.lastName}
            </div>
            <div className={cls.date}>
              {timeFormatter(question.unixTimestamp)}{" "}
              {question.isEdited && (
                <span style={{ color: "#cc33ff" }}>[Edited]</span>
              )}
            </div>
          </div>
          <div className={cls.body}>{question.description}</div>
          <div className={cls.footer}>
            <div className={cls.reply} onClick={handleShowReplies}>
              Reply
            </div>
            <div className={cls.comments} onClick={handleShowComments}>
              Comments ({comments?.length})
            </div>
          </div>
        </div>

        <div className={cls.right}>
          {(userRole === "ADMIN" || userRole === "OWNER") && (
            <QuestionModMenu
              edit={true}
              remove={true}
              notify={false}
              questionTopic={question}
              classTier={classTier}
            />
          )}
          {userRole === "USER" && (
            <QuestionModMenu
              edit={
                user?.data.user.id === GetUserId() &&
                !user?.data.user.isMuted &&
                !isTimestampMoreThanOneHourAgo(question.unixTimestamp) &&
                comments?.length === 0
              }
              remove={
                comments?.length === 0 &&
                !user?.data.user.isMuted &&
                user?.data.user.id === GetUserId() &&
                !isTimestampMoreThanOneHourAgo(question.unixTimestamp)
              }
              notify={user?.data.user.id !== GetUserId()}
              questionTopic={question}
              classTier={classTier}
            />
          )}
        </div>
      </div>
      {!!comments && replies && (
        <div className={cls.replies}>
          {comments.map((reply: IChatMessage, index: number) => {
            const _user: UserTypeResponse = (
              reply.userId === GetUserId()
                ? queryClient.getQueryData(["user-data"])
                : queryClient.getQueryData(["public-user", reply.userId])
            ) as UserTypeResponse;

            return (
              <div className={cls.replyText} key={index}>
                <div className={cls.left}>
                  {_user?.data.user.role.name === "ADMIN" && (
                    <div> &#8594;</div>
                  )}

                  <img
                    src={_user?.data.user.avatarUrl || "/user-avatar.webp"}
                    alt=''
                  />
                </div>

                <div className={cls.center}>
                  <div className={cls.header}>
                    {_user?.data.user.role.name === "ADMIN" && (
                      <div style={{ fontSize: 12, color: "#8e34fc" }}>
                        {" "}
                        [ADMIN]:
                      </div>
                    )}
                    <div className={cls.name}>
                      {_user?.data.user.firstName} {_user?.data.user.lastName}
                    </div>
                    <div className={cls.date}>
                      {timeFormatter(reply.unixTimestamp)}{" "}
                      {reply.isEdited && (
                        <span style={{ color: "#cc33ff" }}>[Edited]</span>
                      )}
                    </div>
                  </div>
                  <div
                    className={`${cls.body} ${
                      _user?.data.user.role.name === "ADMIN"
                        ? cls.adminMessage
                        : ""
                    }`}>
                    {reply.content}
                  </div>
                </div>
                <div className={cls.right}>
                  {(userRole === "ADMIN" || userRole === "OWNER") && (
                    <CommentModMenu
                      edit={true}
                      remove={true}
                      notify={false}
                      chatMessage={reply}
                      classTier={classTier}
                      questionId={question.id}
                    />
                  )}

                  {userRole === "USER" && (
                    <CommentModMenu
                      edit={
                        reply.userId === GetUserId() &&
                        !_user?.data.user.isMuted &&
                        !isTimestampMoreThanOneHourAgo(reply.unixTimestamp)
                      }
                      remove={
                        reply.userId === GetUserId() &&
                        !_user?.data.user.isMuted &&
                        !isTimestampMoreThanOneHourAgo(reply.unixTimestamp)
                      }
                      notify={
                        reply.userId !== GetUserId() &&
                        !checkIfUserIsAdmin(reply.userId)
                      }
                      chatMessage={reply}
                      classTier={classTier}
                      questionId={question.id}
                    />
                  )}
                </div>
              </div>
            );
          })}
          <div className={cls.messageComposer}>
            <MessageSubmitter
              emojiEnabled={true}
              autoFocus={focusMessageInput}
              submitMessage={handleSubmitReply}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassQuestionTopic;
