import { useQuery, useQueryClient } from "react-query";
import { ApiEndpoints } from "../../api/endpoints";
import { requestApi } from "../../api/requestApi";
import { cacheTimes } from "../cacheTimesRQ";

const fetchEnrolledClasses = () => {
  return requestApi({
    url: ApiEndpoints.GET_ALL_ENROLLED_CLASSES,
    method: "post",
    data: {},
  });
};

export const useEnrolledClasses = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["enrolled-classes"], fetchEnrolledClasses, {
    enabled: enabled,
    cacheTime: cacheTimes.ENROLLED_CLASSES_CACHE,
    staleTime: cacheTimes.ENROLLED_CLASSES_STALE,
    initialData: queryClient.getQueryData("enrolled-classes"),
    refetchOnWindowFocus: true,
    select: (data) => data.data.classes,
    onError: (err) => {
      console.log(err);
    },
  });
};

// const fetchClassById = ({ queryKey }: QueryFunctionContext) => {
//   const classId = queryKey[1] as string;
//   return requestApi({
//     url: ApiEndpoints.GET_CLASSES,
//     method: "post",
//     data: { classIds: [classId] },
//   });
// };

// export const useClassById = (classId: string, enabled?: boolean) => {
//   const queryClient = useQueryClient();
//   return useQuery(["class-by-id", classId], fetchClassById, {
//     enabled: enabled,
//     cacheTime: cacheTimes.ENROLLED_CLASSES_CACHE,
//     staleTime: cacheTimes.ENROLLED_CLASSES_STALE,
//     initialData: queryClient.getQueryData(["class-by-id", classId]),
//     refetchOnWindowFocus: false,
//     select: (data) => data.data.classes,
//     onError: (err) => {
//       console.log(err);
//     },
//   });
// };
