import cls from "./LmsDatePicker.module.scss";
import { FieldError } from "react-hook-form";
import React, { useEffect, useRef, useState } from "react";
import CalendarIcon from "../../../assets/icons/calendarIconsvg.svg";
import calendar, {
  // isDate,
  isSameDay,
  isSameMonth,
  getDateISO,
  getNextMonth,
  getPreviousMonth,
  WEEK_DAYS,
  CALENDAR_MONTHS,
  THIS_MONTH,
  THIS_YEAR,
  THIS_DAY,
  zeroPad,
} from "./helpers";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

type Props = {
  label?: string;
  width?: number;
  height?: number;
  defaultValue?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  // inputRef?: React.Ref<HTMLInputElement>;
  onChange: (date: DateStateType) => void;
  // onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  // isTouched?: boolean;
  isDirty?: boolean;
  error?: FieldError | undefined;
};

type DateStateType = {
  day?: number;
  month?: number;
  year?: number;
};

const LmsDatePicker: React.FC<Props> = ({
  width,
  height,
  defaultValue,
  minDate,
  maxDate,
  label,
  disabled,
  // inputRef,
  onChange,
  // onBlur,
  // isTouched,
  isDirty,
  error,
}) => {
  let pressureTimer: ReturnType<typeof setTimeout>,
    pressureTimeout: ReturnType<typeof setTimeout>;

  const [calendarDays, showCalendarDays] = useState<boolean>(false);

  const [dateState, setDateState] = useState<DateStateType>(
    {} as DateStateType
  );

  const rootRef = useRef(null);

  const handleClickOutsideMenu = (e: MouseEvent) => {
    showCalendarDays(false);
  };
  useOnClickOutside(rootRef, handleClickOutsideMenu);

  //// GO PREVIOUS MONTH
  const gotoPreviousMonth = () => {
    const { day, month, year } = dateState;

    const _day = day || THIS_DAY;
    const _month = month || THIS_MONTH;
    const _year = year || THIS_YEAR;

    const previousMonth = getPreviousMonth(_month, _year);

    const lastDay = new Date(
      previousMonth.year,
      previousMonth.month,
      0
    ).getDate();

    setDateState({
      day: _day > lastDay ? lastDay : _day,
      month: previousMonth.month,
      year: previousMonth.year,
    });
  };
  //// GO NEXT MONTH
  const gotoNextMonth = () => {
    const { day, month, year } = dateState;

    const _day = day || THIS_DAY;
    const _month = month || THIS_MONTH;
    const _year = year || THIS_YEAR;

    const nextMonth = getNextMonth(_month, _year);

    const lastDay = new Date(nextMonth.year, nextMonth.month, 0).getDate();

    setDateState({
      day: _day > lastDay ? lastDay : _day,
      month: nextMonth.month,
      year: nextMonth.year,
    });
  };
  //// GO PREVIOUS YEAR
  const gotoPreviousYear = () => {
    const { year } = dateState;

    const _year = year || THIS_YEAR;

    setDateState({
      ...dateState,
      year: _year - 1,
    });
  };
  //// GO NEXT YEAR
  const gotoNextYear = () => {
    const { year } = dateState;
    const _year = year || THIS_YEAR;
    setDateState({
      ...dateState,
      year: _year + 1,
    });
  };

  //// PRESSURE HANDLER
  const handlePressure = (fn: () => void) => {
    if (typeof fn === "function") {
      // clearPressureTimer();
      fn();
      pressureTimeout = setTimeout(() => {
        pressureTimer = setInterval(fn, 100);
      }, 500);
    }
    return clearPressureTimer();
  };
  const clearPressureTimer = () => {
    pressureTimer && clearInterval(pressureTimer);
    pressureTimeout && clearTimeout(pressureTimeout);
  };

  // CHECK IF DATE IS IN INTERVAL
  const isDateInInterval = (
    date: Date,
    min: Date | undefined,
    max: Date | undefined
  ) => {
    const thisDate = date.getTime();
    const minTime = min?.getTime();
    const maxTime = max?.getTime();

    if ((minTime && thisDate < minTime) || (maxTime && thisDate > maxTime))
      return false;
    return true;
  };

  //// MAIN NAVIGATION
  const handlePreviousYear = (evt: React.MouseEvent<HTMLSpanElement>) => {
    evt.preventDefault();
    const fn = gotoPreviousYear;
    handlePressure(fn);
  };
  const handleNextYear = (evt: React.MouseEvent<HTMLSpanElement>) => {
    evt.preventDefault();
    const fn = gotoNextYear;
    handlePressure(fn);
  };

  const handlePreviousMonth = (evt: React.MouseEvent<HTMLSpanElement>) => {
    evt.preventDefault();
    const fn = gotoPreviousMonth;
    handlePressure(fn);
  };
  const handleNextMonth = (evt: React.MouseEvent<HTMLSpanElement>) => {
    evt.preventDefault();
    const fn = gotoNextMonth;
    handlePressure(fn);
  };

  //// HANDLE SELECT DAY
  const handleSelectDay = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    let { day, month, year } = dateState;

    const _day = day || THIS_DAY;
    const _month = month || THIS_MONTH;
    const _year = year || THIS_YEAR;

    const _date = new Date(e.currentTarget.title);
    const selectedDate = new Date(_year, _month - 1, _day);

    //prevent selecting days wich are not in the current month
    if (!isSameMonth(_date, selectedDate)) return;

    // prevent selecting days wich are not in the interval
    if (!isDateInInterval(_date, minDate, maxDate)) return;

    setDateState({ day: _date.getDate(), month: _month, year: _year });
    showCalendarDays(false);
  };
  //// GET CALENDAR DATES
  const getCalendarDates = () => {
    const { month, year } = dateState;

    const _month = month || THIS_MONTH;
    const _year = year || THIS_YEAR;

    return calendar(_month, _year);
  };
  //// RENDER MONTH AND YEAR
  const renderMonthAndYear = () => {
    const { month, year } = dateState;

    const _month = month || THIS_MONTH;
    const _year = year || THIS_YEAR;

    const monthname =
      Object.keys(CALENDAR_MONTHS)[Math.max(0, Math.min(_month - 1, 11))];

    return (
      <div className={cls.header}>
        <div className={cls.yearSelector}>
          <span
            className={cls.leftArrow}
            onMouseDown={handlePreviousYear}
            onMouseUp={clearPressureTimer}
            title='Previous Month'
          />
          <div style={{ color: "white" }}>{_year}</div>
          <span
            className={cls.rightArrow}
            onMouseDown={handleNextYear}
            onMouseUp={clearPressureTimer}
            title='Next Month'
          />
        </div>
        <div className={cls.monthSelector}>
          <span
            className={cls.leftArrow}
            onMouseDown={handlePreviousMonth}
            onMouseUp={clearPressureTimer}
            title='Previous Month'
          />
          <div style={{ color: "white" }}>{monthname}</div>
          <span
            className={cls.rightArrow}
            onMouseDown={handleNextMonth}
            onMouseUp={clearPressureTimer}
            title='Next Month'
          />
        </div>
      </div>
    );
  };
  //// RENDER CALENDAR DAYS
  const renderCalendarDate = (date: (string | number)[], index: number) => {
    let { day, month, year } = dateState;

    const _day = day || THIS_DAY;
    const _month = month || THIS_MONTH;
    const _year = year || THIS_YEAR;

    const _date = new Date(date.join("-"));
    // const selectedDate = new Date(`${_year}-${_month}-${_day}`);
    const selectedDate = new Date(_year, _month - 1, _day);

    // Check if calendar date is same day as today
    const isToday = isSameDay(_date, new Date());

    // Check if calendar date is same day as currently selected date
    const isCurrent = _day && isSameDay(_date, selectedDate);

    // Check if calendar date is in the same month as the state month and year
    const isMonth = _month && _year && isSameMonth(_date, selectedDate);

    // The click handler
    const onClick = handleSelectDay;

    // Conditionally render a styled date component
    const dayClassName = isCurrent ? cls.selected : isToday ? cls.today : "";

    const props = {
      index,
      onClick,
      title: _date.toDateString(),
      className: `${dayClassName} ${
        !isMonth || !isDateInInterval(_date, minDate, maxDate)
          ? cls.disabled
          : ""
      }`,
    };

    return (
      <div key={getDateISO(_date)} {...props}>
        {_date.getDate()}
      </div>
    );
  };

  useEffect(() => {
    if (defaultValue)
      setDateState({
        year: parseInt(defaultValue.split("-")[0]),
        month: parseInt(defaultValue.split("-")[1]),
        day: parseInt(defaultValue.split("-")[2]),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { day, month, year } = dateState;

    if (day && month && year) {
      onChange(dateState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateState]);

  return (
    <div
      className={cls.root}
      style={{ width: width, height: height }}
      ref={rootRef}>
      <label className={cls.label}>{label || ""}</label>

      <div
        className={`${cls.input} ${calendarDays ? cls.expanded : ""}`}
        onClick={() => {
          if (!disabled) showCalendarDays(!calendarDays);
        }}>
        <label
          className={cls.value}
          style={{ color: `${!disabled ? "white" : ""}` }}>
          {dateState.day && dateState.month && dateState.year
            ? `${zeroPad(dateState.day, 2)}/${zeroPad(dateState.month, 2)}/${
                dateState.year
              }`
            : "DD/MM/YYYY"}
        </label>
        <img className='icon' src={CalendarIcon} alt='' />
      </div>
      <p className={cls.error}>{`${error ? error.message : ""}`}</p>
      {calendarDays && (
        <div className={cls.calendar}>
          {renderMonthAndYear()}
          <div className={cls.body}>
            <div className={cls.days}>
              {WEEK_DAYS.map((day: string, index: number) => (
                <div key={index}>{day.toUpperCase()}</div>
              ))}
            </div>
            <div className={cls.dates}>
              {getCalendarDates().map(renderCalendarDate)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LmsDatePicker;
