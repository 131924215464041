export function FilterDuplicateElements<T>(
  array: NonNullable<T>[],
  keyOfUniqueIdentifier: keyof T
): NonNullable<T>[] {
  const hashMap: Record<string | number, NonNullable<T>> = {};

  for (const element of array) {
    const uniqueIdentifier: string = element[
      keyOfUniqueIdentifier
    ] as unknown as string;

    if (typeof hashMap[uniqueIdentifier] === "undefined")
      hashMap[uniqueIdentifier] = element;
  }

  return Object.values(hashMap);
}

export function timeFormatter(tm: number): string {
  const date = new Date(tm);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day}/${month}/${year} at ${hours}:${minutes}`;
  // return `${day}/${month}/${year}`;
}

export function isTimestampMoreThanOneHourAgo(unixTimestamp: number): boolean {
  // Get the current time in Unix timestamp format
  const now = Date.now();

  // Calculate the difference between the current time and the provided timestamp in milliseconds
  const diffInMs = now - unixTimestamp;

  // Convert the difference to hours
  const diffInHours = diffInMs / 1000 / 60 / 60;

  // Return true if the difference is greater than 1 hour, false otherwise
  return diffInHours > 1;
}
