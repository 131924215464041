import cls from "./Terms.module.scss";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import AppHeader from "../../components/appHeader/AppHeader";

import { CollapsedSidebarContext } from "../../context/collapsedSidebarContext";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { useContext } from "react";

const Terms: React.FC = () => {
  const { collapsed } = useContext(CollapsedSidebarContext);

  return (
    <div className={cls.root}>
      <SidebarMenu />

      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />
        <div className={cls.container}>
          <div className={cls.title}> AGREEMENT TO OUR LEGAL TERMS </div>

          <section>
            {/* <h1>COMPANY</h1> */}

            <div className={cls.faq}>
              {/* <h2>What is Visign?</h2> */}
              <p>
                VISIGN ACADEMY SRL (“VISIGN”, "Company," "we," "us," "our"), a
                Romanian legal entity with registered office in the Municipality
                of Buzău, Buzău County, registered at the Trade Register under
                no. J10/1032/2020, with fiscal code 43361971, operates this
                website, as well as any other related products and services that
                refer or link to these legal terms (the "Legal Terms")
                (collectively, the "Services").
                <br /> <br />
                In order to resolve a complaint regarding the Services or to
                receive further information regarding use of the Services,
                please contact us at{" "}
                <a href='mailto:contact@visign.com'>contact@visign.com</a>.
                <br /> <br />
                These Legal Terms constitute a legally binding agreement made
                between you, whether personally or on behalf of an entity
                ("you"), and VISIGN, concerning your access to and use of the
                Services. You agree that by accessing the Services, you have
                read, understood, and agreed to be bound by all of these Legal
                Terms.{" "}
                <b>
                  <u>
                    If you do not agree with all of these legal terms, then you
                    are expressly prohibited from using the Services and you
                    must discontinue use immediately.
                  </u>
                </b>
                <br /> <br />
                Supplemental terms and conditions or documents that may be
                posted on the Services from time to time are hereby expressly
                incorporated herein by reference. We reserve the right, in our
                sole discretion, to make changes or modifications to these Legal
                Terms at any time and for any reason. We will alert you about
                any changes by updating the "Last updated" date of these Legal
                Terms, and you waive any right to receive specific notice of
                each such change. It is your responsibility to periodically
                review these Legal Terms to stay informed of updates. You will
                be subject to, and will be deemed to have been made aware of and
                to have accepted, the changes in any revised Legal Terms by your
                continued use of the Services after the date such revised Legal
                Terms are posted.
              </p>
            </div>
          </section>
          <div className={cls.title}>TERMS OF SERVICE IN FULL </div>
          <section>
            <div className={cls.faq}>
              <h3>1. OUR SERVICES</h3>
              <h3>2. INTELLECTUAL PROPERTY RIGHTS</h3>
              <h3>3. USER REPRESENTATIONS</h3>
              <h3>4. PROHIBITED ACTIVITIES</h3>
              <h3>5. USER GENERATED CONTRIBUTIONS</h3>
              <h3>7. SERVICES MANAGEMENT</h3>
              <h3>8. TERM AND TERMINATION</h3>
              <h3>9. MODIFICATIONS AND INTERRUPTIONS</h3>
              <h3>10. GOVERNING LAW</h3>
              <h3>11. DISPUTE RESOLUTION</h3>
              <h3>12. CORRECTIONS</h3>
              <h3>13. DISCLAIMER</h3>
              <h3>14. LIMITATIONS OF LIABILITY</h3>
              <h3>15. INDEMNIFICATION</h3>
              <h3>16. USER DATA & PERSONAL DATA PRIVACY</h3>
              <h3>
                17. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </h3>
              <h3>18. MISCELLANEOUS</h3>
            </div>
          </section>
          <section>
            {/* <h1>COMMUNITY</h1> */}

            <div className={cls.faq}>
              <h2>1. OUR SERVICES</h2>
              <p>
                The information provided when using the Services is not intended
                for distribution to or use by any person or entity in any
                jurisdiction or country where such distribution or use would be
                contrary to law or regulation or which would subject us to any
                registration requirement within such jurisdiction or country.
                Accordingly, those persons who choose to access the Services
                from other locations do so on their own initiative and are
                solely responsible for compliance with local laws, if and to the
                extent local laws are applicable.
              </p>
            </div>
            <div className={cls.faq}>
              <h2>Newsletter subscription</h2>
              <p>
                Through our website, a user can subscribe to VISIGN newsletter
                in order to receive marketing e-mails from the Company.
                Newsletters will include, among others:
              </p>
              <ul>
                <li>
                  One “welcome pack” containing a PDF document with tips on
                  drawing and learning art, as well as a brush pack for Adobe
                  Photoshop. This will be sent only once, at the time of
                  subscription to the newsletter, to the e-mail address provided
                  for this purpose and will remain accessible to the user on the
                  provided e-mail address even after the withdrawal of consent
                  to receive newsletters from the Company. It is the User's
                  responsibility to check the compatibility of these electronic
                  formats with his own devices and to ensure that they can be
                  opened and accessed. The user is also responsible for ensuring
                  that he/she has downloaded Adobe Acrobat Reader and Adobe
                  Photoshop software more recent than 2016 with valid,
                  up-to-date licenses. The Company is not held responsible for
                  this compatibility. The Company assumes no responsibility for
                  the non-functionality or impossibility to open the PDF
                  document or to use the brush pack due to incompatibility of
                  the User's devices and non-compliance with the required
                  technical and legal specifications;
                </li>
                <li>Information regarding upcoming courses;</li>
                <li>Early access to upcoming courses;</li>
                <li>
                  Other informative materials with tips on drawing, painting and
                  other art subjects.
                </li>
              </ul>
            </div>
            <div className={cls.faq}>
              <h2>VISIGN community</h2>
              <p>
                The user has the opportunity to join VISIGN community on the
                Discord platform, as well as follow our accounts on social
                media, such as but not limited to Instagram or Facebook (links
                provided on the website). The user understands that at the time
                of his navigation on Discord or other social media websites
                (third-party websites or apps), these Terms and Conditions will
                not be applicable regarding his activities carried out on the
                latter platforms. <br />
                <br />
                By using the website, the user is solely responsible for all
                activities that result from his use. From the moment of the
                user's redirection to associated third-party websites or apps,
                the Company does not assume responsibility in any situation
                regarding the policies and Terms of Service applicable on the
                respective websites or apps.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>2. INTELLECTUAL PROPERTY RIGHTS</h2>
              <h2>Our intellectual property</h2>
              <p>
                We are the owner of all intellectual property rights in our
                Services, including all source code, databases, functionality,
                software, website designs, audio, video, text, photographs,
                graphics, sound, materials provided (e.g. PDF documents, brush
                pack) etc. in the Services (collectively, the "Content"), as
                well as the trademarks, service marks, and logos contained
                therein (the "Marks"). <br />
                <br />
                Our Content and Marks are protected by copyright under Law no.
                8/1996 and trademark laws (and various other intellectual
                property rights and unfair competition laws) and treaties in the
                United States and around the world. <br />
                <br />
                The Content and Marks are provided in or through the Services
                "AS IS", granting a non-exclusive license for your personal,
                non-commercial use or internal business purpose only.
              </p>
            </div>
            <div className={cls.faq}>
              <h2>Your use of our Services</h2>
              <p>
                Subject to your compliance with these Legal Terms, including the
                "PROHIBITED ACTIVITIES” section below, we grant you a
                non-exclusive, non-transferable, revocable license to:
              </p>

              <ul>
                <li>access the Services and Content;</li>
                <li>
                  download or print a copy of any portion of the Content to
                  which you have properly gained access solely for your
                  personal, non-commercial use purpose.
                </li>
              </ul>
              <p>
                Except as set out in this section or elsewhere in our Legal
                Terms, no part of the Services and no Content or Marks may be
                copied, reproduced, aggregated, republished, uploaded, posted,
                publicly displayed, encoded, translated, transmitted,
                distributed, sold, licensed, or otherwise exploited for any
                commercial purpose whatsoever, without our express prior written
                permission. <br />
                <br />
                If you wish to make any use of the Services, Content, or Marks
                other than as set out in this section or elsewhere in our Legal
                Terms, please contact us at{" "}
                <a href='mailto:contact@visign.com'>contact@visign.com</a>.{" "}
                <br />
                <br />
                If we ever grant you the permission to post, reproduce, or
                publicly display any part of our Services or Content, you must
                identify us as the owners or licensors of the Services, Content,
                or Marks and ensure that any copyright or proprietary notice
                appears or is visible on posting, reproducing, or displaying our
                Content.
                <br />
                <br />
                We reserve all rights not expressly granted to you in and to the
                Services, Content and Marks.
                <br />
                <br />
                Any breach of these Intellectual Property Rights will constitute
                a material breach of our Legal Terms and your right to use our
                Services will terminate immediately. Any copying, distribution,
                reproduction, republication etc. is a breach of the Romanian
                legislation and the user is liable for any such breach. We
                reserve the right to claim compensation from the user for any
                damages caused.
              </p>
            </div>
            <div className={cls.faq}>
              <h2>Your submissions</h2>
              <p>
                Please review this section and the "PROHIBITED ACTIVITIES"
                section carefully prior to using our Services to understand the
                (a) rights you give us and (b) obligations you have when you
                post or upload any content through the Services.
                <br />
                <br />
                <b>Submissions:</b> By directly sending us any question,
                comment, suggestion, idea, feedback, or other information about
                the Services ("Submissions"), you retain any intellectual
                property rights or other proprietary rights associated with your
                Submissions, and you agree that we will be entitled to
                unrestricted use and dissemination of your Submissions, for any
                lawful purpose, commercial or otherwise, without acknowledgment
                or compensation to you. <br />
                <br />
                You are responsible for what you submit: By sending us
                Submissions through any part of the Services, you:
              </p>
              <ul>
                <li>
                  confirm that you have read and agree with our "PROHIBITED
                  ACTIVITIES" and will not post, send, publish, upload, or
                  transmit through the Services any Submission that is illegal,
                  harassing, hateful, harmful, defamatory, obscene, bullying,
                  abusive, discriminatory, threatening to any person or group,
                  sexually explicit, false, inaccurate, deceitful, or
                  misleading;
                </li>
                <li>
                  warrant that any such Submission are original to you or that
                  you have the necessary rights and licenses to submit such
                  Submissions and that you have full authority to grant us the
                  above-mentioned rights in relation to your Submissions;
                </li>
                <li>
                  warrant and represent that your Submissions do not constitute
                  confidential information;
                </li>
                <li>
                  by sending us an electronic submission, such as subscribing to
                  the newsletter, you agree to have your personal information
                  (e-mail address) processed according to our Privacy Policy.
                </li>
              </ul>
              <p>
                You are solely responsible for your Submissions and you
                expressly agree to reimburse us for any and all losses that we
                may suffer because of your breach of (a) this section, (b) any
                third party’s intellectual property rights, or (c) applicable
                law.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>3. USER REPRESENTATIONS</h2>

              <p>By using the Services, you represent and warrant that:</p>
              <ul>
                <li>
                  you have the legal capacity and you agree to comply with these
                  Legal Terms;
                </li>
                <li>
                  you are not a minor in the jurisdiction in which you reside;
                </li>
                <li>
                  you will not access the Services through automated or
                  non-human means, whether through a bot, script or otherwise;{" "}
                </li>
                <li>
                  you will not use the Services for any illegal or unauthorized
                  purpose;{" "}
                </li>
                <li>
                  your use of the Services will not violate any applicable law
                  or regulation.
                </li>
              </ul>
              <p>
                If you provide any information that is untrue, inaccurate, not
                current, or incomplete, we have the right to suspend or
                terminate your account and refuse any and all current or future
                use of the Services (or any portion thereof).
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>4. PROHIBITED ACTIVITIES</h2>

              <p>
                You may not access or use the Services for any purpose other
                than that for which we make the Services available. The Services
                may not be used in connection with any commercial endeavors,
                except those that are specifically endorsed or approved by us.
                <br />
                <br />
                As a user of the Services, you agree not to:
              </p>
              <ul>
                <li>
                  Systematically retrieve data or other content from the
                  Services to create or compile, directly or indirectly, a
                  collection, compilation, database, or directory without
                  written permission from us.
                </li>
                <li>
                  Circumvent, disable, or otherwise interfere with
                  security-related features of the Services, including features
                  that prevent or restrict the use or copying of any Content or
                  enforce limitations on the use of the Services and/or the
                  Content contained therein.
                </li>
                <li>
                  Disparage, tarnish, or otherwise harm, in our opinion, us
                  and/or the Services.
                </li>
                <li>
                  Use any information obtained from the Services in order to
                  harass, abuse, or harm another person.
                </li>
                <li>
                  Make improper use of our support services or submit false
                  reports of abuse or misconduct.
                </li>
                <li>
                  Use the Services in a manner inconsistent with any applicable
                  laws or regulations.
                </li>
                <li>
                  Engage in unauthorized framing of or linking to the Services.
                </li>
                <li>
                  Upload or transmit (or attempt to upload or to transmit)
                  viruses, Trojan horses, or other material, including excessive
                  use of capital letters and spamming (continuous posting of
                  repetitive text), that interferes with any party’s
                  uninterrupted use and enjoyment of the Services or modifies,
                  impairs, disrupts, alters, or interferes with the use,
                  features, functions, operation, or maintenance of the
                  Services.
                </li>
                <li>
                  Delete the copyright or other proprietary rights notice from
                  any Content.
                </li>
                <li>
                  Attempt to impersonate another user or person or use the
                  username of another user.
                </li>
                <li>
                  Interfere with, disrupt, or create an undue burden on the
                  Services or the networks or services connected to the
                  Services.
                </li>
                <li>
                  Harass, annoy, intimidate, or threaten any of our employees or
                  agents engaged in providing any portion of the Services to
                  you.
                </li>
                <li>
                  Attempt to bypass any measures of the Services designed to
                  prevent or restrict access to the Services, or any portion of
                  the Services.
                </li>
                <li>
                  Copy or adapt the Services' software, including but not
                  limited to Flash, PHP, HTML, JavaScript, or other code.
                </li>
                <li>
                  Except as permitted by applicable law, decipher, decompile,
                  disassemble, or reverse engineer any of the software
                  comprising or in any way making up a part of the Services.
                </li>
                <li>
                  Except as may be the result of standard search engine or
                  Internet browser usage, use, launch, develop, or distribute
                  any automated system, including without limitation, any
                  spider, robot, cheat utility, scraper, or offline reader that
                  accesses the Services, or use or launch any unauthorized
                  script or other software.
                </li>
                <li>
                  Use a buying agent or purchasing agent to make purchases on
                  the Services.
                </li>
                <li>
                  Make any unauthorized use of the Services, including
                  collecting usernames and/or email addresses of users by
                  electronic or other means for the purpose of sending
                  unsolicited email, or creating user accounts by automated
                  means or under false pretenses.
                </li>
                <li>
                  Use the Services as part of any effort to compete with us or
                  otherwise use the Services and/or the Content for any
                  revenue-generating endeavor or commercial enterprise.
                </li>
              </ul>
              <p>
                Actions that violate legal provisions or the present terms of
                use will be sanctioned by VISIGN and may result in notification
                or definitive exclusion from the system depending on the
                seriousness of the act. <br />
                <br />
                The sanctioning decision will be sent in writing by e-mail, to
                the address with which the user registered or appears in the
                system. The user can object within 10 days of sending the
                notification by email.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>5. USER GENERATED CONTRIBUTIONS</h2>

              <p>
                The Services do not currently offer users the possibility to
                submit or post content. We may provide you with the opportunity
                to create, submit, post, display, transmit, perform, publish,
                distribute, or broadcast content and materials to us or on the
                Services, including but not limited to text, writings, video,
                audio, photographs, graphics, comments, suggestions, or personal
                information or other material (collectively, "Contributions").
                Contributions may be viewable by other users of the Services.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>6. CONTRIBUTION LICENSE</h2>

              <p>
                You and Services agree that we may access, store, process, and
                use any information and personal data that you provide and your
                choices (including settings).
                <br />
                <br />
                By submitting suggestions or other feedback regarding the
                Services, you agree that we can use and share such feedback for
                any purpose without compensation to you.
                <br />
                <br />
                We do not assert any ownership over your Contributions. You
                retain full ownership of all of your Contributions and any
                intellectual property rights or other proprietary rights
                associated with your Contributions. We are not liable for any
                statements or representations in your Contributions provided by
                you in any area on the Services. You are solely responsible for
                your Contributions to the Services and you expressly agree to
                exonerate us from any and all responsibility and to refrain from
                any legal action against us regarding your Contributions.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>7. SERVICES MANAGEMENT</h2>

              <p>We reserve the right, but not the obligation, to:</p>
              <ul>
                <li>
                  monitor the Services for violations of these Legal Terms;
                </li>
                <li>
                  take appropriate legal action against anyone who, in our sole
                  discretion, violates the law or these Legal Terms, including
                  without limitation, reporting such users to law enforcement
                  authorities;
                </li>
                <li>
                  in our sole discretion and without limitation, refuse,
                  restrict access to, limit the availability of, or disable (to
                  the extent technologically feasible) any of your Contributions
                  or any portion thereof;
                </li>
                <li>
                  otherwise manage the Services in a manner designed to protect
                  our rights and property and to facilitate the proper
                  functioning of the Services.
                </li>
              </ul>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>8. TERM AND TERMINATION</h2>

              <p>
                These Legal Terms shall remain in full force and effect while
                you use the Services.
                <b>
                  <u>
                    Without limiting any other provision of these legal terms,
                    we reserve the right to, in our sole discretion and without
                    notice or liability, deny access to and use of the services
                    (including blocking certain IP addresses), to any person for
                    any reason or for no reason, including without limitation
                    for breach of any representation, warranty, or covenant
                    contained in these legal terms or of any applicable law or
                    regulation. We may terminate your use or participation in
                    the services or delete any content or information that you
                    posted at any time, without warning, in our sole discretion.
                  </u>
                </b>
                <br />
                <br />
                If we terminate or suspend your account for any reason, you are
                prohibited from registering and creating a new account under
                your name, a fake or borrowed name, or the name of any third
                party, even if you may be acting on behalf of the third party.
                In addition to terminating or suspending your account, we
                reserve the right to take appropriate legal action, including
                without limitation pursuing civil, criminal, and injunctive
                redress.
              </p>
            </div>

            <div className={cls.faq}>
              <h2>Conflict of interest</h2>

              <p>
                To avoid a conflict of interests, upon registering with VISIGN a
                user declares on his own responsibility that, on the date of
                registration, he does not carry out any similar activity, namely
                the organization and conduct of online courses, does not create
                or manage a site, store, blog that sells content similar or
                close to the digital material presented on the site, nor does it
                organize online or offline courses with content similar or close
                to the digital material presented on the site.
                <br />
                <br />
                If VISIGN finds the existence of a conflict of interests, we
                have the right to declare the termination/resolution of the
                study contract, with the result that any further use of the
                digital material or its results will cease from the date of
                receipt of the unilateral termination/resolution declaration by
                e-mail.
                <br />
                <br />
                If the user finds himself in the situation of conflict of
                interest from the point above, in order not to bear the
                consequences of the conflict of interest, he is advised to
                notify us at{" "}
                <a href='mailto:contact@visign.com'>contact@visign.com</a>.
                Following an assessment of the situation, he may become or
                continue to activate as a user following the signing of
                non-competition, intellectual property rights protection and
                confidentiality agreements. Following the signing of these
                contracts, the user may start or continue participating in
                VISIGN courses and activities.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>9. MODIFICATIONS AND INTERRUPTIONS</h2>

              <p>
                We reserve the right to change, modify, or remove the contents
                of the Services at any time or for any reason at our sole
                discretion without notice. However, we have no obligation to
                update any information on our Services.
                <br />
                <br />
                We will not be liable to you or any third party for any
                modification, price change, suspension, or discontinuance of the
                Services.
                <br /> <br />
                We cannot guarantee the Services will be available at all times.
                We may experience hardware, software, or other problems or need
                to perform maintenance related to the Services, resulting in
                interruptions, delays, or errors. We reserve the right to
                change, revise, update, suspend, discontinue, or otherwise
                modify the Services at any time or for any reason without notice
                to you. You agree that we have no liability whatsoever for any
                loss, damage, or inconvenience caused by your inability to
                access or use the Services during any downtime or discontinuance
                of the Services. Nothing in these Legal Terms will be construed
                to obligate us to maintain and support the Services or to supply
                any corrections, updates, or releases in connection therewith.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>10. GOVERNING LAW</h2>

              <p>
                These Legal Terms shall be governed by and defined following the
                laws of Romania. VISIGN and yourself irrevocably consent that
                the courts of Romania, specifically the Courts of Bucharest,
                shall have exclusive jurisdiction to resolve any dispute which
                may arise in connection with these Legal Terms.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>11. DISPUTE RESOLUTION</h2>
              <h2>Informal Negotiations</h2>

              <p>
                To expedite resolution and control the cost of any dispute,
                controversy, or claim related to these Legal Terms (each a
                "Dispute" and collectively, the "Disputes") brought by either
                you or us (individually, a "Party" and collectively, the
                "Parties"), the Parties agree to first attempt to negotiate any
                Dispute (except those Disputes expressly provided below)
                informally for at least 30 days before initiating arbitration.
                Such informal negotiations commence upon written notice from one
                Party to the other Party.
              </p>
              <h2>Binding Arbitration</h2>
              <p>
                If the solution offered in informal negotiations is not
                satisfactory, any Dispute arising out of or in connection with
                these Legal Terms, including any question regarding its
                existence, validity, or termination, shall be referred to and
                finally resolved by the Courts of Bucharest, Romania, regardless
                of the nationality of the parties or the applicable law as
                provided for in international law.
              </p>
              <h2>Restrictions</h2>
              <p>
                The Parties agree that any arbitration shall be limited to the
                Dispute between the Parties individually. To the full extent
                permitted by law, (a) no arbitration shall be joined with any
                other proceeding; (b) there is no right or authority for any
                Dispute to be arbitrated on a class-action basis or to utilize
                class action procedures; and (c) there is no right or authority
                for any Dispute to be brought in a purported representative
                capacity on behalf of the general public or any other persons.
              </p>
              <h2>Exceptions to Informal Negotiations and Arbitration</h2>
              <p>
                The Parties agree that the following Disputes are not subject to
                the above provisions concerning informal negotiations binding
                arbitration: (a) any Disputes seeking to enforce or protect, or
                concerning the validity of, any of the intellectual property
                rights of a Party; (b) any Dispute related to, or arising from,
                allegations of theft, piracy, invasion of privacy, or
                unauthorized use; and (c) any claim for injunctive relief. If
                this provision is found to be illegal or unenforceable, then
                neither Party will elect to arbitrate any Dispute falling within
                that portion of this provision found to be illegal or
                unenforceable and such Dispute shall be decided by a court of
                competent jurisdiction within the courts listed for jurisdiction
                above, and the Parties agree to submit to the personal
                jurisdiction of that court.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>12. CORRECTIONS</h2>

              <p>
                There may be information on the Services that contains
                typographical errors, inaccuracies, or omissions, including
                descriptions, pricing, availability, and various other
                information. We reserve the right to correct any errors,
                inaccuracies, or omissions and to change or update the
                information on the Services at any time, without prior notice.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>12. DISCLAIMER</h2>

              <p>
                The services are provided on an as-is and as-available basis.
                You agree that your use of the services will be at your sole
                risk. To the fullest extent permitted by law, we disclaim all
                warranties, express or implied, in connection with the services
                and your use thereof. We make no warranties or representations
                about the accuracy or completeness of the services' content or
                the content of any websites or mobile applications linked to the
                services and we will assume no liability or responsibility for
                any (1) errors, mistakes, or inaccuracies of content and
                materials, (2) personal injury or property damage, of any nature
                whatsoever, resulting from your access to and use of the
                services, (3) any unauthorized access to or use of our secure
                servers and/or any and all personal information and/or financial
                information stored therein, (4) any interruption or cessation of
                transmission to or from the services, (5) any bugs, viruses,
                trojan horses, or the like which may be transmitted to or
                through the services by any third party, and/or (6) any errors
                or omissions in any content and materials or for any loss or
                damage of any kind incurred as a result of the use of any
                content posted, transmitted, or otherwise made available via the
                services.
                <br />
                <br />
                We do not warrant, endorse, guarantee, or assume responsibility
                for any product or service advertised or offered by a third
                party through the services, any hyperlinked website, or any
                website or mobile application featured in any banner or other
                advertising, and we will not be a party to or in any way be
                responsible for monitoring any transaction between you and any
                third-party providers of products or services. As with the
                purchase of a product or service through any medium or in any
                environment, you should use your best judgment and exercise
                caution where appropriate.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>14. LIMITATIONS OF LIABILITY</h2>

              <p>
                In no event will we or our directors, employees, or agents be
                liable to you or any third party for any direct, indirect,
                consequential, exemplary, incidental, special, or punitive
                damages, including lost profit, lost revenue, loss of data, or
                other damages arising from your use of the services, even if we
                have been advised of the possibility of such damages.
                <br />
                <br />
                Notwithstanding anything to the contrary contained herein, our
                liability to you for any cause whatsoever and regardless of the
                form of the action, will at all times be limited to the amount
                paid, if any, by you to us.
                <br />
                <br />
                Certain US state laws and international laws do not allow
                limitations on implied warranties or the exclusion or limitation
                of certain damages. If these laws apply to you, some or all of
                the above disclaimers or limitations may not apply to you, and
                you may have additional rights
                <br />
                <br />
                Neither party shall be liable for failure to perform its
                contractual obligations if such failure is caused by a force
                majeure event. Force majeure is the unforeseeable event, beyond
                the control of the parties and which cannot be avoided.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>15. INDEMNIFICATION</h2>

              <p>
                You agree to defend, indemnify, and hold us harmless, including
                our subsidiaries, affiliates, and all of our respective
                officers, agents, partners, and employees, from and against any
                loss, damage, liability, claim, or demand, including reasonable
                attorneys’ fees and expenses, made by any third party due to or
                arising out of: (1) use of the Services; (2) breach of these
                Legal Terms; (3) any breach of your representations and
                warranties set forth in these Legal Terms; (4) your violation of
                the rights of a third party, including but not limited to
                intellectual property rights; or (5) any overt harmful act
                toward any other user of the Services with whom you connected
                via the Services.
                <br />
                <br />
                Notwithstanding the foregoing, we reserve the right, at your
                expense, to assume the exclusive defense and control of any
                matter for which you are required to indemnify us, and you agree
                to cooperate, at your expense, with our defense of such claims.
                We will use reasonable efforts to notify you of any such claim,
                action, or proceeding which is subject to this indemnification
                upon becoming aware of it.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>16. USER DATA & PERSONAL DATA PRIVACY</h2>

              <p>
                We will maintain certain data that you transmit to the Services
                for the purpose of managing the performance of the Services, as
                well as data relating to your use of the Services. Although we
                perform regular routine backups of data, you are solely
                responsible for all data that you transmit or that relates to
                any activity you have undertaken using the Services. You agree
                that we shall have no liability to you for any loss or
                corruption of any such data, and you hereby waive any right of
                action against us arising from any such loss or corruption of
                such data.
                <br />
                <br />
                <b>Personal data privacy:</b>
                Your submissions or contributions are likely to include contact
                data, which may include personal information (e.g. name, e-mail
                address etc.). VISIGN, as Data Controller, makes every effort to
                ensure that it protects and respects the confidentiality of
                personal data belonging to its users by implementing appropriate
                technical and organizational measures, in accordance with the
                requirements of Regulation No 679/2016 on the protection of
                natural persons with regard to the processing of personal data
                and on the free movement of such data, and repealing Directive
                95/46/EC (General Data Protection Regulation) and Law No.
                190/2018 on the implementation of the General Data Protection
                Regulation and good practices in data processing. The
                information provided by the user will be stored by Controller in
                electronic format and will not be provided to any third party,
                other than those directly involved in providing the specific
                Services. <br /> <br /> For more details on the processing of
                personal data that the Controller carries out in the context of
                using the Services, please consult the Privacy Policy available
                on the Site.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>
                17. ELECTRONIC COMMUNICATIONS, TRANSACTIONS AND SIGNATURES
              </h2>

              <p>
                Visiting the Services, sending us emails, and completing online
                forms constitute electronic communications. You consent to
                receive electronic communications, and you agree that all
                agreements, notices, disclosures, and other communications we
                provide to you electronically, via email and on the Services,
                satisfy any legal requirement that such communication be in
                writing. You hereby agree to the use of electronic signatures,
                contracts, orders, and other records, and to electronic delivery
                of notices, policies, and records of transactions initiated or
                completed by us or via the Services. You hereby waive any rights
                or requirements under any statutes, regulations, rules,
                ordinances, or other laws in any jurisdiction which require an
                original signature or delivery or retention of non-electronic
                records, or to payments or the granting of credits by any means
                other than electronic means.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>18. MISCELLANEOUS</h2>

              <p>
                These Legal Terms and any policies or operating rules posted by
                us on the Services or in respect to the Services constitute the
                entire agreement and understanding between you and us. Our
                failure to exercise or enforce any right or provision of these
                Legal Terms shall not operate as a waiver of such right or
                provision. These Legal Terms operate to the fullest extent
                permissible by law. We may assign any or all of our rights and
                obligations to others at any time. We shall not be responsible
                or liable for any loss, damage, delay, or failure to act caused
                by any cause beyond our reasonable control. If any provision or
                part of a provision of these Legal Terms is determined to be
                unlawful, void, or unenforceable, that provision or part of the
                provision is deemed severable from these Legal Terms and does
                not affect the validity and enforceability of any remaining
                provisions. There is no joint venture, partnership, employment
                or agency relationship created between you and us as a result of
                these Legal Terms or use of the Services. You agree that these
                Legal Terms will not be construed against us by virtue of having
                drafted them. You hereby waive any and all defenses you may have
                based on the electronic form of these Legal Terms and the lack
                of signing by the parties hereto to execute these Legal Terms.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Terms;
