import {
  collection,
  CollectionReference,
  DocumentSnapshot,
  onSnapshot,
  QuerySnapshot,
} from "@firebase/firestore";

import { firestore } from "../firebase";
import { ApiEndpoints } from "../endpoints";
import { GetUserId } from "../firebase";
import { Dispatcher } from "../../utils/dispatcher";

import { requestApi } from "../requestApi";
import INotification from "../../interfaces/INotification";

class NotificationService {
  private readonly eventDispatcher: Dispatcher<INotification[]> =
    new Dispatcher();
  private isInitialized: boolean = false;
  private notifications: INotification[] = [];
  private databaseListenerUnsubscriber: (() => void) | null = null;

  public async Initialize(): Promise<void> {
    if (this.isInitialized) return;

    this.notifications = [];

    const userNotificationCollection: CollectionReference = collection(
      firestore,
      `Users/${GetUserId()}/PersonalNotifications`
    );

    this.databaseListenerUnsubscriber = onSnapshot(
      userNotificationCollection,
      async (snapshot: QuerySnapshot) => {
        const ids: string[] = snapshot.docs.map(
          (document: DocumentSnapshot) => (document.data() as { id: string }).id
        );

        const notifications: INotification[] = await this.FetchNotifications(
          ids.filter(
            (id) =>
              !this.notifications.find((notification) => notification.id === id)
          )
        );

        if (notifications.length > 0) {
          this.notifications.push(...notifications);
          this.notifications.sort((a: INotification, b: INotification) => {
            // Sort by isRead first
            if (a.isRead !== b.isRead) {
              return a.isRead ? 1 : -1; // True (isRead: true) comes first
            }

            // If isRead is the same, sort by unixTimestamp
            return b.unixTimestamp - a.unixTimestamp; // Descending order
          });
          this.eventDispatcher.Dispatch(notifications);
        }
      }
    );

    this.isInitialized = true;
  }

  public Destruct(): void {
    this.databaseListenerUnsubscriber?.();
    this.databaseListenerUnsubscriber = null;

    this.isInitialized = false;
  }

  public GetNotifications(): INotification[] {
    return this.notifications.sort((a: INotification, b: INotification) => {
      // Sort by isRead first
      if (a.isRead !== b.isRead) {
        return a.isRead ? 1 : -1; // True (isRead: true) comes first
      }

      // If isRead is the same, sort by unixTimestamp
      return b.unixTimestamp - a.unixTimestamp; // Descending order
    });
  }

  private DispatchNotifications() {
    this.eventDispatcher.Dispatch(this.notifications);
  }

  public SubscribeForNotifications(
    callback: (value: INotification[]) => unknown
  ) {
    const unsubscribeHandler: () => void =
      this.eventDispatcher.Subscribe(callback);

    this.DispatchNotifications();

    return unsubscribeHandler;
  }

  public async MarkNotificationsAsRead(): Promise<void> {
    for (const notification of this.notifications) notification.isRead = true;

    this.eventDispatcher.Dispatch(this.notifications);

    await this.UpdateNotifications(this.notifications);
  }

  public async SetNotificationReadState(
    notification: INotification,
    newReadState: boolean
  ): Promise<void> {
    const storedNotification: INotification | undefined =
      this.notifications.find(({ id }) => notification.id === id);

    if (!storedNotification) return;

    storedNotification.isRead = newReadState;

    this.eventDispatcher.Dispatch(this.notifications);

    await this.UpdateNotifications([storedNotification]);
  }

  private async UpdateNotifications(
    notifications: INotification[]
  ): Promise<void> {
    await requestApi({
      url: ApiEndpoints.UPDATE_NOTIFICATIONS,
      method: "post",
      data: {
        notificationsQuery: notifications.map((notification) => ({
          notificationId: notification.id,
          readState: notification.isRead,
        })),
      },
    });
  }

  private async FetchNotifications(
    notificationIds: string[]
  ): Promise<INotification[]> {
    if (notificationIds.length === 0) return [];

    const result = await requestApi({
      url: ApiEndpoints.GET_NOTIFICATIONS,
      method: "post",
      data: { notificationsQuery: notificationIds },
    });
    if (result.status !== 200) return [];

    return result.data.notifications.sort(
      (a: INotification, b: INotification) => {
        // Sort by isRead first
        if (a.isRead !== b.isRead) {
          return a.isRead ? 1 : -1; // True (isRead: true) comes first
        }

        // If isRead is the same, sort by unixTimestamp
        return b.unixTimestamp - a.unixTimestamp; // Descending order
      }
    );
  }
}

export const GlobalNotificationService: NotificationService =
  new NotificationService();
