import { QueryFunctionContext, useQuery, useQueryClient } from "react-query";
import { ApiEndpoints } from "../../api/endpoints";
import { requestApi } from "../../api/requestApi";
import { IChatMessage } from "../../interfaces/IChatMessage";
import { IFaqCategory } from "../../interfaces/IFaq";
import {
  // ESupportTicketPriority,
  ISupportTicket,
  SupportTicketCategory,
} from "../../interfaces/ISupportTicket";
import { cacheTimes } from "../cacheTimesRQ";

///support help questions

const fetchHelpQuestionsCategories = () => {
  return requestApi({
    url: ApiEndpoints.GET_HELP_QUESTION_CATEGORIES,
    method: "get",
  });
};

export const useHelpQuestionsCategories = () => {
  const queryClient = useQueryClient();
  return useQuery(["help-questions-categories"], fetchHelpQuestionsCategories, {
    cacheTime: cacheTimes.HELP_QUESTIONS_CACHE,
    staleTime: cacheTimes.HELP_QUESTIONS_STALE,
    initialData: () => queryClient.getQueryData("help-questions-categories"),
    refetchOnWindowFocus: false,
    select: (data) =>
      data.data.categories.sort((a: IFaqCategory, b: IFaqCategory) =>
        a.name.localeCompare(b.name)
      ),
    // select: (data) => data.data.categories.sort(
    //   (a: IFaqCategory, b: IFaqCategory) => a.orderIndex - b.orderIndex
    // ),
    onError: (err) => {
      console.log(err);
    },
  });
};

const fetchHelpQuestions = () => {
  return requestApi({
    url: ApiEndpoints.GET_HELP_QUESTIONS_LIST,
    method: "get",
  });
};

export const useHelpQuestions = () => {
  const queryClient = useQueryClient();
  return useQuery(["help-questions"], fetchHelpQuestions, {
    cacheTime: cacheTimes.HELP_QUESTIONS_CACHE,
    staleTime: cacheTimes.HELP_QUESTIONS_STALE,
    initialData: () => queryClient.getQueryData("help-questions"),
    refetchOnWindowFocus: false,
    select: (data) => data.data.helpQuestions,
    onError: (err) => {
      console.log(err);
    },
  });
};

////support tickets
export const fetchSupportTickets = () => {
  return requestApi({
    url: ApiEndpoints.GET_SUPPORT_TICKETS,
    method: "post",
  });
};

export const useSupportTickets = () => {
  const queryClient = useQueryClient();
  return useQuery(["support-tickets"], fetchSupportTickets, {
    cacheTime: cacheTimes.SUPPORT_TICKETS_CACHE,
    staleTime: cacheTimes.SUPPORT_TICKETS_STALE,
    initialData: () => queryClient.getQueryData("support-tickets"),
    refetchOnWindowFocus: true,
    select: (data) =>
      data.data.tickets.sort((a: ISupportTicket, b: ISupportTicket) => {
        return a.timestamp - b.timestamp;
      }),
    onError: (err) => {
      console.log(err);
    },
  });
};

export const submitSupportTicket = (ticket: {
  message: string;
  categoryId: string;
  // priority: ESupportTicketPriority;
}) => {
  return requestApi({
    url: ApiEndpoints.SUBMIT_SUPPORT_TICKET,
    method: "post",
    data: ticket,
  });
};

//get support departments data
export const fetchSupportDepartments = () => {
  return requestApi({
    url: ApiEndpoints.GET_SUPPORT_TICKET_CATEGORIES,
    method: "get",
  });
};

export const useSupportDepartments = () => {
  const queryClient = useQueryClient();
  return useQuery(["support-departments"], fetchSupportDepartments, {
    cacheTime: cacheTimes.SUPPORT_DEPARTMENTS_CACHE,
    staleTime: cacheTimes.SUPPORT_DEPARTMENTS_STALE,
    initialData: () => queryClient.getQueryData("support-departments"),
    refetchOnWindowFocus: false,
    select: (data) =>
      data.data.categories.sort(
        (a: SupportTicketCategory, b: SupportTicketCategory) =>
          a.name.localeCompare(b.name)
      ),
    onError: (err) => {
      console.log(err);
    },
  });
};

const fetchSupportTicketMessages = ({ queryKey }: QueryFunctionContext) => {
  const ticketId: string = queryKey[1] as string;
  return requestApi({
    url: ApiEndpoints.GET_SUPPORT_TICKET_MESSAGES,
    method: "POST",
    data: { ticketId },
  });
};

export const useSupportTicketMessages = (
  ticketId: string,
  enabled?: boolean
) => {
  const queryClient = useQueryClient();
  return useQuery(["ticket-messages", ticketId], fetchSupportTicketMessages, {
    refetchOnWindowFocus: false,
    enabled: enabled,
    cacheTime: cacheTimes.SUPPORT_TICKET_MESSAGES_CACHE,
    staleTime: cacheTimes.SUPPORT_TICKET_MESSAGES_STALE,
    initialData: queryClient.getQueryData(["ticket-messages", ticketId]),
    select: (data) =>
      data.data.messages.sort(
        (a: IChatMessage, b: IChatMessage) => a.unixTimestamp - b.unixTimestamp
      ),
    onError: (err) => {
      console.log(err);
    },
  });
};
