import cls from "./Faqs.module.scss";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import AppHeader from "../../components/appHeader/AppHeader";
import { useContext, useEffect, useState } from "react";
import { CollapsedSidebarContext } from "../../context/collapsedSidebarContext";

import SearchInput from "../../components/common/searchInput/SearchInput";
import {
  useHelpQuestions,
  useHelpQuestionsCategories,
} from "../../api/support/support";
import Loading from "../../components/common/loading/Loading";
import MainBtn from "../../components/common/buttons/mainBtn/MainBtn";
import { useNavigate, useParams } from "react-router-dom";
import { IQuestion } from "../../interfaces/IQuestion";
import HelpQuestionItem from "../../components/helpQuestionItem/HelpQuestionItem";
import { UrlParams } from "../../interfaces/UrlParams";
import { IFaqCategory } from "../../interfaces/IFaq";

const Faqs: React.FC = () => {
  const { isLoading: isLoadingFaqs, data: faqs } = useHelpQuestions(); // to implement get faqs by category id
  const { isLoading: isLoadingFaqCategories, data: faqCategories } =
    useHelpQuestionsCategories();
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { faqCategoryId } = routeParameters;

  const navigate = useNavigate();
  const { collapsed } = useContext(CollapsedSidebarContext);

  const [filter, setFilter] = useState<string>("");
  const [faqCategoryTitle, setFaqCategoryTitle] = useState<string>("");
  const [faqList, setFaqList] = useState<IQuestion[]>([]);

  const handleFilterFaqs = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const text = e.target.value;
    setFilter(text);
  };

  useEffect(() => {
    if (faqs)
      setFaqList(
        faqs.filter((faq: IQuestion) => faq.categoryId === faqCategoryId)
      );
  }, [faqCategoryId, faqs]);

  useEffect(() => {
    if (faqCategories)
      setFaqCategoryTitle(
        faqCategories.filter(
          (faqCategory: IFaqCategory) => faqCategory.id === faqCategoryId
        )[0].name
      );
  }, [faqCategoryId, faqCategories]);

  const matchesFilter = (faq: IQuestion, filter: string) => {
    return (
      faq.title.toLowerCase().includes(filter.toLowerCase()) ||
      faq.content.toLowerCase().includes(filter.toLowerCase())
    );
  };

  return (
    <div className={cls.root}>
      <SidebarMenu />
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {isLoadingFaqs || (isLoadingFaqCategories && <Loading />)}

          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>{faqCategoryTitle}</div>
              <div className={cls.subtitle}>Frequently asked questions</div>
            </div>
            <SearchInput
              customPlaceholder='Search FAQs'
              onValueChange={handleFilterFaqs}
              width={320}
              height={55}
              value={filter}
            />
          </div>

          <div className={cls.contents}>
            <div className={cls.header2}>
              <div className={cls.left}>
                <div className={cls.subtitle}>Still can`t find an answer?</div>
                <div className={cls.title}>
                  Help is on the way! We just need to check your account to send
                  you to the right place.
                </div>
              </div>
              <div className={cls.right}>
                <MainBtn
                  label='Lets`s talk!'
                  height={44}
                  width={175}
                  onClick={() => navigate("/help/support")}
                />
              </div>
            </div>

            <div className={cls.list}>
              {(filter.length > 2
                ? faqList.filter((u: IQuestion) => matchesFilter(u, filter))
                : faqList
              )?.map((faq: IQuestion, index: number) => (
                <HelpQuestionItem data={faq} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
