import cls from "./MenuList.module.scss";
import { NavLink } from "react-router-dom";
import Icon from "../../assets/icons/Icon";
// import { useRef, useState } from "react";
// import useOnClickOutside from "../../hooks/useOnClickOutside";
// import Modal from "../common/modal/Modal";
// import GlobalChat from "../globalChat/GlobalChat";
// import ChatIcon from "../icons/chatIcon";

import discordIcon from "../../assets/images/discord-logo.png";

type Props = {
  collapsed: boolean;
};

const MenuList: React.FC<Props> = ({ collapsed }) => {
  ///////////////////MODAL//////////////////////
  // const refModal = useRef(null);
  // const [showModal, setShowModal] = useState<boolean>(false);

  // const handleShowModal = (e: React.MouseEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  //   setShowModal(true);
  // };
  // const handleCloseModal = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault();
  //   setShowModal(false);
  // };
  // const handleClickOutsideModal = (e: MouseEvent) => {
  //   e.preventDefault();
  //   setShowModal(false);
  // };
  // useOnClickOutside(refModal, handleClickOutsideModal);

  return (
    <div className={`${cls.root} ${collapsed ? cls.collapsed : ""}`}>
      <NavLink
        to='/activity'
        className={(navLink) =>
          cls.item + " " + (navLink.isActive ? cls.active : "")
        }>
        <Icon icon='Dashboard' />
        <span>Activity</span>
      </NavLink>
      <NavLink
        to='/my-courses'
        className={(navLink) =>
          cls.item + " " + (navLink.isActive ? cls.active : "")
        }>
        <Icon icon='Courses' />
        <span>My Courses</span>
      </NavLink>
      {/* <NavLink
        to='/program-tasks'
        className={(navLink) =>
          cls.item + " " + (navLink.isActive ? cls.active : "")
        }>
        <Icon icon='Task' />
        <span>Program Tasks</span>
      </NavLink> */}
      <NavLink
        to='/settings'
        className={(navLink) =>
          cls.item + " " + (navLink.isActive ? cls.active : "")
        }>
        <Icon icon='Settings' />
        <span>Settings</span>
      </NavLink>

      <NavLink
        to='/help'
        className={(navLink) =>
          cls.item + " " + (navLink.isActive ? cls.active : "")
        }>
        <Icon icon='Help' />
        <span>Help</span>
      </NavLink>

      <NavLink
        to='/walktrough'
        className={(navLink) =>
          cls.item + " " + (navLink.isActive ? cls.active : "")
        }>
        <Icon icon='Task' />
        <span>Walkthrough</span>
      </NavLink>

      <div className={cls.lastItem}>
        <a
          href='https://discord.com/invite/Nt9BW7h4ZY'
          target='_blank'
          rel='noreferrer'
          className={cls.item}>
          <img className={cls.discord} src={discordIcon} alt='' />
        </a>

        <NavLink className={cls.terms} to='/terms'>
          Terms & Conditions
        </NavLink>
        <NavLink className={cls.terms} to='/privacy'>
          Privacy Policy
        </NavLink>
      </div>

      {/* <Modal isVisible={showModal} width={840}>
        <div ref={refModal}>
          <div className='modal-header'>
            <button onClick={handleCloseModal}>X</button>
          </div>
          <div>
            <GlobalChat title='Global Chat' />
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

export default MenuList;
