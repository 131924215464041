import * as d3 from "d3";
import "./TimelineD3.scss";
import { useEffect, useRef, useState } from "react";
import { zoom, zoomTransform } from "d3";
import useResizeObserver from "./useResizeObserver";
import { useEnrolledClasses } from "../../api/class/class";
import { useQueryClient } from "react-query";
import { requestApi } from "../../api/requestApi";
import { ApiEndpoints } from "../../api/endpoints";
import { useNavigate } from "react-router-dom";

type CourseProps = {
  id: string;
  title: string;
};

type TermProps = {
  id: string;
  title: string;
};

type WeekProps = {
  id: string;
  title: string;
};

type MetadataProps = {
  course: CourseProps;
  term: TermProps;
  week: WeekProps;
};

export type DataSeriesProps = {
  metadata: MetadataProps;
  startsAt: string;
  endsAt: string;
  type: string;
  title: string;
};

const margin = {
  top: 60,
  right: 20,
  bottom: 40,
  left: 150,
};

type Props = {
  dataSeries: DataSeriesProps[];
};

const TimelineD3: React.FC<Props> = ({ dataSeries }) => {
  const { data: enrolledClasses, isLoading: isLoadingEnrolledClasses } =
    useEnrolledClasses();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const containerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const dimensions = useResizeObserver(containerRef);

  const [zoomState, setZoomState] = useState<d3.ZoomTransform>();

  ///// Handle navigation to course overview
  const handleGoToCourse = async (
    e: React.MouseEvent<SVGElement>,
    d: DataSeriesProps
  ) => {
    e.preventDefault();

    if (!enrolledClasses) return;

    console.log(enrolledClasses);

    const classId = enrolledClasses.find(
      (classObj: any) => classObj.courseId === d.metadata.course.id
    )?.id;

    if (!classId) return;

    //////////////
    let firstVideoId: string = "";

    const cacheVideos: any = queryClient.getQueryData([
      "videos",
      d.metadata.course.id,
      d.metadata.term.id,
      d.metadata.week.id,
    ]);

    if (cacheVideos) {
      firstVideoId = cacheVideos?.data.videos[0].id;
    } else {
      const videosData = await requestApi({
        url: ApiEndpoints.GET_VIDEOS,
        method: "post",
        data: {
          courseId: d.metadata.course.id,
          termId: d.metadata.term.id,
          weekId: d.metadata.week.id,
        },
      });
      queryClient.setQueryData(
        [
          "videos",
          d.metadata.course.id,
          d.metadata.term.id,
          d.metadata.week.id,
        ],
        videosData
      );
      firstVideoId = videosData.data.videos[0].id;
    }

    if (!firstVideoId) return;

    navigate(
      `/course-overview/${d.metadata.course.id}/${classId}/${d.metadata.term.id}/${d.metadata.week.id}/${firstVideoId}`
    );
  };

  ///// Analyze and parse data series
  const analyzeDataSeries = (dataSeries: DataSeriesProps[]) => {
    let joinedYScale: CourseProps[] = [];
    let minDate = Infinity;
    let maxDate = -Infinity;

    dataSeries.forEach((item) => {
      // Add unique "course" objects based on "course/id"
      const courseId = item.metadata.course.id;
      const existingCourse = joinedYScale.find(
        (course: CourseProps) => course.id === courseId
      );
      if (!existingCourse) {
        joinedYScale.push(item.metadata.course);
      }
      // Update minDate and maxDate values
      const startDate = new Date(item.startsAt);

      // Calculate the endsAt timestamp by adding 6 days (6 * 24 hours)
      const endsAt = new Date(startDate);
      endsAt.setDate(startDate.getDate() + 6);
      // Format endsAt back into ISO 8601 format
      const formattedEndsAt = endsAt.toISOString();

      const endDate = new Date(formattedEndsAt);

      if (startDate.getTime() < minDate) {
        minDate = startDate.getTime();
      }

      if (endDate.getTime() > maxDate) {
        maxDate = endDate.getTime();
      }
    });

    return {
      joinedYScale,
      minDate,
      maxDate,
    };

    // // const joinedData: object[] = [];
    // const joinedYScale: string[] = [];

    // dataSeries.forEach((data) => {
    //   // course.data.forEach((dataObj: any) => {
    //   // joinedData.push(data);
    //   if (joinedYScale.indexOf(data.y) === -1) joinedYScale.push(data.y);
    //   // });
    // });
    // // console.log(joinedData);
    // // console.log(joinedYScale);
    // return {
    //   // _dataSeries: joinedData,
    //   _joinedYScale: joinedYScale,
    // };
  };

  useEffect(() => {
    if (!containerRef.current || !svgRef.current || dataSeries.length === 0)
      return;

    // const { _dataSeries, _joinedYScale } = analyzeDataSeries(dataSeries);
    const { joinedYScale, minDate, maxDate } = analyzeDataSeries(dataSeries);

    const { width } =
      dimensions || containerRef.current.getBoundingClientRect();

    const height = joinedYScale.length * 92 + margin.top + margin.bottom;

    const svgChart = d3
      .select(svgRef.current as Element)
      .attr("width", width)
      .attr("preserveAspectRatio", "xMidYMid meet")
      .attr("viewBox", "0 0 " + width + " " + height)
      .attr("height", height);

    svgChart.selectAll("*").remove();

    ///////////////////// [SCALES & AXES] /////////////////////

    const yScale = d3
      .scaleLinear()
      .range([0, height - margin.top - margin.bottom])
      .domain([0, joinedYScale.length]);

    // const startDate = new Date(dataSeries[0].x[0]);
    // startDate.setDate(startDate.getDate() - 1);
    // const endDate = new Date(dataSeries[dataSeries.length - 1].x[1]);
    // endDate.setDate(endDate.getDate() + 1);

    const xScale = d3
      .scaleTime()
      // .domain([d3.timeParse("%Y")(2001), d3.timeParse("%Y")(2002)])
      // .domain([
      //   new Date(dataSeries[0].x[0]),
      //   new Date(dataSeries[dataSeries.length - 1].x[1]),
      // ])
      .domain([minDate, maxDate])
      .nice()
      .range([0, width - margin.left]);

    if (zoomState) {
      const newXScale = zoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }

    const xAxis = d3
      .axisTop(xScale)
      .tickSize(0)
      // .ticks(d3.timeDay.every(1))
      .tickFormat(((d, i) => d3.timeFormat("%d' %b")(d as Date)) as (
        domainValue: Date | d3.NumberValue,
        index: number
      ) => string);

    //////////////// LEFT AXIS /////////////////
    const leftAxis = svgChart
      .append("g")
      .attr("class", "left-axis")
      .attr("transform", "translate(" + 0 + "," + margin.top + ")");

    leftAxis
      // .append("rect")
      // .attr("fill", "#fefefe2b")
      .attr("width", margin.left)
      .attr("height", height - margin.top - margin.bottom);

    leftAxis
      .append("line")
      .attr("x1", margin.left)
      .attr("y1", 0)
      .attr("x2", margin.left)
      .attr("y2", height - margin.top - margin.bottom)
      .attr("stroke", "#9d9ca04f")
      .attr("stroke-width", 0.7);

    leftAxis
      .selectAll(".leftAxisSeparators")
      .data(joinedYScale)
      .enter()
      .append("line")
      .attr("x1", margin.left - 20)
      .attr("x2", margin.left - 1)
      .attr("y1", function (d, i) {
        return yScale(i + 1);
      })
      .attr("y2", function (d, i) {
        return yScale(i + 1);
      })
      .attr("class", "leftAxisSeparator")
      .attr("stroke", "#9d9ca04f")
      .attr("stroke-width", 0.7);

    // leftAxis
    //   .selectAll(".leftAxisText")
    //   .data(joinedYScale)
    //   .enter()
    //   .append("text")
    //   .text((d: CourseProps) => d.title)
    //   .attr("x", 10)
    //   .attr("y", function (d, i) {
    //     return yScale(i + 0.5);
    //   })
    //   .attr("dy", ".5ex")
    //   .attr("text-anchor", "start")
    //   .attr("alignment-baseline", "middle")
    //   .attr("dominant-baseline", "middle")
    //   .attr("xmlSpace", "preserve")
    //   .attr("class", "leftAxisText");

    // leftAxis
    //   .selectAll("text")
    //   .attr("font-size", "16px")
    //   .attr("line-height", "26px")
    //   .attr("letter-spacing", "-0.14px")
    //   .attr("font-weight", 200)
    //   .attr("font-family", "'Sofia Pro', sans-serif")
    //   .attr("fill", "#FBFBFB");

    leftAxis
      .selectAll(".leftAxisText")
      .data(joinedYScale)
      .enter()
      .append("foreignObject")
      .attr("x", 0)
      .attr("y", function (d, i) {
        return yScale(i) + 1;
      })
      .attr("width", margin.left - 4) // Adjust the width as needed
      .attr("height", 90) // Adjust the height as needed
      .append("xhtml:div")
      .attr("class", "leftAxisText")
      .style("font-size", "16px")
      .style("line-height", "22px")
      .style("letter-spacing", "-0.14px")
      .style("font-weight", 200)
      .style("font-family", "'Sofia Pro', sans-serif")
      .style("color", "#FBFBFB")
      .style("width", "100%") // Ensure the div fills the available width
      .style("height", "100%") // Ensure the div fills the available height
      .style("display", "flex")
      .style("align-items", "center")
      .style("justify-content", "flex-start")
      .style("overflow-wrap", "break-word")
      .style("overflow", "hidden")
      .html((d: CourseProps) => d.title);

    //////////////////

    //////// MAIN CHART ////////

    svgChart /// clip path
      .append("defs")
      .append("clipPath")
      .attr("id", "mainClip")
      .append("rect")
      .attr("transform", "translate(" + 0 + "," + 0 + ")")
      .attr("width", width)
      .attr("height", height);

    const main = svgChart
      .append("g")
      .attr("class", "main")
      .attr("transform", "translate(" + margin.left + "," + 0 + ")")
      .attr("width", width - margin.left)
      // .call(zoom)

      .attr("clip-path", "url(#mainClip)");

    // .attr("height", height);

    // main // 4 layout testing
    //   .append("rect")
    //   .attr("stroke", "grey")
    //   .attr("width", width - margin.left)
    //   .attr("height", height);

    ///// TOP AXIS  /////

    const topAxis = main
      .append("g")
      .attr("class", "top-axis")
      .attr("transform", "translate(" + 0 + "," + 16 + ")")
      .call(xAxis);

    topAxis.selectAll(".domain").attr("stroke", "transparent");
    topAxis
      .selectAll("text")
      .attr("font-size", "14px")
      .attr("line-height", "26px")
      .attr("letter-spacing", "-0.14px")
      .attr("font-weight", 200)
      .attr("font-family", "'Sofia Pro', sans-serif")
      .attr("color", "#FCFEFF");
    //////////////////

    //////////////

    const chartContainer = main
      .append("g")

      .attr("class", "chart-container")
      .attr("transform", "translate(" + 0 + "," + margin.top + ")");
    //////////////
    // separators
    chartContainer
      .append("g")
      .attr("class", "separators")
      .selectAll(".separator")
      .data(dataSeries)
      .enter()
      .append("line")
      .attr("class", "separator")
      .attr("x1", 1)
      .attr("y1", function (d, i) {
        return yScale(i + 1);
      })
      .attr("x2", width - margin.left)
      .attr("y2", function (d, i) {
        return yScale(i + 1);
      })
      .attr("stroke", "#9d9ca04f")
      .attr("stroke-width", 0.7);

    //////////////////////// bars ////////////////////////////////////////////
    const bars = chartContainer
      .append("g")
      .attr("class", "bars")
      .selectAll(".bar")
      .data(dataSeries)
      .enter();

    // const bar = bars.append("g").attr("class", "bar");

    // bar
    //   .append("rect")
    //   .attr("x", (d: DataSeriesProps) => {
    //     // console.log(xScale(new Date(d.x[0])));
    //     // console.log(new Date(d.x[0]));

    //     return xScale(new Date(d.startsAt)) + 1;
    //   })
    //   .attr("y", (d: DataSeriesProps, i: number) => {
    //     const indexYScale = joinedYScale.findIndex(
    //       (obj: CourseProps) => obj.id === d.metadata.course.id
    //     );
    //     return yScale(indexYScale) + 1;
    //   })
    //   .attr("class", "bar")
    //   .attr("width", (d: DataSeriesProps) => {
    //     const startsAtDate = new Date(d.startsAt);
    //     // Increase endsAt by 5 days
    //     const endsAtDate = new Date(d.endsAt);
    //     endsAtDate.setDate(endsAtDate.getDate() + 6);

    //     return xScale(endsAtDate) - xScale(startsAtDate) - 2;
    //   })
    //   .attr("height", () => yScale(1) - yScale(0) - 2)
    //   .attr("fill", "#27122a")
    //   .attr("style", "cursor: pointer;")
    //   .on("click", handleGoToCourse);

    bars
      .append("foreignObject")
      .attr("x", (d: DataSeriesProps) => {
        return xScale(new Date(d.startsAt)) + 1;
      })
      .attr("y", (d: DataSeriesProps, i: number) => {
        const indexYScale = joinedYScale.findIndex(
          (obj: CourseProps) => obj.id === d.metadata.course.id
        );
        return yScale(indexYScale) + 1;
      })
      .attr("class", "bar")
      .attr("width", (d: DataSeriesProps) => {
        const startsAtDate = new Date(d.startsAt);
        // Increase endsAt by 5 days
        const endsAtDate = new Date(d.endsAt);
        endsAtDate.setDate(endsAtDate.getDate() + 6);

        return xScale(endsAtDate) - xScale(startsAtDate) - 2 + "px";
      })
      .attr("height", () => yScale(1) - yScale(0) - 2 + "px")
      // .attr("style", "cursor: pointer;background: #27122a;overflow: hidden;")
      .html((d) => {
        return `<div class="bar-container">
        <p class="course-title">
        ${d.metadata.course.title}
        </p>
        <p class="course-structure">${d.metadata.term.title} / ${
          d.metadata.week.title
        }</p>
<p class="course-date">
Starts at: ${new Date(d.startsAt).toLocaleDateString()}
</p>
        </div>`;
      })
      .on("click", handleGoToCourse);

    //       .attr("fill", "#FCFEFF")
    //       .attr("font-size", "20px")
    //       .attr("line-height", "26px")
    //       .attr("letter-spacing", "-0.14px")
    //       .attr("font-weight", 600)
    //       .attr("font-family", "'Sofia Pro', sans-serif")

    //////////////

    // const imgSrc = "/placeholder.webp";
    // bar
    //   .append("image")
    //   .attr("class", "barImage")
    //   .attr("x", (d: DataSeriesProps) => xScale(new Date(d.startsAt)) + 10)

    //   .attr("y", (d: DataSeriesProps, i: number) => {
    //     const indexYScale = joinedYScale.findIndex(
    //       (obj: CourseProps) => obj.id === d.metadata.course.id
    //     );
    //     return yScale(indexYScale) + 16;
    //   })
    //   // .attr("y", (d, i) => yScale(i) + 16)
    //   .attr("width", () => yScale(1) - yScale(0) - 30)
    //   .attr("height", () => yScale(1) - yScale(0) - 30)

    //   .attr("preserveAspectRatio", "xMidYMid slice")
    //   .attr("xlink:href", imgSrc)
    //   .attr("style", "cursor: pointer;")
    //   .on("click", handleGoToCourse);

    //       //////////////

    //     bar
    //       .append("text")
    //       .attr("class", "courseTitle")

    //       .attr("x", (d: DataSeriesProps) => xScale(new Date(d.startsAt)) + 90)
    //       // .attr("y", (d, i) => yScale(i) + 30)

    //       .attr("y", (d: DataSeriesProps, i: number) => {
    //         const indexYScale = joinedYScale.findIndex(
    //           (obj: CourseProps) => obj.id === d.metadata.course.id
    //         );
    //         return yScale(indexYScale) + 30;
    //       })

    //       // .attr("width", () => yScale(1) - yScale(0) - 30)
    //       // .attr("height", () => yScale(1) - yScale(0) - 30)
    //       .text((d: DataSeriesProps) => d.metadata.course.title)
    //       .attr("fill", "#FCFEFF")
    //       .attr("font-size", "20px")
    //       .attr("line-height", "26px")
    //       .attr("letter-spacing", "-0.14px")
    //       .attr("font-weight", 600)
    //       .attr("font-family", "'Sofia Pro', sans-serif")
    //       .attr("style", "cursor: pointer;")
    //       .on("click", handleGoToCourse);

    //       //////////////

    //     bar
    //       .append("text")
    //       .attr("class", "termTitle")

    //       .attr("x", (d: DataSeriesProps) => xScale(new Date(d.startsAt)) + 90)
    //       // .attr("y", (d, i) => yScale(i) + 50)

    //       .attr("y", (d: DataSeriesProps, i: number) => {
    //         const indexYScale = joinedYScale.findIndex(
    //           (obj: CourseProps) => obj.id === d.metadata.course.id
    //         );
    //         return yScale(indexYScale) + 50;
    //       })

    //       // .attr("width", () => yScale(1) - yScale(0) - 30)
    //       // .attr("height", () => yScale(1) - yScale(0) - 30)
    //       .text((d: DataSeriesProps) => d.metadata.term.title)
    //       .attr("fill", "#FCFEFF")
    //       .attr("font-size", "14px")
    //       .attr("line-height", "25px")
    //       .attr("letter-spacing", "-0.14px")
    //       .attr("font-weight", 200)
    //       .attr("font-family", "'Sofia Pro', sans-serif")
    //       .attr("style", "cursor: pointer;")
    //       .on("click", handleGoToCourse);

    // //////////////

    //     bar
    //       .append("text")
    //       .attr("class", "startDate")

    //       .attr("x", (d: DataSeriesProps) => xScale(new Date(d.startsAt)) + 90)
    //       // .attr("y", (d, i) => yScale(i) + 75)
    //       .attr("y", (d: DataSeriesProps, i: number) => {
    //         const indexYScale = joinedYScale.findIndex(
    //           (obj: CourseProps) => obj.id === d.metadata.course.id
    //         );
    //         return yScale(indexYScale) + 75;
    //       })
    //       .text((d: DataSeriesProps) =>
    //         d3.timeFormat("%d' %b %Y")(new Date(d.startsAt))
    //       )
    //       .attr("fill", "#FCFEFF")
    //       .attr("font-size", "14px")
    //       .attr("line-height", "25px")
    //       .attr("letter-spacing", "-0.14px")
    //       .attr("font-weight", 200)
    //       .attr("font-family", "'Sofia Pro', sans-serif")
    //       .attr("style", "cursor: pointer;")
    //       .on("click", handleGoToCourse);

    //// annotations

    const annotationLines = chartContainer
      .append("g")
      .attr("class", "annotationLines")
      .selectAll(".annotationLine")
      .data(dataSeries)
      .enter();

    annotationLines
      .append("line")
      .attr("class", "annotationLine")
      .attr("x1", (d: DataSeriesProps) => xScale(new Date(d.startsAt)))
      // .attr("y1", (d, i) => yScale(i + 1))
      .attr("y1", (d: DataSeriesProps, i: number) => {
        const indexYScale = joinedYScale.findIndex(
          (obj: CourseProps) => obj.id === d.metadata.course.id
        );
        return yScale(indexYScale + 1);
      })

      .attr("x2", (d: DataSeriesProps) => xScale(new Date(d.startsAt)))
      .attr("y2", (d, i) => -20)
      .attr("stroke", "#874EDB")
      .attr("stroke-width", 0.3);

    const annotationCircles = chartContainer
      .append("g")
      .attr("class", "annotationCircles")
      .selectAll(".annotationCircle")
      .data(dataSeries)
      .enter();

    annotationCircles
      .append("circle")
      .attr("class", "annotationCircle")
      .attr("cx", (d: DataSeriesProps) => xScale(new Date(d.startsAt)))
      .attr("cy", (d, i) => -20)
      .attr("r", 5)
      .attr("fill", "#874EDB")
      .attr("stroke", "#874EDB")
      .attr("stroke-width", 0.3);

    /////////ZOOM & PAN SETTINGS////////

    const zoomBehavior: d3.ZoomBehavior<Element, unknown> = zoom()
      .scaleExtent([0.2, 5])
      .translateExtent([
        [0, 0],
        [width, height],
      ])
      .on("zoom", (e: d3.D3ZoomEvent<Element, unknown>) => {
        // console.log("zoom", e);
        const _zoomState: d3.ZoomTransform = zoomTransform(
          svgRef.current as Element
        );

        // console.log("zoomState", _zoomState);

        setZoomState(_zoomState);
      });

    // console.log("zoomBehavior", zoomBehavior);
    svgChart.call(zoomBehavior);

    ////////////////////////
  }, [dimensions, zoomState, dataSeries]);

  if (isLoadingEnrolledClasses) return null;

  return (
    <div ref={containerRef}>
      <svg ref={svgRef} />
    </div>
  );
};

export default TimelineD3;
