const sec = 1000;
const min = 60 * sec;
const hour = 60 * min;
const day = 24 * hour;

export enum cacheTimes {
  //////
  USER_DATA_CACHE = 12 * hour,
  USER_DATA_STALE = 10 * min,
  //////
  PUBLIC_USER_DATA_CACHE = day,
  PUBLIC_USER_DATA_STALE = hour,
  //////
  USER_ACTIVITY_PROGRESS_CACHE = 30 * min,
  USER_ACTIVITY_PROGRESS_STALE = 10 * min,
  //////
  ENROLLED_COURSES_CACHE = hour,
  ENROLLED_COURSES_STALE = 30 * min,
  //////
  COURSE_SCHEDULES_CACHE = 10 * min,
  COURSE_SCHEDULES_STALE = 5 * min,
  //////
  COURSE_DATA_CACHE = 12 * hour,
  COURSE_DATA_STALE = 6 * hour,
  //////
  COURSE_TERMS_CACHE = 12 * hour,
  COURSE_TERMS_STALE = 6 * hour,
  //////
  VIDEO_LIST_CACHE = 12 * hour,
  VIDEO_LIST_STALE = 6 * hour,
  //////
  WEEK_LIST_CACHE = 12 * hour,
  WEEK_LIST_STALE = 6 * hour,
  //////
  ENROLLED_CLASSES_CACHE = hour,
  ENROLLED_CLASSES_STALE = 30 * min,
  //////
  CLASS_QUESTIONS_CACHE = 10 * min,
  CLASS_QUESTIONS_STALE = 5 * min,
  //////
  CLASS_QUESTION_COMMENTS_CACHE = 5 * min,
  CLASS_QUESTION_COMMENTS_STALE = 2 * min,
  //////
  HELP_QUESTIONS_CACHE = day,
  HELP_QUESTIONS_STALE = 12 * hour,
  //////
  SUPPORT_TICKETS_CACHE = hour,
  SUPPORT_TICKETS_STALE = 30 * min,
  //////
  SUPPORT_DEPARTMENTS_CACHE = 5 * day,
  SUPPORT_DEPARTMENTS_STALE = 2 * day,
  //////
  SUPPORT_TICKET_MESSAGES_CACHE = hour,
  SUPPORT_TICKET_MESSAGES_STALE = 30 * min,
}
