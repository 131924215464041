import { useEffect, useState } from "react";
import { useMutation, useQueries, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  addNewQuestion,
  useClassQuestions,
} from "../../../api/classQuestions/classQuestions";
import { GetUserId } from "../../../api/firebase";
import { fetchPublicUser } from "../../../api/user/user";
import { IClass } from "../../../interfaces/IClass";
import { IClassQuestionTopic } from "../../../interfaces/IClassQuestionTopic";
import { IPublicUser } from "../../../interfaces/IPublicUser";
import { EServiceTier } from "../../../interfaces/IServiceTier";
import { IUser } from "../../../interfaces/IUser";
import { UrlParams } from "../../../interfaces/UrlParams";
import Loading from "../../common/loading/Loading";
import MessageSubmitter from "../../common/messageSubmitter/MessageSubmitter";
import cls from "./ClassQuestions.module.scss";
import ClassQuestionTopic from "./ClassQuestionTopic";

type Props = {
  // questions: IClassQuestionTopic[];
  questionIdInUrl?: string | null;
  classTier: EServiceTier;
  userRole: string | null | undefined;
};

const ClassQuestions: React.FC<Props> = ({
  classTier,
  userRole,
  questionIdInUrl,
}) => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, classId, termId, weekId } = routeParameters;
  const queryClient = useQueryClient();

  // fetch class questions
  const {
    data: questions,
    // isLoading: isLoadingClassQuestions,
    // refetch: refetchClassQuestions,
  } = useClassQuestions(weekId, termId, classId, courseId, classTier);

  const [areUsersLoaded, setUsersLoaded] = useState<boolean>(false);
  const [usersToFetch, setUsersToFetch] = useState<string[]>([]);
  // const [questionDescription, setQuestionDescription] = useState<string>("");
  // const [submitButtonEnabled, setSubmitButtonEnabled] =
  //   useState<boolean>(false);

  const addQuestionRQ = useMutation(addNewQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        "class-questions",
        weekId,
        termId,
        classId,
        courseId,
        classTier,
      ]);
    },
    onError: (err) => {
      console.log("addQuestionRQ", err);
    },
    // onSettled: () => {
    //   setSubmitButtonEnabled(false);
    //   setQuestionDescription("");
    // },
  });

  const handleSubmitQuestion = (textToSubmit: string) => {
    // e.preventDefault();
    if (textToSubmit.length === 0) return;
    // setSubmitButtonEnabled(true);
    const payload = {
      weekId: weekId,
      serviceTier: classTier,
      classId,
      courseId,
      title: "",
      termId: termId,
      description: textToSubmit,
    };
    return addQuestionRQ.mutate(payload);
  };

  // const handleQuestionInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   e.preventDefault();
  //   const val = e.target.value;
  //   setQuestionDescription(val);
  // };

  const LoadUsers = async () => {
    const usersNotCached: string[] = [];

    for (const question of questions)
      if (!queryClient.getQueryData(["public-user", question.userId]))
        if (!usersNotCached.includes(question.userId))
          usersNotCached.push(question.userId);

    setUsersToFetch(usersNotCached);
    setUsersLoaded(true);
  };

  useEffect(() => {
    if (questions) LoadUsers().catch((e) => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  // useEffect(() => {
  //   if (questionIdInUrl) {

  //     const classQuestionsCache: any = queryClient.getQueryData([
  //       "class-questions",
  //       weekId,
  //       termId,
  //       classId,
  //       courseId,
  //       classTier,
  //     ]);

  //     const isQuestionInCache = classQuestionsCache?.data.questions.some(
  //       (question: IClassQuestionTopic) => question.id === questionIdInUrl
  //     );

  //     if (!isQuestionInCache) {
  //       console.log("Question is not the cache");
  //       refetchClassQuestions();
  //     } else {
  //       console.log("Question is in the cache");
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [questionIdInUrl]);

  useQueries(
    usersToFetch.map((userId: string) => {
      return {
        queryKey: ["public-user", userId],
        queryFn: () => fetchPublicUser(userId),
        initialData: queryClient.getQueryData(["public-user", userId]),
        cacheTime: 2 * 3600000,
        refetchOnMount: false,
        enabled: areUsersLoaded,
      };
    })
  );

  type userTypeResponse = { data: { user: IUser } };

  if (!questions && !areUsersLoaded) {
    return <p>Loading messages...</p>;
  }

  return (
    <div className={cls.root}>
      <div className={cls.messageComposer}>
        <MessageSubmitter
          emojiEnabled={true}
          submitMessage={handleSubmitQuestion}
        />
      </div>
      <div className={cls.wrapper}>
        {questions?.length === 0 && (
          <p
            style={{
              color: "white",
              fontSize: "1.3125rem",
              letterSpacing: -0.33,
              lineHeight: "1.5625rem",
              marginBottom: 24,
            }}>
            No questions for this class.
          </p>
        )}

        {questions?.map((question: IClassQuestionTopic) => {
          const user: userTypeResponse = (
            question.userId === GetUserId()
              ? queryClient.getQueryData(["user-data"])
              : queryClient.getQueryData(["public-user", question.userId])
          ) as userTypeResponse;

          return (
            <ClassQuestionTopic
              key={question.id}
              isQuestionIdInUrl={questionIdInUrl}
              user={user}
              question={question}
              classTier={classTier}
              userRole={userRole}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ClassQuestions;
