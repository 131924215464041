import React from "react";
import iconData from "./IconsLib";

type IconProps = {
  size?: number;
  color?: string;
  icon: string;
  viewBox?: string;
  style?: object;
  className?: string;
  onClick?: (e: React.MouseEvent<SVGElement>) => void;
};

type PathProps = {
  d: string;
  transform?: string;
  fill?: string;
};

const defaultStyles = { display: "inline-block", verticalAlign: "middle" };

const Icon: React.FC<IconProps> = ({
  size,
  color,
  icon,
  className,
  style,
  viewBox,
}) => {
  const styles = { ...defaultStyles, ...style };
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={styles}
      className={className}
      width={size}
      height={size}
      viewBox={viewBox}>
      <g id={icon} data-name={icon}>
        {iconData[icon].map((path: PathProps, pi) => (
          <path
            fill={color}
            d={path.d}
            key={pi}
            transform={path.transform}></path>
        ))}
      </g>
    </svg>
  );
};

Icon.defaultProps = {
  size: 24,
  color: "#7a8798",
  viewBox: "0 0 24 24",
  style: {},
  className: "",
};
export default Icon;
