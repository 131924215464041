import { useNavigate, useSearchParams } from "react-router-dom";
import { useVideos } from "../../api/course/course";
import INotification, {
  NotificationPriority,
} from "../../interfaces/INotification";
import "./NotificationItem.scss";

import { useQueryClient } from "react-query";
import { useEnrolledClasses } from "../../api/class/class";
import { IClass } from "../../interfaces/IClass";
import { timeFormatter } from "../../utils/common";

type Props = {
  notification: INotification;
  onReadClick: (newReadState: boolean) => void;
  onLinkClicked: () => void;
};

const MapPriorityToColor: Record<NotificationPriority, string> = {
  [NotificationPriority.CRITICAL]: "#C31E1E",
  [NotificationPriority.URGENT]: "#E0C12B",
  [NotificationPriority.IMPORTANT]: "#99E24A",
  [NotificationPriority.NORMAL]: "#7A8798",
};

const NotificationItem: React.FC<Props> = ({
  notification,
  onReadClick,
  onLinkClicked,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const questionUrlParamId = searchParams.get("questionId");
  const queryClient = useQueryClient();

  const { courseId, classId, termId, weekId, questionId, ticketId } =
    notification?.metadata || {};

  const { data: enrolledClasses } = useEnrolledClasses(
    !!notification?.metadata?.classId
  );

  const { data: videoList } = useVideos(
    courseId,
    termId,
    weekId,
    !!courseId && !!termId && !!weekId
  );

  const handleGoToNotificationSource = async (
    e: React.MouseEvent<HTMLParagraphElement>
  ) => {
    e.preventDefault();

    // console.log("notification", notification);

    ////// support ticket ///////
    if (
      notification.type === "SUPPORT_TICKET_CREATED" ||
      notification.type === "SUPPORT_TICKET_ANSWERED" ||
      notification.type === "SUPPORT_TICKET_CLOSED" ||
      notification.type === "SUPPORT_TICKET_STATUS_UPDATED"
    ) {
      if (!ticketId) return;

      if (notification.type === "SUPPORT_TICKET_ANSWERED")
        queryClient.invalidateQueries(["ticket-messages", ticketId]);

      if (
        notification.type === "SUPPORT_TICKET_STATUS_UPDATED" ||
        notification.type === "SUPPORT_TICKET_CLOSED"
      )
        queryClient.invalidateQueries(["support-tickets"]);

      onReadClick(true);
      onLinkClicked();
      return navigate(`/help/tickets/${notification.metadata.ticketId}`);
    }

    ////// class questions ///////
    if (
      notification.type === "CLASS_QUESTION" ||
      notification.type === "CLASS_QUESTION_ANSWERED" ||
      notification.type === "CLASS_QUESTION_REPORTED" ||
      notification.type === "CLASS_QUESTION_EDITED" ||
      notification.type === "CLASS_QUESTION_REMOVED" ||
      notification.type === "CLASS_QUESTION_ANSWER_EDITED" ||
      notification.type === "CLASS_QUESTION_ANSWER_REMOVED"
    ) {
      if (!courseId || !classId || !termId || !weekId || !questionId) return;

      const tier = enrolledClasses.find((cl: IClass) => cl.id === classId).tier;

      // if new question, edited question or deleted invalidate the class-questions query and refetch it
      if (
        notification.type === "CLASS_QUESTION" ||
        notification.type === "CLASS_QUESTION_EDITED" ||
        notification.type === "CLASS_QUESTION_REMOVED"
      ) {
        queryClient.invalidateQueries([
          "class-questions",
          weekId,
          termId,
          classId,
          courseId,
          tier,
        ]);
      }

      // if new answer or edited answer or removed answer, invalidate the class-question-answers query and refetch it
      if (
        notification.type === "CLASS_QUESTION_ANSWERED" ||
        notification.type === "CLASS_QUESTION_ANSWER_EDITED" ||
        notification.type === "CLASS_QUESTION_ANSWER_REMOVED"
      ) {
        queryClient.invalidateQueries([
          "comments-for-question",
          weekId,
          termId,
          {
            classId,
            serviceTier: tier,
            courseId,
          },
          questionId,
        ]);
      }

      // if notification is about a question that was removed,
      // or if the questionId is in the url params (already on the question page),
      // then no navigation is needed
      if (
        notification.type === "CLASS_QUESTION_REMOVED" ||
        (questionUrlParamId && questionUrlParamId === questionId)
      ) {
        onReadClick(true);
        onLinkClicked();
        return;
      }

      const firstVideo = videoList?.[0];

      if (firstVideo) {
        onReadClick(true);
        onLinkClicked();
        return navigate(
          `/course-overview/${courseId}/${classId}/${termId}/${weekId}/${firstVideo.id}?questionId=${questionId}`
        );
      }
    }
  };

  return (
    <div
      className={`notification-item ${
        notification.metadata.source === "SYSTEM" ||
        notification.metadata.source === "ADMIN"
          ? "from-sys-admin"
          : ""
      }`}>
      <div className='avatar'>
        <img
          src={
            notification.source === "SYSTEM"
              ? "/favicon.webp"
              : notification.metadata.avatarUrl || "/user-avatar.webp"
          }
          // src='https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8bWFufGVufDB8fDB8fA%3D%3D&w=1000&q=80'
          alt=''
        />
        <div
          className='status'
          style={{ background: MapPriorityToColor[notification.priority] }}
        />
      </div>

      <div className='details'>
        <div className={`contents ${notification.isRead ? "read" : ""}`}>
          {notification.description}

          <div className='description show'>
            <p onClick={handleGoToNotificationSource}>
              Go to notification source
            </p>
          </div>
        </div>

        <div className='time'>{timeFormatter(notification.unixTimestamp)}</div>
      </div>
    </div>
  );
};

export default NotificationItem;
