import { createContext, useEffect, useState, ReactNode } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../api/firebase";
import { User as FirebaseUser } from "firebase/auth";

type AuthContextProps = {
  currentUser: FirebaseUser | null;
  userRole: string | null;
  notificationsDisabled: boolean;
  updateNotificationsDisabled: (disabled: boolean) => void;
};

const AuthContext = createContext<AuthContextProps | null>(null);

type Props = {
  children: ReactNode;
};

const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<FirebaseUser | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [notificationsDisabled, setNotificationsDisabled] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && user.emailVerified) {
        const { claims } = await user.getIdTokenResult();
        // console.log("claims", claims);
        setUserRole(claims.role);
        setNotificationsDisabled(claims.areNotificationsDisabled);
        setCurrentUser(user);
        setLoading(false);
      } else {
        setUserRole(null);
        setCurrentUser(null);
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const authContextValue: AuthContextProps = {
    currentUser,
    userRole,
    notificationsDisabled,
    updateNotificationsDisabled: (disabled: boolean) =>
      setNotificationsDisabled(disabled),
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
