import cls from "./EditQuestion.module.scss";

// import FormInput from "../../common/formInput/FormInput";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { IChatMessage } from "../../../interfaces/IChatMessage";
import TextareaInput from "../../common/textareaInput/TextareaInput";

type FormInputs = {
  text: string;
};

const schema = yup.object().shape({
  text: yup.string().required("Text value is required!"),
});

type Props = {
  width?: number;
  height?: number;
  chatMessage: IChatMessage;

  onSave: (d: string) => void;
  onDeny?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};
const EditComment: React.FC<Props> = ({
  width,
  height,

  chatMessage,
  onDeny,
  onSave,
}) => {
  ///////////////// handle submit form
  const { handleSubmit, control } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormInputs) => {
    // if (data) {

    onSave(data.text);
    // }
  };

  return (
    <div className={cls.root}>
      <div className={cls.wrapper} style={{ width: width, height: height }}>
        <div className={cls.title}>Edit Comment</div>

        {/* <form action=''> */}
        <div className={cls.row}>
          <Controller
            name='text'
            defaultValue={chatMessage.content}
            control={control}
            render={({
              field: { onChange, onBlur, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <TextareaInput
                label='Comment'
                defaultValue={chatMessage.content}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                isTouched={isTouched}
                isDirty={isDirty}
                error={error}
                rows={5}
                backgroundColor='#0d1118'
              />
              // <FormInput
              //   type='text'
              //   label='Comment'
              //   defaultValue={chatMessage.content}
              //   onChange={onChange}
              //   onBlur={onBlur}
              //   inputRef={ref}
              //   isTouched={isTouched}
              //   isDirty={isDirty}
              //   error={error}
              // />
            )}
          />
        </div>

        {/* </form> */}

        <div className={cls.actions}>
          <button onClick={onDeny}>Back</button>
          <button onClick={handleSubmit(onSubmit)}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default EditComment;
