import cls from "./Help.module.scss";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import AppHeader from "../../components/appHeader/AppHeader";
import React, { useContext, useEffect, useState } from "react";
import { CollapsedSidebarContext } from "../../context/collapsedSidebarContext";
import HelpTabPanel from "../../components/helpTabPanel/HelpTabPanel";
import TicketTabPanel from "../../components/ticketTabPanel/TicketTabPanel";
import SearchInput from "../../components/common/searchInput/SearchInput";
import {
  useHelpQuestionsCategories,
  useSupportDepartments,
  useSupportTickets,
} from "../../api/support/support";
import Loading from "../../components/common/loading/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../api/user/user";

const Help: React.FC = () => {
  const { isLoading: isLoadingUser, data: user } = useUser();
  const { isLoading: isLoadingFaqCategories, data: faqCategories } =
    useHelpQuestionsCategories();

  const { isLoading: isLoadingTickets, data: tickets } = useSupportTickets();
  const { isLoading: isLoadingSupportDepartments, data: supportDepartments } =
    useSupportDepartments();

  const location = useLocation();
  const navigate = useNavigate();

  const { collapsed } = useContext(CollapsedSidebarContext);

  const [filter, setFilter] = useState<string>("");

  const handleFilterTickets = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const text = e.target.value;
    setFilter(text);
  };

  const handleClickTab = (index: number) => {
    navigate(index === 0 ? "/help" : "/help/tickets");
    setFilter("");
  };

  return (
    <div className={cls.root}>
      <SidebarMenu />
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {(isLoadingFaqCategories ||
            isLoadingTickets ||
            isLoadingUser ||
            isLoadingSupportDepartments) && <Loading />}

          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Help & Getting started</div>
              <div className={cls.subtitle}>
                Frequently asked questions and support tickets
              </div>
            </div>

            {location.pathname.includes("tickets") && (
              <SearchInput
                customPlaceholder='Search Tickets'
                onValueChange={handleFilterTickets}
                width={320}
                height={55}
                value={filter}
              />
            )}
          </div>

          <div className={cls.contents}>
            <div className={cls.tabs}>
              <div
                onClick={() => handleClickTab(0)}
                className={`${cls.tab} ${
                  !location.pathname.includes("tickets") ? cls.active : ""
                }`}>
                FAQ
              </div>
              <div
                onClick={() => handleClickTab(1)}
                className={`${cls.tab} ${
                  location.pathname.includes("tickets") ? cls.active : ""
                }`}>
                Support tickets
              </div>
            </div>

            {faqCategories && !location.pathname.includes("tickets") && (
              <HelpTabPanel faqCategories={faqCategories} filter={filter} />
            )}
            {tickets &&
              supportDepartments &&
              location.pathname.includes("tickets") && (
                <TicketTabPanel
                  filter={filter}
                  tickets={tickets}
                  supportDepartments={supportDepartments}
                  user={user}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
