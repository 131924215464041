import cls from "./TextareaInput.module.scss";
import { FieldError } from "react-hook-form";

type Props = {
  label?: string;
  width?: number;
  height?: number;
  backgroundColor?: string;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  cols?: number;
  rows?: number;
  inputRef?: React.Ref<HTMLTextAreaElement>;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  maxLength?: number;
  isTouched?: boolean;
  isDirty?: boolean;
  error?: FieldError | undefined;
};

const TextareaInput: React.FC<Props> = ({
  width,
  height,
  backgroundColor,
  defaultValue,
  label,
  placeholder,
  disabled,
  rows,
  cols,
  inputRef,
  onChange,
  onBlur,
  maxLength,
  isTouched,
  isDirty,
  error,
}) => {
  return (
    <div className={cls.root}>
      {!placeholder && <label className={cls.label}>{label}</label>}

      <textarea
        style={{
          width: width,
          height: height,
          backgroundColor: backgroundColor,
        }}
        maxLength={maxLength}
        ref={inputRef}
        defaultValue={defaultValue}
        className={cls.input}
        disabled={disabled}
        cols={cols || 1}
        rows={rows || 7}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />

      <p className={cls.error}>{`${error ? error.message : ""}`}</p>
    </div>
  );
};

export default TextareaInput;
