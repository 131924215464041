// import { useUser } from "../../../api/user/user";
import cls from "./ProfileImage.module.scss";

type Props = {
  size?: number;
  rounded?: boolean;
  imgUrl: string | null;
};

const ProfileImage: React.FC<Props> = ({ size, rounded, imgUrl }) => {
  return (
    <div className={cls.root}>
      <img
        src={imgUrl || "/user-avatar.webp"}
        alt=''
        className={`${rounded ? cls.rounded : ""}`}
        style={{ width: size, height: size }}
      />
    </div>
  );
};

export default ProfileImage;
