import cls from "./ForgotPasswordForm.module.scss";
import * as yup from "yup";
import { useForm, Controller, set } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../api/firebase";

// import { useNavigate } from "react-router-dom";

import FormInput from "../../common/formInput/FormInput";
import MainBtn from "../../common/buttons/mainBtn/MainBtn";
import { useState } from "react";

type FormInputs = {
  email: string;
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Not a valid email address!")
    .required("Email is required!"),
});

const ForgotPasswordForm: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [resetMessage, setResetMessage] = useState<string>(" ");

  ///////////////// handle submit form

  const { handleSubmit, control } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormInputs) => {
    console.log(data);
    setResetMessage(" ");
    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, data.email);
      // Password reset email sent successfully
      // Display a success message to the user
      setResetMessage("Reset email sent successfully!");
      setLoading(false);
    } catch (error) {
      // Handle error
      console.error(error);
      // Display an error message to the user
      setResetMessage("Something went wrong. Please try again later.");
      setLoading(false);
    }
  };

  return (
    <div className={cls.root}>
      {/* {isLoading && <Loading />} */}

      <div className={cls.title}>Did You Forgot Your Password ?</div>
      <div className={cls.subtitle}>
        Enter your email you used when you joined and we’ll send you
        instructions to reset passwords.
      </div>
      <form>
        <div className={cls.row}>
          <Controller
            name='email'
            defaultValue=''
            control={control}
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <FormInput
                type='email'
                label='Email'
                defaultValue=''
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                isTouched={isTouched}
                isDirty={isDirty}
                error={error}
              />
            )}
          />
        </div>

        <div className={cls.row}>
          <p
            style={{
              color: "#c8d5c8",
              fontSize: "1.2rem",
              textTransform: "uppercase",
            }}>
            {" "}
            {resetMessage}{" "}
          </p>
        </div>

        {/* <p className={cls.error}> {error && error} </p> */}
        <div className={`${cls.row} ${cls.actions}`}>
          <MainBtn
            disabled={loading}
            label={
              loading ? "Sending reset email...." : "Send Reset Instructions"
            }
            width={230}
            height={44}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
