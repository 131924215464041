import cls from "./GenderSelect.module.scss";
import { useState } from "react";

type Props = {
  width?: number;
  height?: number;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const GenderSelect: React.FC<Props> = ({
  width,
  height,
  onChange,
  defaultValue,
}) => {
  const [currentVal, setCurrentVal] = useState<string | undefined>(
    defaultValue
  );

  const isChecked = (val: string) => {
    return currentVal ? val === currentVal : false;
  };

  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      <div className={cls.label}> Gender</div>
      <div className={cls.field}>
        <label>Male</label>
        <input
          name='gender'
          type='radio'
          value='male'
          className={`${isChecked("male") ? cls.active : ""}`}
          onClick={() => setCurrentVal("male")}
          onChange={onChange}
        />
      </div>

      <div className={cls.field}>
        <label>Female</label>
        <input
          name='gender'
          type='radio'
          value='female'
          className={`${isChecked("female") ? cls.active : ""}`}
          onClick={() => setCurrentVal("female")}
          onChange={onChange}
        />
      </div>
      <div className={cls.field}>
        <label>Other</label>
        <input
          name='gender'
          type='radio'
          value='other'
          className={`${isChecked("other") ? cls.active : ""}`}
          onClick={() => setCurrentVal("other")}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default GenderSelect;
