import { QueryFunctionContext, useQuery, useQueryClient } from "react-query";
import { ApiEndpoints } from "../../api/endpoints";
import { requestApi } from "../../api/requestApi";
import { ITerm } from "../../interfaces/ITerm";
import { IVideo } from "../../interfaces/IVideo";

import { cacheTimes } from "../cacheTimesRQ";
import { IWeek } from "../../interfaces/IWeek";

/// courses
export const fetchCourses = () => {
  return requestApi({
    url: ApiEndpoints.ENROLLED_COURSES_PREVIEW,
    method: "get",
  });
};
export const useCourses = () => {
  const queryClient = useQueryClient();
  return useQuery(["enrolled-courses"], fetchCourses, {
    cacheTime: cacheTimes.ENROLLED_COURSES_CACHE,
    staleTime: cacheTimes.ENROLLED_COURSES_STALE,
    initialData: queryClient.getQueryData("enrolled-courses"),
    refetchOnWindowFocus: true,
    select: (data) => data.data.enrolledCourses,
    onError: (err) => {
      console.log(err);
    },
  });
};

export const fetchCourse = ({ queryKey }: QueryFunctionContext) => {
  const id: string = queryKey[1] as string;
  return requestApi({
    url: ApiEndpoints.GET_COURSE,
    method: "post",
    data: { courseId: id },
  });
};

export const useCourse = (courseId: string, enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["course", courseId], fetchCourse, {
    enabled: enabled,
    refetchOnWindowFocus: false,
    cacheTime: cacheTimes.COURSE_DATA_CACHE,
    staleTime: cacheTimes.COURSE_DATA_STALE,
    initialData: queryClient.getQueryData(["course", courseId]),
    select: (data) => data.data.course,
    onError: (err) => {
      console.log(err);
    },
  });
};

////// terms
export const fetchTerms = ({ queryKey }: QueryFunctionContext) => {
  const courseId: string = queryKey[1] as string;
  return requestApi({
    url: ApiEndpoints.MODULES_FOR_COURSE,
    method: "post",
    data: { courseId: courseId },
  });
};

export const useTerms = (courseId: string, enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["terms-for-course", courseId], fetchTerms, {
    refetchOnWindowFocus: false,
    enabled: enabled,
    cacheTime: cacheTimes.COURSE_TERMS_CACHE,
    staleTime: cacheTimes.COURSE_TERMS_STALE,
    initialData: queryClient.getQueryData(["terms-for-course", courseId]),
    select: (data) =>
      data.data.modules.sort(
        (a: ITerm, b: ITerm) => a.orderIndex - b.orderIndex
      ),
    onError: (err) => {
      console.log(err);
    },
  });
};
////// weeeks
export const fetchWeeks = ({ queryKey }: QueryFunctionContext) => {
  const courseId: string = queryKey[1] as string;
  const termId: string = queryKey[2] as string;
  return requestApi({
    url: ApiEndpoints.GET_WEEKS,
    method: "post",
    data: { courseId: courseId, termId: termId },
  });
};
export const useWeeks = (
  courseId: string,
  termId: string,
  enabled?: boolean
) => {
  const queryClient = useQueryClient();
  return useQuery(["weeks", courseId, termId], fetchWeeks, {
    refetchOnWindowFocus: false,
    enabled: enabled,
    cacheTime: cacheTimes.WEEK_LIST_CACHE,
    staleTime: cacheTimes.WEEK_LIST_STALE,
    initialData: queryClient.getQueryData(["weeks", courseId, termId]),
    select: (data) =>
      data.data.weeks.sort((a: IWeek, b: IWeek) => a.orderIndex - b.orderIndex),
    onError: (err) => {
      console.log(err);
    },
  });
};

/////// videos
export const fetchVideos = ({ queryKey }: QueryFunctionContext) => {
  const courseId: string = queryKey[1] as string;
  const termId: string = queryKey[2] as string;
  const weekId: string = queryKey[3] as string;

  return requestApi({
    url: ApiEndpoints.GET_VIDEOS,
    method: "post",
    data: { courseId, termId, weekId },
  });
};

export const useVideos = (
  courseId: string | undefined,
  termId: string | undefined,
  weekId: string | undefined,
  enabled?: boolean
) => {
  // if (!courseId || !termId || !weekId) return;
  const queryClient = useQueryClient();
  return useQuery(["videos", courseId, termId, weekId], fetchVideos, {
    enabled: enabled,
    cacheTime: cacheTimes.VIDEO_LIST_CACHE,
    staleTime: cacheTimes.VIDEO_LIST_STALE,
    refetchOnWindowFocus: false,
    initialData: queryClient.getQueryData(["videos", courseId, termId, weekId]),
    select: (data) =>
      data.data.videos.sort(
        (a: IVideo, b: IVideo) => a.orderIndex - b.orderIndex
      ),
    onError: (err) => {
      console.log(err);
    },
  });
};

/////// course schedules
export const fetchCourseSchedules = () => {
  return requestApi({
    url: ApiEndpoints.GET_SCHEDULED_COURSES,
    method: "get",
  });
};
export const useCourseSchedules = () => {
  const queryClient = useQueryClient();
  return useQuery(["course-schedules"], fetchCourseSchedules, {
    cacheTime: cacheTimes.COURSE_SCHEDULES_CACHE,
    staleTime: cacheTimes.COURSE_SCHEDULES_STALE,
    initialData: queryClient.getQueryData("course-schedules"),
    refetchOnWindowFocus: true,
    select: (data) => data.data.schedules,
    onError: (err) => {
      console.log(err);
    },
  });
};
