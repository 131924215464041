import cls from "./WeekList.module.scss";

import { useParams } from "react-router-dom";
import { UrlParams } from "../../../interfaces/UrlParams";
import { IWeek } from "../../../interfaces/IWeek";
import { useVideos, useWeeks } from "../../../api/course/course";

type Props = {
  _termId: string;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const WeekList: React.FC<Props> = ({ _termId, onSelect }) => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, weekId } = routeParameters;
  const { data: weeksData } = useWeeks(courseId, _termId);

  const WeekItem: React.FC<{ week: IWeek }> = ({ week }) => {
    const { data: videosData } = useVideos(courseId, _termId, week.id);

    if (videosData?.length === 0) return null;

    const firstVideoId: string = videosData?.[0].id;

    // if (!firstVideoId) return null;

    return (
      <div className={cls.option}>
        <input
          name='select'
          type='radio'
          value={`${_termId}/${week.id}/${firstVideoId}`}
          id={week.title}
          checked={weekId === week.id}
          onChange={onSelect}
        />
        <label htmlFor={week.title}>- {week.title}</label>
      </div>
    );
  };

  return (
    <div className={cls.root}>
      {weeksData?.map((week: IWeek, index: number) => {
        return <WeekItem key={index} week={week} />;
      })}
    </div>
  );
};

export default WeekList;
