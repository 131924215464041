import {
  collection,
  CollectionReference,
  DocumentSnapshot,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  Query,
  QuerySnapshot,
  startAt,
} from "firebase/firestore";

import { firestore } from "../firebase";
import { ApiEndpoints } from "../endpoints";

import { IChatMessage } from "../../interfaces/IChatMessage";
import { fetchUser } from "../user/user";

import { GetUserId } from "../firebase";

import { FilterDuplicateElements } from "../../utils/common";
import { Dispatcher } from "../../utils/dispatcher";
import { requestApi } from "../requestApi";

export enum EMessageCallbackType {
  OLDER = "OLDER",
  NEWER = "NEWER",
  EXISTING = "EXISTING",
}

export type ChatMessageCallback = {
  messages: IChatMessage[];
  type: EMessageCallbackType;
  numberOfMessagesAdded: number;
};

export interface IChatService {
  SubscribeForMessages(
    callback: (value: ChatMessageCallback) => unknown
  ): () => void;

  SubmitMessage(messageContent: string): Promise<{ id: string }>;

  Initialize(): Promise<void>;

  SetLastMessageRead(message: IChatMessage): void;

  GetLastMessage(): IChatMessage | null;

  GetNumberOfUnreadMessages(): number;

  Destruct(): void;

  LoadPreviousMessagesEventSafe(): void;

  LoadNextMessagesEventSafe(): void;
}

class ChatService implements IChatService {
  private readonly messageEventDispatcher: Dispatcher<ChatMessageCallback> =
    new Dispatcher();
  private readonly MESSAGE_PAGE_SIZE: number = 100;

  private isInitialized: boolean = false;
  private messages: IChatMessage[] = [];
  private lastMessageRead: { messageId: string; timestamp: number } | null =
    null;

  private lastPreviousMessageDocumentOrTimestamp: DocumentSnapshot | number = 0;
  private lastNextMessageDocumentOrTimestamp: DocumentSnapshot | number = 0;
  private databaseListenerUnsubscribe: (() => void) | null = null;
  private getPreviousDocsPromise: Promise<void> | null = null;
  private getNextDocsPromise: Promise<void> | null = null;
  private currentSnapshotDocumentsRead: number = 0;

  constructor(
    private readonly chatCollection: CollectionReference,
    private readonly postMessageRoute: ApiEndpoints,
    private readonly chatId?: string
  ) {}

  public async Initialize(): Promise<void> {
    if (this.isInitialized) return;

    const lastMessageSaved:
      | { messageId: string; timestamp: number }
      | undefined = (await fetchUser()).data.user.messageHistory[
      this.chatId || "global"
    ];

    this.lastMessageRead = lastMessageSaved || null;
    this.lastPreviousMessageDocumentOrTimestamp =
      lastMessageSaved?.timestamp || 0;
    this.lastNextMessageDocumentOrTimestamp = lastMessageSaved?.timestamp || 0;

    this.isInitialized = true;
  }

  public LoadPreviousMessagesEventSafe() {
    if (this.getPreviousDocsPromise) return;

    this.getPreviousDocsPromise = this.LoadMorePreviousMessages();
  }

  public LoadNextMessagesEventSafe() {
    if (this.getNextDocsPromise) return;

    this.getNextDocsPromise = this.LoadMoreNewMessages();
  }

  public Destruct(): void {
    this.databaseListenerUnsubscribe?.();
    this.databaseListenerUnsubscribe = null;

    this.messages = [];
    this.isInitialized = false;
  }

  public SubscribeForMessages(
    callback: (value: ChatMessageCallback) => unknown
  ): () => void {
    const unsubscribeHandler: () => void =
      this.messageEventDispatcher.Subscribe(callback);

    this.messageEventDispatcher.Dispatch({
      messages: this.messages,
      type: EMessageCallbackType.EXISTING,
      numberOfMessagesAdded: this.messages.length,
    });

    return unsubscribeHandler;
  }

  public GetLastMessage(): IChatMessage | null {
    return this.messages[this.messages.length - 1] || null;
  }
  //////////////////
  public async SetLastMessageRead(message: IChatMessage): Promise<void> {
    // console.log("HERE message", message);
    if (this.lastMessageRead?.messageId === message.id) return;

    this.lastMessageRead = {
      messageId: message.id,
      timestamp: Date.now(),
    };

    const user = await fetchUser();

    user.data.user.messageHistory[this.chatId || "global"] =
      this.lastMessageRead;

    const output = await requestApi({
      url: ApiEndpoints.SET_CHAT_LAST_MESSAGE,
      method: "post",
      data: {
        messageHistory: this.lastMessageRead,
        chatId: this.chatId || "global",
      },
    });

    return output.data;
  }

  public GetNumberOfUnreadMessages(): number {
    if (!this.lastMessageRead) return this.messages.length;

    const numberOfUnreadMessages: number =
      this.messages.length -
      this.messages.findIndex(
        (message: IChatMessage) =>
          message.id === this.lastMessageRead?.messageId
      ) -
      1;

    return numberOfUnreadMessages;
  }
  /////////////////////
  public async SubmitMessage(messageContent: string): Promise<{ id: string }> {
    console.log("HERE messageContent", messageContent);
    console.log("HERE this.chatId", this.chatId);
    const newMessage: IChatMessage = {
      content: messageContent,
      userId: GetUserId() as string,
      unixTimestamp: new Date().getTime(),
      id: "",
    };

    const result = await requestApi({
      url: this.postMessageRoute,
      method: "post",
      data: {
        messageContent,
        chatId: this.chatId,
      },
    });

    if (result.status !== 200) return { id: "" };

    newMessage.id = result.data.id || "";

    this.lastMessageRead = {
      messageId: newMessage.id,
      timestamp: Date.now(),
    };

    return result.data;
  }

  private async LoadMoreNewMessages() {
    if (!this.isInitialized) await this.Initialize();

    const q: Query = query(
      this.chatCollection,
      orderBy("unixTimestamp", "asc"),
      startAt(this.lastNextMessageDocumentOrTimestamp),
      limit(this.MESSAGE_PAGE_SIZE)
    );

    await new Promise<void>((resolve) => {
      const unsub: () => void = onSnapshot(q, (snapshot: QuerySnapshot) => {
        const newMessagesLength: number = snapshot.docs.length;

        this.onSnapshot(snapshot, EMessageCallbackType.NEWER);

        this.currentSnapshotDocumentsRead += newMessagesLength;

        if (this.currentSnapshotDocumentsRead >= this.MESSAGE_PAGE_SIZE) {
          this.lastNextMessageDocumentOrTimestamp =
            snapshot.docs[newMessagesLength - 1] ||
            this.lastMessageRead?.timestamp ||
            0;

          this.currentSnapshotDocumentsRead = 0;
          unsub();
          resolve();
        }
      });
    });

    this.getNextDocsPromise = null;
  }

  private async LoadMorePreviousMessages() {
    if (!this.isInitialized) await this.Initialize();

    const q: Query = query(
      this.chatCollection,
      orderBy("unixTimestamp", "desc"),
      startAt(this.lastPreviousMessageDocumentOrTimestamp),
      limit(this.MESSAGE_PAGE_SIZE)
    );

    const snapshot: QuerySnapshot = await getDocs(q);

    if (snapshot.empty) return;

    this.lastPreviousMessageDocumentOrTimestamp =
      snapshot.docs[snapshot.docs.length - 1];

    this.onSnapshot(snapshot, EMessageCallbackType.OLDER);
    this.getPreviousDocsPromise = null;
  }

  private onSnapshot = (
    snapshot: QuerySnapshot,
    type: EMessageCallbackType
  ) => {
    const newMessages: IChatMessage[] = snapshot.docs.map(
      (document: DocumentSnapshot) => document.data() as IChatMessage
    );

    const oldNumberOfMessages: number = this.messages.length;
    this.messages.push(...newMessages);

    this.messages = FilterDuplicateElements(this.messages, "id").sort(
      (a, b) => a.unixTimestamp - b.unixTimestamp
    );

    this.messageEventDispatcher.Dispatch({
      messages: this.messages,
      type,
      numberOfMessagesAdded: this.messages.length - oldNumberOfMessages,
    });
  };
}

export const GlobalChatService: IChatService = new ChatService(
  collection(firestore, "GlobalChat"),
  ApiEndpoints.ADD_MESSAGE_CHAT
);

const chatServices: Record<string, IChatService> = {};

export async function GetChatServiceForId(id: string) {
  if (chatServices[id]) return chatServices[id];

  const newChatService: IChatService = new ChatService(
    collection(firestore, `Chat/${id}/Messages`),
    ApiEndpoints.ADD_MESSAGE_CHAT,
    id
  );

  await newChatService.Initialize();

  chatServices[id] = newChatService;

  return newChatService;
}
