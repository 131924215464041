import cls from "./CourseSelector.module.scss";

import React, { useEffect, useRef, useState } from "react";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import Icon from "../../../assets/icons/Icon";
import { useCourses } from "../../../api/course/course";
import { ICourse } from "../../../interfaces/ICourse";
import { useEnrolledClasses } from "../../../api/class/class";
import { IClass } from "../../../interfaces/IClass";
import { useNavigate, useParams } from "react-router-dom";
import { UrlParams } from "../../../interfaces/UrlParams";
// import { IProgress } from "../../../interfaces/IProgress";
import { useQueryClient } from "react-query";
import { requestApi } from "../../../api/requestApi";
import { ApiEndpoints } from "../../../api/endpoints";
// import { useUserProgress } from "../../../api/user/user";
// import { ProgressTitles } from "../../../interfaces/IProgress";
// import Loading from "../../common/loading/Loading";

const CourseSelector: React.FC = () => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId } = routeParameters;

  const { data: courses } = useCourses();
  const { data: enrolledClasses } = useEnrolledClasses();

  // const { data: userProgress } = useUserProgress();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const refField = useRef(null);
  const [courseList, showCourseList] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<string>();

  const getPathToLecture = async (selectedCourseId: string) => {
    // const courseProgress = userProgress?.find(
    //   (c: ProgressTitles) => c.courseId === selectedCourseId
    // );
    const classByCourseId: IClass = enrolledClasses?.find(
      (c: IClass) => c.courseId === selectedCourseId
    );

    if (!classByCourseId) return;

    // if (courseProgress)
    //   return `/course-overview/${courseProgress.courseId}/${classByCourseId.id}/${courseProgress.termId}/${courseProgress.lectureId}/${courseProgress.videoId}`;

    let firstTermId: string = "";

    const cacheTerms: any = queryClient.getQueryData([
      "terms-for-course",
      selectedCourseId,
    ]);

    if (cacheTerms) {
      firstTermId = cacheTerms?.data.modules[0].id;
    } else {
      const termsData = await requestApi({
        url: ApiEndpoints.MODULES_FOR_COURSE,
        method: "post",
        data: { courseId: selectedCourseId },
      });
      queryClient.setQueryData(
        ["terms-for-course", selectedCourseId],
        termsData
      );
      console.log(termsData);
      firstTermId = termsData.data.modules[0].id;
    }
    //////////////
    let firstWeekId: string = "";

    const cacheWeeks: any = queryClient.getQueryData([
      "weeks",
      selectedCourseId,
      firstTermId,
    ]);

    if (cacheWeeks) {
      firstWeekId = cacheWeeks?.data.weeks[0].id;
    } else {
      const weeksData = await requestApi({
        url: ApiEndpoints.GET_WEEKS,
        method: "post",
        data: { courseId: selectedCourseId, termId: firstTermId },
      });
      queryClient.setQueryData(
        ["weeks", selectedCourseId, firstTermId],
        weeksData
      );
      firstWeekId = weeksData.data.weeks[0].id;
    }

    //////////////
    let firstVideoId: string = "";

    const cacheVideos: any = queryClient.getQueryData([
      "videos",
      selectedCourseId,
      firstTermId,
      firstWeekId,
    ]);

    if (cacheVideos) {
      firstVideoId = cacheVideos?.data.videos[0].id;
    } else {
      const videosData = await requestApi({
        url: ApiEndpoints.GET_VIDEOS,
        method: "post",
        data: {
          courseId: selectedCourseId,
          termId: firstTermId,
          weekId: firstWeekId,
        },
      });
      queryClient.setQueryData(
        ["videos", selectedCourseId, firstTermId, firstWeekId],
        videosData
      );
      firstVideoId = videosData.data.videos[0].id;
    }

    if (
      !selectedCourseId ||
      !classByCourseId.id ||
      !firstTermId ||
      !firstWeekId ||
      !firstVideoId
    )
      return;

    return `/course-overview/${selectedCourseId}/${classByCourseId.id}/${firstTermId}/${firstWeekId}/${firstVideoId}`;
  };

  const handleClickOutsideMenu = (e: MouseEvent) => {
    showCourseList(false);
  };
  useOnClickOutside(refField, handleClickOutsideMenu);

  /////
  const handleShowCategoryList = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    showCourseList(!courseList);
  };
  /////
  const handleSelectCourse = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // e.preventDefault();
    const val = e.target.value;
    const courseId = e.currentTarget.id;
    setSelectedCourse(val);
    showCourseList(false);

    const path = await getPathToLecture(courseId);
    if (!path) return;
    navigate(path);
  };

  const getCourseByClass = (courseIdFromCLass: string) => {
    const courseByClass: ICourse | undefined = courses?.find(
      (course: ICourse) => course.id === courseIdFromCLass
    );

    return {
      courseId: courseByClass ? courseByClass["id"] : "",
      courseTitle: courseByClass ? courseByClass["title"] : "",
    };
  };

  useEffect(() => {
    const courseByClass = courses?.find(
      (course: ICourse) => course.id === courseId
    );
    if (courseByClass) {
      setSelectedCourse(courseByClass["title"]);
    }

    // setSelectedCourse(
    //   courses?.find((course: ICourse) => course.id === courseId).title
    // );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  return (
    <div className={cls.root} ref={refField}>
      <div
        className={`${cls.select} ${courseList ? cls.show : ""}`}
        onClick={handleShowCategoryList}>
        <Icon
          icon='ArrowLeft'
          viewBox='0 0 320 512'
          size={18}
          className={courseList ? cls.expanded : ""}
        />
        <label className={cls.label}>Select Course</label>
        <div className={cls.value}>{selectedCourse}</div>
      </div>

      <div className={`${cls.options} ${courseList ? cls.show : ""}`}>
        {enrolledClasses?.map((cl: IClass, index: number) => {
          return (
            <div key={index} className={cls.option}>
              <input
                name='select'
                type='radio'
                value={getCourseByClass(cl.courseId).courseTitle}
                id={getCourseByClass(cl.courseId).courseId}
                onChange={handleSelectCourse}
              />
              <label htmlFor={getCourseByClass(cl.courseId).courseId}>
                {getCourseByClass(cl.courseId).courseTitle}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CourseSelector;
