import cls from "./UserSettings.module.scss";
import MainBtn from "../common/buttons/mainBtn/MainBtn";
import React, { useContext, useEffect, useState } from "react";
import { Birthday, IUser } from "../../interfaces/IUser";
import Loading from "../common/loading/Loading";
// import DatePicker from "../common/datePicker/DatePicker";
import { updatePassword } from "firebase/auth";
import { auth } from "../../api/firebase";
import { useMutation, useQueryClient } from "react-query";
import {
  updateUserAccount,
  updateUserNotificationSettings,
} from "../../api/user/user";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "../common/formInput/FormInput";
import { useNavigate } from "react-router-dom";
import GenderSelect from "../userProfile/genderSelect/GenderSelect";
import ProfilePicture from "../userProfile/picture/ProfilePicture";
import ConfirmationPopUp from "../common/confirmationPopUp/ConfirmationPopUp";
import LmsDatePicker from "../common/lmsDatePicker/LmsDatePicker";
import { AuthContext } from "../../context/authContext";
import { ReloadFirebaseUserToken } from "../../api/firebase";

type Props = {
  user: IUser;
};
type FormInputs = {
  firstName: string;
  lastName: string;
  birthday: string;
  gender: string;
  phone: string;
  oldPassword: string | null;
  newPassword: string | null;
};

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required!")
    .max(20, "No more than 20 characters are allowed!"),
  lastName: yup
    .string()
    .required("Last name is required!")
    .max(20, "No more than 20 characters are allowed!"),
  birthday: yup.string(),
  gender: yup.string(),
  phone: yup
    .string()
    .matches(/^(\+?\d*\s*)*\d+$/, { message: "Not a valid phone number!" })
    .max(40, "No more than 40 characters are allowed!"),
  oldPassword: yup
    .string()
    .nullable()
    .notRequired()
    .min(6, "Password must be at least 6 characters long!")
    .max(40, "No more than 40 characters are allowed!"),
  newPassword: yup
    .string()
    .nullable()
    .notRequired()
    .min(6, "Password must be at least 6 characters long!")
    .max(40, "No more than 40 characters are allowed!"),
});

const UserSettings: React.FC<Props> = ({ user }) => {
  const [newPasswordMessage, setNewPasswordMessage] = useState<string>(" ");

  const authInstance = useContext(AuthContext);

  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const [notifications, setNotifications] = useState<boolean>(
    !authInstance?.notificationsDisabled
  );

  /////////////// POPUPS ////////////////

  const [
    successConfirmationOnNotifications,
    showSuccessConfirmationOnNotifications,
  ] = useState<boolean>(false);

  const [successConfirmationUpdated, showSuccessConfirmationUpdated] =
    useState<boolean>(false);
  const [errorConfirmation, showErrorConfirmation] = useState<boolean>(false);

  ///////////////// format date
  const getDateValue = (birthday: Birthday | undefined): string => {
    let result: string = "";
    if (birthday) {
      const { year, month, day } = birthday;
      const targetMonth: string | number = month > 9 ? month : `0${month}`;
      const targetDay: string | number = day > 9 ? day : `0${day}`;
      const date: Date = new Date(year, month - 1, day);
      result = `${date.getFullYear()}-${targetMonth}-${targetDay}`;
    }

    return result;
  };
  const formatDatePayload = (date: string) => {
    if (date) {
      const splitString = date.split("-");
      let result = {
        year: parseInt(splitString[0]),
        month: parseInt(splitString[1]),
        day: parseInt(splitString[2]),
      };

      return result;
    }

    return;
  };

  const handleUpdateNotificationSettings = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // e.preventDefault();
    // console.log("update notification settings", e.target.checked);

    try {
      setIsLoading(true);
      await updateUserNotificationSettings(notifications);

      // refresh firebase token - on refresh => to update firebase user claims (notificationDisabled state)
      await ReloadFirebaseUserToken();

      setNotifications(!notifications);

      showSuccessConfirmationOnNotifications(true);
    } catch (err) {
      console.log(err);
      showErrorConfirmation(true);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserRQ = useMutation(updateUserAccount, {
    onMutate: () => setIsLoading(true),
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("user-data");
      showSuccessConfirmationUpdated(true);
    },
    onError: (err) => {
      console.log("error user updated", err);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  ///////////////// handle submit form
  const { handleSubmit, control, formState } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (data: FormInputs) => {
    // console.log(data);
    setNewPasswordMessage(" ");
    if (formState.isDirty)
      updateUserRQ.mutate({
        firstName: data.firstName,
        lastName: data.lastName,
        gender: data.gender,
        birthday: formatDatePayload(data.birthday),
        phoneNumber: data.phone,
      });

    if (data.newPassword) {
      const user = auth.currentUser;
      if (user)
        updatePassword(user, data.newPassword)
          .then(() => {
            setNewPasswordMessage(
              "Password updated successfully! PLease login again with the new password."
            );
          })
          .catch((error) => {
            console.log(error.message);
            setNewPasswordMessage(
              "Something went wrong while reseting your password. Reason: 'Password may have been used in the past'. Please try again later."
            );
          });
    }
  };

  return (
    <div className={cls.root}>
      {isLoading && <Loading />}

      {successConfirmationUpdated && (
        <ConfirmationPopUp
          maxWidth={400}
          type='success'
          message='Account successfully updated!'
          onClick={() => showSuccessConfirmationUpdated(false)}
        />
      )}

      {successConfirmationOnNotifications && (
        <ConfirmationPopUp
          maxWidth={400}
          type='success'
          message='Notifications settings successfully updated!'
          subText='* When disabled you will only receive notifications from the system admin'
          onClick={() => [
            authInstance?.updateNotificationsDisabled(!notifications),
            showSuccessConfirmationOnNotifications(false),
          ]}
        />
      )}

      {errorConfirmation && (
        <ConfirmationPopUp
          maxWidth={400}
          type='error'
          message='Could not update the account!'
          onClick={() => showErrorConfirmation(false)}
        />
      )}

      <div className={cls.form}>
        <ProfilePicture user={user} />

        <form action=''>
          <div className={cls.row}>
            <div className={cls.fields}>
              <div className={cls.field}>
                <Controller
                  name='firstName'
                  defaultValue={user.firstName}
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    <FormInput
                      type='text'
                      label='First Name'
                      maxLength={20}
                      defaultValue={user.firstName}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      isTouched={isTouched}
                      isDirty={isDirty}
                      error={error}
                    />
                  )}
                />
              </div>

              <div className={cls.field}>
                <Controller
                  name='lastName'
                  defaultValue={user.lastName}
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    <FormInput
                      type='text'
                      label='Last Name'
                      maxLength={20}
                      defaultValue={user.lastName}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      isTouched={isTouched}
                      isDirty={isDirty}
                      error={error}
                    />
                  )}
                />
              </div>

              <div className={cls.field}>
                <Controller
                  name='birthday'
                  defaultValue={getDateValue(user.birthday)}
                  control={control}
                  render={({
                    field: { onChange, onBlur, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    // <DatePicker
                    //   label='Birthday'
                    //   defaultValue={getDateValue(user.birthday)}
                    //   onChange={onChange}
                    //   onBlur={onBlur}
                    //   inputRef={ref}
                    //   isTouched={isTouched}
                    //   isDirty={isDirty}
                    //   error={error}
                    // />
                    <LmsDatePicker
                      label='Birthday'
                      defaultValue={getDateValue(user.birthday)}
                      maxDate={new Date()}
                      // onChange={onChange}
                      onChange={(date) => {
                        // console.log(date);
                        return onChange(
                          `${date.year}-${date.month}-${date.day}`
                        );
                      }}
                      // onBlur={onBlur}
                      // inputRef={ref}
                      // isTouched={isTouched}
                      isDirty={isDirty}
                      error={error}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className={cls.row}>
            <Controller
              name='gender'
              defaultValue={user.gender}
              control={control}
              render={({ field: { onChange } }) => (
                <GenderSelect onChange={onChange} defaultValue={user.gender} />
              )}
            />
          </div>

          <div className={cls.row}>
            <div className={cls.title}>Notifications</div>

            <label className={cls.toggleNotifications} htmlFor='toggle'>
              <div
                className={`${cls.slider} ${cls.round} ${
                  !notifications ? "" : cls.on
                }`}></div>
              <input
                type='checkbox'
                id='toggle'
                onChange={handleUpdateNotificationSettings}
                checked={notifications}
              />
              <div className={`${cls.label} ${!notifications ? "" : cls.isOn}`}>
                {!notifications ? "No" : "Yes"}
              </div>
            </label>
          </div>

          <div className={cls.row}>
            <div className={cls.title}>Contact & Billing Information</div>
            <div className={cls.fields}>
              <div className={cls.field}>
                <FormInput
                  type='email'
                  label='Email'
                  disabled={true}
                  defaultValue={user.email}
                />
              </div>
              <div className={cls.field}>
                <Controller
                  name='phone'
                  defaultValue={user.phoneNumber}
                  control={control}
                  render={({
                    field: { onChange, onBlur, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    <FormInput
                      maxLength={40}
                      type='text'
                      label='Phone Number'
                      defaultValue={user.phoneNumber}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      isTouched={isTouched}
                      isDirty={isDirty}
                      error={error}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className={cls.row}>
            <div className={cls.title}>Change Password</div>
            <div className={cls.fields}>
              <div className={cls.field}>
                <Controller
                  name='oldPassword'
                  defaultValue={null}
                  control={control}
                  rules={{ required: false }}
                  render={({
                    field: { onChange, onBlur, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    <FormInput
                      type='password'
                      label='Old Password'
                      maxLength={40}
                      defaultValue=''
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      isTouched={isTouched}
                      isDirty={isDirty}
                      error={error}
                    />
                  )}
                />
              </div>
              <div className={cls.field}>
                <Controller
                  name='newPassword'
                  defaultValue={null}
                  control={control}
                  rules={{ required: false }}
                  render={({
                    field: { onChange, onBlur, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    <FormInput
                      type='password'
                      label='New Password'
                      defaultValue=''
                      maxLength={40}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      isTouched={isTouched}
                      isDirty={isDirty}
                      error={error}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <p>{newPasswordMessage}</p>
          </div>
        </form>

        <div className={cls.actions}>
          <MainBtn
            label='Back'
            transparent={true}
            width={130}
            height={44}
            onClick={() => navigate(-1)}
          />

          <MainBtn
            disabled={!formState.isDirty}
            label='Save Changes'
            width={180}
            height={44}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
