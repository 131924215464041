import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore";
import { FirebaseStorage, getStorage } from "firebase/storage";

const firebaseConfig: Record<string, string> = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
  appId: process.env.REACT_APP_FIREBASE_APP_ID!,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!,
};

const app: FirebaseApp = initializeApp(firebaseConfig);

export const storage: FirebaseStorage = getStorage(app);
export const firestore: Firestore = getFirestore(app);
export const auth: Auth = getAuth(app);

export function GetUserId(): string | undefined {
  return auth.currentUser?.uid;
}

export async function ReloadFirebaseUserToken() {
  await auth.currentUser?.getIdToken(true);
}
