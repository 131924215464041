import { useContext } from "react";
import { GlobalNotificationService } from "../../api/notifications/notificationService";
import INotification from "../../interfaces/INotification";

import NotificationItem from "../notificationItem/NotificationItem";
import "./NotificationList.scss";
import { NotificationContext } from "../../context/notificationContext";

type Props = {
  closeNotifications: () => void;
};

const NotificationList: React.FC<Props> = ({ closeNotifications }) => {
  const notificationsInstance = useContext(NotificationContext);

  const onReadStateChange = async (
    notification: INotification,
    newReadState: boolean
  ) => {
    if (notification.isRead === newReadState) return;
    await GlobalNotificationService.SetNotificationReadState(
      notification,
      newReadState
    );
  };

  if (!notificationsInstance?.notifications) return <></>;
  return (
    <div className='notifications-list'>
      {notificationsInstance.notifications.map(
        (notification: INotification, index: number) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            onReadClick={(newReadState) =>
              onReadStateChange(notification, newReadState)
            }
            onLinkClicked={closeNotifications}
          />
        )
      )}
    </div>
  );
};

export default NotificationList;
