import cls from "./DepartmentSelector.module.scss";
import React, { useRef, useState } from "react";
import { FieldError } from "react-hook-form/dist/types";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import Icon from "../../../assets/icons/Icon";
import { useSupportDepartments } from "../../../api/support/support";
import { SupportTicketCategory } from "../../../interfaces/ISupportTicket";

type Props = {
  width?: number;
  height?: number;
  defaultValue?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: FieldError | undefined;
};

const DepartmentSelector: React.FC<Props> = ({
  width,
  height,
  defaultValue,
  onChange,
  error,
}) => {
  // get support departments
  const { data: supportDepartments } = useSupportDepartments();
  const refField = useRef(null);
  const [departmentList, showDepartmentList] = useState<boolean>(false);
  const [selectedDepartment, setSelectedDepartment] = useState<string>(
    defaultValue || ""
  );

  const handleClickOutsideMenu = (e: MouseEvent) => {
    showDepartmentList(false);
  };
  useOnClickOutside(refField, handleClickOutsideMenu);

  /////
  const handleShowComponentList = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    showDepartmentList(!departmentList);
  };
  /////
  const handleSelectDepartment = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const val = e.target.value;

    setSelectedDepartment(val);
    showDepartmentList(false);
    onChange(e);
  };

  return (
    <div className={cls.root} style={{ width: width, height: height }}>
      <div className={cls.wrapper} ref={refField}>
        <div
          className={`${cls.select} ${departmentList ? cls.expanded : ""}`}
          onClick={handleShowComponentList}>
          <div className={cls.label}>Department</div>
          <Icon
            icon='ArrowLeft'
            viewBox='0 0 320 512'
            size={18}
            className={departmentList ? cls.expanded : ""}
          />

          <div className={cls.value}>{selectedDepartment}</div>
        </div>

        <div className={`${cls.options} ${departmentList ? cls.show : ""}`}>
          {supportDepartments?.map(
            (department: SupportTicketCategory, index: number) => {
              return (
                <div key={index} className={cls.option}>
                  <label htmlFor={department.id}>{department.name}</label>
                  <input
                    name='select'
                    type='radio'
                    value={department.name}
                    id={department.id}
                    onChange={handleSelectDepartment}
                  />
                </div>
              );
            }
          )}
        </div>
      </div>
      <p className={cls.error}>{`${error ? error.message : ""}`}</p>
    </div>
  );
};

export default DepartmentSelector;
