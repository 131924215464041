import cls from "./TermItem.module.scss";
import ProgressBar2 from "../../common/progressBars/progressBar2/ProgressBar2";

import WeekItem from "../weekItem/WeekItem";
import { useWeeks } from "../../../api/course/course";
import { useUser } from "../../../api/user/user";
import { useEffect, useState } from "react";
import { IWeek } from "../../../interfaces/IWeek";
import {
  IProgress,
  ProgressTitles,
  WeekProgress,
} from "../../../interfaces/IProgress";
import LazyImage from "../../common/lazyImage/LazyImage";
import { IClass } from "../../../interfaces/IClass";

type Props = {
  id: string;
  courseId: string;
  // courseTitle: string;
  enrolledClass?: IClass;
  avatar: string;
  title: string;
  subtitle: string;
  description: string;
  progress?: ProgressTitles[];
};

const TermItem: React.FC<Props> = ({
  id,
  courseId,
  enrolledClass,
  avatar,
  title,
  subtitle,
  description,
  progress,
}) => {
  // get user progress
  const { data: userData } = useUser();

  const [termProgress, setTermProgress] = useState<ProgressTitles>();
  const [lastVideoWatched, setLastVideoWatched] = useState<IProgress>();

  ///// get weeks
  const { data: weeksData } = useWeeks(courseId, id);

  useEffect(() => {
    if (progress) {
      const progressForThisTerm = progress.find(
        (progress: ProgressTitles) => progress.termId === id
      );

      setTermProgress(progressForThisTerm);
    }

    if (userData) {
      const lastVideoWatchedForThisTerm = userData.progress.find(
        (progress: IProgress) => progress.termId === id
      );

      setLastVideoWatched(lastVideoWatchedForThisTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return (
    <div className={cls.root}>
      <div className={cls.wrapper}>
        {/* {isLoadingWeeks && <Loading />} */}
        <div className={cls.avatar}>
          {/* <img src={avatar} alt='' loading='lazy' /> */}
          <LazyImage src={avatar || "/placeholder.webp"} type='term' />
        </div>
        <h5 className={cls.subtitle}>{subtitle}</h5>
        <h3 className={cls.title}>{title}</h3>
        <div className={cls.description}>{description}</div>
        <div className={cls.progress}>
          <ProgressBar2
            total={72}
            percentage={termProgress?.percentageCompleted || 0}
          />
        </div>

        <div className={cls.details}>
          <div className={cls.header}>Weeks</div>
          {weeksData &&
            weeksData.map((week: IWeek, idx: number) => {
              const weekProgress = termProgress?.lectureProgress.find(
                (wk: WeekProgress) => wk.weekId === week.id
              );
              return (
                <div key={idx} style={{ display: "block" }}>
                  <WeekItem
                    courseId={courseId}
                    enrolledClass={enrolledClass}
                    termId={id}
                    id={week.id}
                    title={week.title}
                    lastVideoWatched={
                      lastVideoWatched?.lastVideoWatched?.weekId === week.id
                        ? lastVideoWatched?.lastVideoWatched?.videoId
                        : null
                    }
                    weekProgress={weekProgress}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TermItem;
