import cls from "./ProfilePicture.module.scss";

import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { IUser } from "../../../interfaces/IUser";
import ProfileImage from "../profileImage/ProfileImage";
import StorageService from "../../../api/storage/storageService";
import { updateUserAccount } from "../../../api/user/user";
import ConfirmationPopUp from "../../common/confirmationPopUp/ConfirmationPopUp";
import CallToActionPopUp from "../../common/callToActionPopUp/CallToActionPopUp";

type Props = {
  user: IUser;
};
const ProfilePicture: React.FC<Props> = ({ user }) => {
  const queryClient = useQueryClient();

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const [successConfirmationUpdated, showSuccessConfirmationUpdated] =
    useState<boolean>(false);

  const [confirmationPopUpDelete, showConfirmationPopUpDelete] =
    useState<boolean>(false);

  const [errorConfirmation, showErrorConfirmation] = useState<boolean>(false);

  const updateUserRQ = useMutation(updateUserAccount, {
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("user-data");
      showSuccessConfirmationUpdated(true);
    },
    onError: (err) => {
      console.log("error user updated", err);
      showErrorConfirmation(true);
    },
    onSettled: () => {
      setButtonDisabled(false);
    },
  });

  // on profile picture change
  const handleChangeProfilePicture = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const file = e.target.files?.[0] || null;

    if (!file) return;
    setButtonDisabled(true);
    const pictureUrl: string = await StorageService.UploadProfilePicture(
      file,
      user.id
    );

    console.log(file);
    updateUserRQ.mutate({ ...user, avatarUrl: pictureUrl });
  };

  // on profile picture delete
  const handleConfirmDeletePicture = () => {
    setButtonDisabled(true);
    updateUserRQ.mutate({ ...user, avatarUrl: null });
    showConfirmationPopUpDelete(false);
  };

  const handleDenyConfirmation = () => {
    showConfirmationPopUpDelete(false);
  };

  return (
    <div className={cls.root}>
      {successConfirmationUpdated && (
        <ConfirmationPopUp
          maxWidth={400}
          type='success'
          message='Account successfully updated!'
          onClick={() => showSuccessConfirmationUpdated(false)}
        />
      )}

      {confirmationPopUpDelete && (
        <CallToActionPopUp
          message='Are you sure you want to delete this profile picture?'
          onConfirm={() => handleConfirmDeletePicture()}
          onDeny={() => handleDenyConfirmation()}
        />
      )}

      {errorConfirmation && (
        <ConfirmationPopUp
          maxWidth={400}
          type='error'
          message='Could not update the account!'
          onClick={() => showErrorConfirmation(false)}
        />
      )}

      <label> Profile picture</label>
      <div className={cls.avatar}>
        <ProfileImage size={84} rounded={true} imgUrl={user.avatarUrl} />

        {user.avatarUrl && (
          <div
            className={cls.delete}
            onClick={() =>
              showConfirmationPopUpDelete(!buttonDisabled ? true : false)
            }>
            &#128465;
          </div>
        )}
      </div>
      <label
        htmlFor='fileInput'
        role='button'
        className={`${cls.fileUpload} ${buttonDisabled ? cls.disabled : ""}`}>
        Upload
      </label>
      <input
        disabled={buttonDisabled}
        id='fileInput'
        type='file'
        name='fileniput'
        accept='image/*'
        style={{ display: "none" }}
        onChange={handleChangeProfilePicture}
      />
    </div>
  );
};

export default ProfilePicture;
