import { ApiEndpoints } from "../endpoints";
import { requestApi } from "../requestApi";

// import { fetchUser, useUserProgress } from "../user/user";
// import { IUser } from "../../interfaces/IUser";
// import {
//   IProgress,
//   VideoProgress,
//   ProgressTitles,
// } from "../../interfaces/IProgress";
import { AxiosResponse } from "axios";

export type TrackingVideo = {
  courseId: string;
  termId: string;
  videoId: string;
  weekId: string;
  videoTimestamp: number;
};

class UserProgressTrackingServiceClass {
  // public progress: ProgressTitles[] | undefined = undefined;
  private requestQueue: Promise<void>[] = [];
  private resolver: Promise<void> | null = null;

  public async Submit(trackingVideo: TrackingVideo): Promise<void> {
    this.requestQueue.push(this.SubmitTrackingProgress(trackingVideo));

    if (this.resolver) return;

    this.resolver = this.ConsumeQueue();
  }

  // public async GetProgress(): Promise<ProgressTitles[]> {
  //   if (this.progress) return this.progress;

  //   return await this.UpdateProgress();
  // }

  private async ConsumeQueue(): Promise<void> {
    while (this.requestQueue.length > 0) {
      const request: Promise<void> | undefined = this.requestQueue.shift();

      if (request) await request;
    }

    // await this.UpdateProgress();

    this.resolver = null;
  }

  /// TO DO: Update progress depending on existing user progress compared with seconds wathced
  // only update if seconds watched is greater than current progress
  private async SubmitTrackingProgress(
    trackingVideo: TrackingVideo
  ): Promise<void> {
    const result: AxiosResponse = await requestApi({
      url: ApiEndpoints.TRACK_VIDEO_PROGRESS,
      data: trackingVideo,
      method: "POST",
    });

    if (result.status !== 200) throw new Error(result.statusText);

    // await this.UpdateUserVideoProgress(trackingVideo);
  }

  // private async UpdateUserVideoProgress(
  //   trackingVideo: TrackingVideo
  // ): Promise<void> {
  //   const user: any = await fetchUser();

  //   const progress: IProgress | undefined = user.progress?.find(
  //     (termId: string) => termId === trackingVideo.termId
  //   );

  //   const newVideoProgress: VideoProgress = {
  //     secondsWatched: trackingVideo.videoTimestamp,
  //   };

  //   if (progress)
  //     this.UpdateCurrentProgressForVideo(
  //       progress,
  //       trackingVideo,
  //       newVideoProgress
  //     );
  //   else this.AddNewProgressEntry(user, trackingVideo, newVideoProgress);
  // }

  // private UpdateCurrentProgressForVideo(
  //   progress: IProgress,
  //   trackingVideo: TrackingVideo,
  //   newVideoProgress: VideoProgress
  // ): void {
  //   progress.lastVideoWatched = {
  //     videoId: trackingVideo.videoId,
  //     weekId: trackingVideo.weekId,
  //   };

  //   if (progress.lectureProgress[trackingVideo.weekId])
  //     progress.lectureProgress[trackingVideo.weekId].videoProgress[
  //       trackingVideo.videoId
  //     ] = newVideoProgress;
  //   else
  //     progress.lectureProgress[trackingVideo.weekId] = {
  //       videoProgress: {
  //         [trackingVideo.videoId]: newVideoProgress,
  //       },
  //     };
  // }

  // private AddNewProgressEntry(
  //   user: IUser,
  //   trackingVideo: TrackingVideo,
  //   newVideoProgress: VideoProgress
  // ): void {
  //   if (!user.progress) user.progress = [];

  //   user.progress.push({
  //     lectureProgress: {
  //       [trackingVideo.weekId]: {
  //         videoProgress: {
  //           [trackingVideo.videoId]: newVideoProgress,
  //         },
  //       },
  //     },
  //     lastVideoWatched: {
  //       videoId: trackingVideo.videoId,
  //       weekId: trackingVideo.weekId,
  //     },
  //     termId: trackingVideo.termId,
  //     courseId: trackingVideo.courseId,
  //   });
  // }

  // private async UpdateProgress(): Promise<ProgressTitles[]> {
  //   const result: AxiosResponse = await requestApi({
  //     url: ApiEndpoints.GET_PROGRESS,
  //     data: {},
  //     method: "POST",
  //   });
  //   console.log(result);
  //   if (result.status !== 200) throw new Error(result.statusText);

  //   this.progress = result.data.progress as ProgressTitles[];
  //   console.log(this.progress);
  //   return this.progress;
  // }
}

const UserProgressTrackingService: UserProgressTrackingServiceClass =
  new UserProgressTrackingServiceClass();

export default UserProgressTrackingService;
