import cls from "./Privacy.module.scss";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import AppHeader from "../../components/appHeader/AppHeader";

import { CollapsedSidebarContext } from "../../context/collapsedSidebarContext";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { useContext } from "react";

const Privacy: React.FC = () => {
  const { collapsed } = useContext(CollapsedSidebarContext);

  return (
    <div className={cls.root}>
      <SidebarMenu />

      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />
        <div className={cls.container}>
          <div className={cls.title}> GENERAL </div>

          <section>
            {/* <h1>COMPANY</h1> */}

            <div className={cls.faq}>
              {/* <h2>What is Visign?</h2> */}
              <p>
                This privacy policy for VISIGN ("Company," "we," "us," or
                "our"), describes how and why we might collect, store, use,
                and/or share ("process") your information when you use our
                services ("Services"), such as when you:
                <br /> <br />
                • Visit our website, or any website of ours that links to this
                privacy policy.
                <br />
                <br />
                <b>Questions or concerns?</b> Reading this privacy policy will
                help you understand your privacy rights and choices. If you do
                not agree with our policies and practices, please do not use our
                Services. If you still have any questions or concerns, please
                contact us at{" "}
                <a href='mailto:contact@visign.com'>contact@visign.com</a>.
              </p>
            </div>
          </section>
          <div className={cls.title}> SUMMARY </div>

          <section>
            {/* <h1>COMPANY</h1> */}

            <div className={cls.faq}>
              {/* <h2>What is Visign?</h2> */}
              <p>
                This summary provides key points from our privacy policy, but
                you can find out more details about any of these topics in the
                Section “Privacy Policy in Full”.
                <br /> <br />
                <b>What personal information do we process? </b> When you visit
                or navigate our Services, we do not process personal
                information. When you sign up to our Services, we process
                personal information provided by you.
                <br />
                <br />
                <b>Do we process any sensitive personal information? </b> We do
                not process sensitive personal information.
                <br />
                <br />
                <b>Do we receive any information from third parties? </b> We do
                not receive any information from third parties.
                <br />
                <br />
                <b>How do we process your information? </b> We process your
                information to provide, improve, and administer our Services,
                communicate with you, for security and fraud prevention, and to
                comply with law. We process your information only when we have a
                valid legal reason to do so.
                <br />
                <br />
                <b>
                  In what situations and with which parties do we share personal
                  information?
                </b>
                We may share information in specific situations and with
                specific third parties.
                <br />
                <br />
                <b>How do we keep your information safe?</b>
                We have organizational and technical processes and procedures in
                place to protect your personal information.
                <br />
                <br />
                <b>What are your rights?</b>
                Depending on where you are located geographically, the
                applicable privacy law may mean you have certain rights
                regarding your personal information.
                <br />
                <br />
                <b>How do you exercise your rights?</b>
                The easiest way to exercise your rights is by contacting us. We
                will consider and act upon any request in accordance with
                applicable data protection laws.
              </p>
            </div>
          </section>
          <div className={cls.title}>PRIVACY POLICY IN FULL </div>
          <section>
            <div className={cls.faq}>
              <h3>1. WHO IS THE DATA CONTROLLER?</h3>
              <h3>2. WHAT INFORMATION DO WE COLLECT?</h3>
              <h3>3. HOW DO WE PROCESS YOUR INFORMATION?</h3>
              <h3>
                4. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                INFORMATION?
              </h3>
              <h3>
                5. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </h3>
              <h3>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
              <h3>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
              <h3>8. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
              <h3>9. COOKIES AND OTHER TRACKING TECHNOLOGIES</h3>
              <h3>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
              <h3>11. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
              <h3>12. DO WE MAKE UPDATES TO THIS POLICY?</h3>
              <h3>13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h3>
              <h3>
                14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </h3>
            </div>
          </section>
          <section>
            {/* <h1>COMMUNITY</h1> */}

            <div className={cls.faq}>
              <h2>1. WHO IS THE DATA CONTROLLER?</h2>
              <p>
                The data controller responsible for the collection and
                processing of your personal data through this Service is the
                company VISIGN ACADEMY SRL (“VISIGN”), a Romanian legal entity
                with registered office in the Municipality of Buzău, Buzău
                County, registered at the Trade Register under no.
                J10/1032/2020, with fiscal code 43361971, e-mail address:
                <a href='mailto:contact@visign.com'>contact@visign.com</a>.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>2. WHAT INFORMATION DO WE COLLECT?</h2>
              {/* <h2>Our intellectual property</h2> */}
              <p>
                We collect personal information that you voluntarily provide to
                us when you register on the Services, express an interest in
                obtaining information about us or our products and Services,
                when you participate in activities on the Services, or otherwise
                when you contact us.
              </p>

              <h2>Personal Information Provided by You.</h2>
              <p>
                The personal information that we collect depends on the context
                of your interactions with us and the Services. The personal
                information we collect may include the following:
              </p>
              <ul>
                <li>email addresses</li>
                <li>contact preferences</li>
              </ul>
              <h2>Sensitive Information. </h2>
              <p>
                We do not process sensitive information.
                <br />
                <br />
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </p>
            </div>
            <div className={cls.faq}>
              <h2>Information automatically collected.</h2>
              <p>
                We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal
                your specific identity (like your name or contact information),
                but may include device and usage information, such as:
              </p>

              <ul>
                <li>
                  <i>Log and Usage Data: </i>
                  service-related, diagnostic, usage, and performance
                  information our servers automatically collect when you access
                  or use our Services. Depending on how you interact with us,
                  this log data may include your device information, browser
                  type and information about your activity in the Services (such
                  as the pages and files viewed and other actions you take e.g.
                  which features you use).
                </li>
                <li>
                  <i>Device Data.</i> We collect device data such as information
                  about your computer, phone, tablet, or other device you use to
                  access the Services. Depending on the device used, this device
                  data may include information such as your location, browser
                  type, operating system.
                </li>
                <li>
                  <i>Location Data.</i> We collect location data such as
                  information about your device’s location, which can be either
                  precise or imprecise. How much information we collect depends
                  on the type and settings of the device you use to access the
                  Services. You can opt out of allowing us to collect this
                  information either by refusing access to the information or by
                  disabling your Location setting on your device.{" "}
                </li>
              </ul>
              <p>
                This information is primarily needed to maintain the security
                and operation of our Services, and for our internal analytics
                and reporting purposes.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>3. HOW DO WE PROCESS YOUR INFORMATION?</h2>

              <p>
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </p>
              <ul>
                <li>
                  To deliver and facilitate delivery of services to the user,
                  i.e. provide you with the requested Service.
                </li>
                <li>
                  To respond to user inquiries/offer support to users with the
                  respective Service.
                </li>
                <li>
                  To send administrative information to you, including details
                  about our products and services, changes to our terms and
                  policies, and other similar information.
                </li>
                <li>
                  To send you marketing and promotional communications, if this
                  is in accordance with your marketing preferences. You can opt
                  out of our marketing emails at any time.
                </li>
              </ul>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>
                4. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
              </h2>
              <p>
                <u>
                  <i>
                    If you are located in the EU or UK, this section applies to
                    you.
                  </i>
                </u>
                <br />
                <br />
                The General Data Protection Regulation (GDPR) and UK GDPR
                require us to explain the valid legal bases we rely on in order
                to process your personal information. As such, we may rely on
                the following legal bases to process your personal information:
              </p>
              <ul>
                <li>
                  <b>Consent.</b> We may process your information if you have
                  given us permission (i.e. consent) to use your personal
                  information for a specific purpose. You can withdraw your
                  consent at any time.
                </li>
                <li>
                  <b>Performance of a Contract.</b> We may process your personal
                  information when it is necessary to fulfill our contractual
                  obligations to you, including providing our Services or at
                  your request.
                </li>
                <li>
                  <b>Legitimate Interests.</b> We may process your information
                  when it is reasonably necessary to achieve our legitimate
                  business interests and those interests do not outweigh your
                  interests and fundamental rights and freedoms.
                </li>
                <li>
                  <b>Legal Obligations.</b> We may process your information
                  where it is necessary for compliance with our legal
                  obligations, such as to cooperate with a law enforcement body
                  or regulatory agency, exercise or defend our legal rights, or
                  disclose your information as evidence in litigation in which
                  we are involved.
                </li>
              </ul>
              <p>
                <u>
                  <i>
                    If you are located in the Canada this section applies to
                    you.
                  </i>
                </u>
                <br />
                <br />
                We may process your information if you have given us specific
                permission (i.e. express consent) to use your personal
                information for a specific purpose, or in situations where your
                permission can be inferred (i.e. implied consent). You can
                withdraw your consent at any time.
                <br />
                <br />
                In some exceptional cases, we may be legally permitted under
                applicable law to process your information without your consent,
                including, for example:
              </p>

              <ul>
                <li>For investigations and fraud detection and prevention</li>
                <li>
                  For business transactions provided certain conditions are met
                </li>
                <li>
                  If it is reasonable to expect collection and use with consent
                  would compromise the availability or the accuracy of the
                  information and the collection is reasonable for purposes
                  related to investigating a breach of an agreement or a
                  contravention of the laws of Canada or a province
                </li>
                <li>
                  If disclosure is required to comply with a subpoena, warrant,
                  court order, or rules of the court relating to the production
                  of records
                </li>
                <li>
                  If the information is publicly available and is specified by
                  the regulations
                </li>
              </ul>
              <h2>Newsletter subscription</h2>
              <p>
                In order to ensure that the transmission of newsletters is
                carried out in compliance with data protection rules, but also
                to ensure additional protection of the data thus processed, the
                Controller has implemented the following way of subscribing to
                the newsletter:
              </p>
              <ul>
                <li>filling in the box for the e-mail address; </li>
                <li>
                  ticking the box corresponding to the consent to the terms of
                  service. This represents your consent to the processing of
                  personal data transmitted for the purpose of receiving
                  informative communications;
                </li>
                <li>
                  confirming the subscription by clicking the "Sign up" button.
                </li>
              </ul>
              <p>
                The subscription and subsequent communications are not subject
                to payment of a fee. The content of the informative
                communications will be sent to the e-mail address provided for
                this purpose by the user, provided that it is entered correctly.
                Visign does not assume any responsibility for the accuracy and
                veracity of the information provided by users (e.g. e-mail
                address).
                <br />
                <br />
                Subscribing to the newsletter involves the processing of the
                e-mail address, as well as the name and surname, indirectly (in
                the case of e-mail addresses such as name.surname@x.com), which
                fall under the category of personal data.
                <br />
                <br />
                All data processing carried out for the purpose of transmitting
                information specific to the Controller's marketing activity
                complies with the rules described in the current Privacy Policy.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>
                5. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </h2>

              <p>
                Your personal information is processed, generally, on the
                territory of the European Union, through secure internal
                servers. However, by means of specific social network plug-ins
                (e.g. Facebook, Instagram, Discord etc.), as third-party
                recipients of the data, it is possible that the data processed
                in this context may also be transferred outside the European
                Union/European Economic Area and stored on servers in third
                countries. In this regard, the Controller has implemented a
                series of necessary and appropriate measures to ensure that the
                data is processed in accordance with the applicable law.
                <br />
                <br />
                Your personal information is shared with VISIGN staff,
                collaborators and suppliers involved in delivering the Services
                to you, with the purposes of delivering the Service to you,
                responding to your inquiries/ offering support with the
                Services, sending administrative information and marketing and
                promotional communications, if the latter is in accordance with
                your marketing preferences. VISIGN ensures that these parties
                are bound by appropriate confidentiality and data protection
                obligations.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

              <p>
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy policy,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting, or other legal requirements).
                <br />
                <br />
                No purpose in this policy will require us keeping your personal
                information for longer than the period of time in which users
                are signed up to our Services.
                <br />
                <br />
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

              <p>
                We have implemented appropriate and reasonable administrative,
                physical and technical measures designed to protect the security
                of any personal information we process.
                <br />
                <br />
                Data collected through the Service is not transferred to
                countries outside the European Economic Area (EEA).
                <br />
                <br />
                To protect information and personal data, VISIGN uses encryption
                technologies for certain types of transmissions coordinated
                through this site. However, no electronic transmission over the
                internet or information storage technology can be guaranteed to
                be 100% secure, so we cannot promise or guarantee that hackers,
                cybercriminals, or other unauthorized third parties will not be
                able to defeat our security and improperly collect, access,
                steal, or modify your information. Although we will do our best
                to protect your personal information, transmission of personal
                information to and from our Services is at your own risk. You
                should only access the Services within a secure environment.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>

              <p>
                In some regions (like the EEA, UK, and Canada), you have certain
                rights under applicable data protection laws. These may include
                the right:
              </p>
              <br />
              <p>
                &nbsp; &nbsp; &nbsp; &nbsp; (I) &nbsp; to request access and
                obtain a copy of your personal information
              </p>
              <br />
              <p>
                &nbsp; &nbsp; &nbsp; &nbsp; (II) &nbsp; to request rectification
                or erasure;
              </p>
              <br />
              <p>
                &nbsp; &nbsp; &nbsp; &nbsp; (III) &nbsp; to restrict the
                processing of your personal information;{" "}
              </p>
              <br />
              <p>
                &nbsp; &nbsp; &nbsp; &nbsp; (IV) &nbsp; if applicable, to data
                portability;
              </p>
              <br />
              <p>
                &nbsp; &nbsp; &nbsp; &nbsp; (V) &nbsp; in certain circumstances,
                you may also have the right to object to the processing of your
                personal information. You can make such a request by contacting
                us at <a href='mailto:contact@visign.com'>contact@visign.com</a>
                .
              </p>
              <br />
              <p>
                &nbsp; &nbsp; &nbsp; &nbsp; (VI) &nbsp; to object to automated
                individual decision-making, including profiling. However, the
                exercise of this right shall not apply if the decision is
                necessary for the conclusion or performance of a contract
                between the data subject and VISIGN, if the decision is
                authorized by the law applicable to the Controller, provided
                that it ensures adequate protection of the rights, freedoms and
                interests of the data subject or is based on the consent of the
                data subject obtained in compliance with applicable law;
              </p>
              <br />
              <p>
                &nbsp; &nbsp; &nbsp; &nbsp; (VII) &nbsp; to address your state
                data protection authority.{" "}
              </p>
              <br />
              <p>
                We will consider and act upon any request in accordance with
                applicable data protection laws.
                <br />
                <br />
                If you are located in the EEA or UK and you believe we are
                unlawfully processing your personal information, you also have
                the right to complain to your Member State data protection
                authority or UK data protection authority. If you are located in
                Switzerland, you may contact the Federal Data Protection and
                Information Commissioner.
                <br />
                <br />
                <b>Withdrawing your consent.</b> If we are relying on your
                consent to process your personal information, which may be
                express and/or implied consent depending on the applicable law,
                you have the right to withdraw your consent at any time. You can
                withdraw your consent at any time by contacting us at{" "}
                <a href='mailto:contact@visign.com'>contact@visign.com</a>
                or updating your preferences.
                <br />
                <br />
                However, please note that this will not affect the lawfulness of
                the processing before its withdrawal nor, when applicable law
                allows, will it affect the processing of your personal
                information conducted in reliance on lawful processing grounds
                other than consent.
                <br />
                <br />
                <b>
                  Opting out of marketing and promotional communications.
                </b>{" "}
                You can unsubscribe from our marketing and promotional
                communications at any time by clicking on the unsubscribe link
                in the emails that we send, or by contacting us at
                contact@visign.com. You will then be removed from the marketing
                lists. However, we may still communicate with you, for example,
                to send you service-related messages that are necessary for the
                administration and use of your account, to respond to service
                requests, or for other non-marketing purposes.
                <br />
                <br />
                After opting out (unsubscribing), you can subscribe to our
                communications again, at any time, free of charge.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>9. COOKIES AND OTHER TRACKING TECHNOLOGIES</h2>

              <p>
                We do not use cookies and tracking on this Service. Therefore,
                we do not collect any personal data through cookies.
                <br />
                Should VISIGN implement cookies and other tracking technologies
                on the Services, the privacy policy will be updated to reflect
                the types of personal data collected and ways for users to
                manage their preferences.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

              <p>
                California Civil Code Section 1798.83, also known as the "Shine
                The Light" law, permits our users who are California residents
                to request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us at
                <a href='mailto:contact@visign.com'>contact@visign.com</a>.
              </p>
              <br />
              <h2>CCPA Privacy Notice</h2>
              <p>The California Code of Regulations defines a "resident" as:</p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp; (1) every individual who is in the
                State of California for other than a temporary or transitory
                purpose
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp; (2) every individual who is domiciled
                in the State of California who is outside the State of
                California for a temporary or transitory purpose
              </p>
              <br />
              <p>
                All other individuals are defined as "non-residents."
                <br />
                <br />
                If this definition of "resident" applies to you, we must adhere
                to certain rights and obligations regarding your personal
                information.
              </p>
              <br />
              <h2>What categories of personal information do we collect?</h2>

              <p>
                We have collected the following categories of personal
                information in the past twelve (12) months:
              </p>

              <table>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Examples</th>
                    <th>Collected</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>A. Identifiers</td>
                    <td>
                      Contact details, such as real name, alias, postal address,
                      telephone or mobile contact number, unique personal
                      identifier, online identifier, Internet Protocol address,
                      email address and account name
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>
                      B. Personal information categories listed in the
                      California Customer Records statute
                    </td>
                    <td>
                      Name, contact information, education, employment,
                      employment history, and financial information
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>
                      C. Protected classification characteristics under
                      California or federal law
                    </td>
                    <td>Gender and date of birth</td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>D. Commercial information</td>
                    <td>
                      Transaction information, purchase history, financial
                      details, and payment information
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>E. Biometric information</td>
                    <td>Fingerprints and voiceprints</td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>F. Internet or other similar network activity</td>
                    <td>
                      Browsing history, search history, online behavior,
                      interest data, and interactions with our and other
                      websites, applications, systems, and advertisements
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>G. Geolocation data</td>
                    <td>Device location</td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>
                      H. Audio, electronic, visual, thermal, olfactory, or
                      similar information
                    </td>
                    <td>
                      Images and audio, video or call recordings created in
                      connection with our business activities
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>I. Professional or employment-related information</td>
                    <td>
                      Business contact details in order to provide you our
                      Services at a business level or job title, work history,
                      and professional qualifications if you apply for a job
                      with us
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>J. Education Information</td>
                    <td>Student records and directory information</td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>K. Inferences drawn from other personal information</td>
                    <td>
                      Inferences drawn from any of the collected personal
                      information listed above to create a profile or summary
                      about, for example, an individual`s preferences and
                      characteristics
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>L. Sensitive Personal Information</td>
                    <td></td>
                    <td>NO</td>
                  </tr>
                </tbody>
              </table>
              <p>
                We will use and retain the collected personal information as
                needed to provide the Services or for:{" "}
              </p>
              <ul>
                <li>Category A - As long as the user is subscribed with us</li>
                <li>Category K - As long as the user is subscribed with us</li>
              </ul>
              <p>
                We may also collect other personal information outside of these
                categories through instances where you interact with us online
                or by mail in the context of:
              </p>
              <ul>
                <li>Receiving help through our customer support channels;</li>
                <li>Participation in customer surveys;</li>
                <li>
                  Facilitation in the delivery of our Services and to respond to
                  your inquiries.
                </li>
              </ul>
              <h2>How do we use and share your personal information?</h2>
              <p>
                More information about our data collection and sharing practices
                can be found in this privacy policy.
                <br />
                <br />
                If you are using an authorized agent to exercise your right to
                opt out, we may deny a request if the authorized agent does not
                submit proof that they have been validly authorized to act on
                your behalf.
              </p>
              <h2>Will your information be shared with anyone else?</h2>
              <p>
                We may disclose your personal information with our service
                providers pursuant to a written contract between us and each
                service provider. Each service provider is a for-profit entity
                that processes the information on our behalf, following the same
                strict privacy protection obligations mandated by the CCPA.
                <br />
                <br />
                We may use your personal information for our own business
                purposes, such as for undertaking internal research. This is not
                considered to be "selling" of your personal information.
                <br />
                <br />
                VISIGN has not disclosed, sold, or shared any personal
                information to third parties for a business or commercial
                purpose in the preceding twelve (12) months. VISIGN will not
                sell or share personal information in the future, belonging to
                website visitors, users, and other consumers.
              </p>
              <h2>Your rights with respect to your personal data:</h2>
              <ul>
                <li>
                  <b>Right to request deletion of the data.</b> You can ask for
                  the deletion of your personal information. If you ask us to
                  delete your personal information, we will respect your request
                  and delete your personal information, subject to certain
                  exceptions provided by law, such as (but not limited to) our
                  compliance requirements resulting from a legal obligation, or
                  any processing that may be required to protect against illegal
                  activities.
                </li>
                <li>
                  <b>Right to be informed</b> Depending on the circumstances,
                  you have a right to know:
                  <ul>
                    <li>
                      whether we collect and use your personal information;
                    </li>
                    <li>
                      the categories of personal information that we collect;
                    </li>
                    <li>
                      the purposes for which the collected personal information
                      is used;
                    </li>
                    <li>
                      whether we sell or share personal information to third
                      parties;
                    </li>
                    <li>
                      the categories of personal information that we sold,
                      shared, or disclosed for a business purpose;
                    </li>
                    <li>
                      the categories of third parties to whom the personal
                      information was sold, shared, or disclosed for a business
                      purpose;
                    </li>
                    <li>
                      the business or commercial purpose for collecting,
                      selling, or sharing personal information;{" "}
                    </li>
                    <li>
                      the specific pieces of personal information we collected
                      about you.
                    </li>
                  </ul>
                  In accordance with applicable law, we are not obligated to
                  provide or delete consumer information that is de-identified
                  in response to a consumer request or to re-identify individual
                  data to verify a consumer request.
                </li>
                <li>
                  <b>
                    Right to Non-Discrimination for the Exercise of a Consumer`s
                    Privacy Rights.
                  </b>
                  We will not discriminate against you if you exercise your
                  privacy rights.
                </li>
                <li>
                  <b>
                    Right to Limit Use and Disclosure of Sensitive Personal
                    Information.
                  </b>
                  We do not process consumer's sensitive personal information.
                </li>
                <li>
                  <b>Verification process.</b> Upon receiving your request, we
                  will need to verify your identity to determine you are the
                  same person about whom we have the information in our system.
                  We will only use personal information provided in your request
                  to verify your identity or authority to make the request. To
                  the extent possible, we will avoid requesting additional
                  information from you for the purposes of verification. We will
                  delete such additionally provided information as soon as we
                  finish verifying you.
                </li>
                <li>
                  <b>Other privacy rights: </b>

                  <ul>
                    <li>
                      You may object to the processing of your personal
                      information.
                    </li>
                    <li>
                      You may request correction of your personal data if it is
                      incorrect or no longer relevant, or ask to restrict the
                      processing of the information.
                    </li>
                    <li>
                      You can designate an authorized agent to make a request
                      under the CCPA on your behalf. We may deny a request from
                      an authorized agent that does not submit proof that they
                      have been validly authorized to act on your behalf in
                      accordance with the CCPA.
                    </li>
                    <li>
                      You may request to opt out from future selling or sharing
                      of your personal information to third parties. Upon
                      receiving an opt-out request, we will act upon the request
                      as soon as feasibly possible, but no later than fifteen
                      (15) days from the date of the request submission.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                To exercise these rights, you can contact us at{" "}
                <a href='mailto:contact@visign.com'>contact@visign.com</a>.{" "}
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>11. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
              <h2>Virginia CDPA Privacy Notice</h2>

              <p>
                Under the Virginia Consumer Data Protection Act (CDPA): <br />
                "Consumer" means a natural person who is a resident of the
                Commonwealth acting only in an individual or household context.
                It does not include a natural person acting in a commercial or
                employment context.
                <br />
                "Personal data" means any information that is linked or
                reasonably linkable to an identified or identifiable natural
                person.
                <br />
                "Personal data" does not include de-identified data or publicly
                available information.
                <br />
                "Sale of personal data" means the exchange of personal data for
                monetary consideration.
                <br />
                <br />
                If this definition of "consumer" applies to you, we must adhere
                to certain rights and obligations regarding your personal data.
                <br />
                <br />
                The information we collect, use, and disclose about you will
                vary depending on how you interact with VISIGN and our Services.
                More information about our data collection and sharing practices
                can be found in this privacy policy.
              </p>
              <br />
              <h2>Your rights with respect to your personal data:</h2>
              <ul>
                <li>
                  Right to be informed whether or not we are processing your
                  personal data;
                </li>
                <li>Right to access your personal data;</li>
                <li>Right to correct inaccuracies in your personal data;</li>
                <li>Right to request deletion of your personal data;</li>
                <li>
                  Right to obtain a copy of the personal data you previously
                  shared with us;
                </li>
                <li>
                  Right to opt out of the processing of your personal data if it
                  is used for targeted advertising, the sale of personal data,
                  or profiling in furtherance of decisions that produce legal or
                  similarly significant effects ("profiling")
                </li>
              </ul>
              <p>
                VISIGN has not sold any personal data to third parties for
                business or commercial purposes.
                <br />
                VISIGN will not sell personal data in the future belonging to
                website visitors, users, and other consumers.
              </p>
              <br />
              <h2>Exercise your rights provided under the Virginia CDPA:</h2>
              <p>
                To exercise these rights, you can contact us at{" "}
                <a href='mailto:contact@visign.com'>contact@visign.com</a>
                <br />
                <br />
                If you are using an authorized agent to exercise your rights, we
                may deny a request if the authorized agent does not submit proof
                that they have been validly authorized to act on your behalf.
              </p>
              <br />
              <h2>Verification process:</h2>
              <br />
              <p>
                We may request that you provide additional information
                reasonably necessary to verify you and your consumer's request.
                If you submit the request through an authorized agent, we may
                need to collect additional information to verify your identity
                before processing your request.
                <br />
                <br />
                Upon receiving your request, we will respond without undue
                delay, but in all cases, within forty-five (45) days of receipt.
                The response period may be extended once by forty-five (45)
                additional days when reasonably necessary. We will inform you of
                any such extension within the initial 45-day response period,
                together with the reason for the extension.
              </p>
              <br />
              <h2>Right to appeal:</h2>
              <br />
              <p>
                If we decline to take action regarding your request, we will
                inform you of our decision and reasoning behind it. If you wish
                to appeal our decision, please contact us at{" "}
                <a href='mailto:contact@visign.com'>contact@visign.com</a>.
                <br />
                <br />
                Within sixty (60) days of receipt of an appeal, we will inform
                you in writing of any action taken or not taken in response to
                the appeal, including a written explanation of the reasons for
                the decisions. If your appeal if denied, you may contact the
                Attorney General to submit a complaint.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>12. DO WE MAKE UPDATES TO THIS POLICY?</h2>

              <p>
                We may update this privacy policy from time to time. The updated
                version will be indicated by an updated "Revised" date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy policy, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy policy frequently to be informed of
                how we are protecting your information.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h2>

              <p>
                If you have questions or comments about this policy, you may
                contact our Data Protection Officer (DPO) at{" "}
                <a href='mailto:contact@visign.com'>contact@visign.com</a>.
                <br />
                <br />
                If you wish to send a query or request regarding personal
                information, please mention “data protection”, “GDPR” or
                “personal information” in the subject. These requests will be
                treated with priority.
                <br />
                <br />
                You will receive a response within 30 days from the receipt of
                the query or request. In exceptional situations, this deadline
                can be extended; in such a case, you will be duly informed of
                the extension and reasons thereof.
              </p>
            </div>
          </section>
          <section>
            {/* <h1>PRICING</h1> */}
            <div className={cls.faq}>
              <h2>
                14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </h2>

              <p>
                Based on the applicable laws of your country, you may have the
                right to request access to the personal information we collect
                from you, change that information, or delete it. To request to
                review, update, or delete your personal information, please
                contact us at
                <a href='mailto:contact@visign.com'>contact@visign.com</a>.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
