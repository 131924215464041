import cls from "./LoginForm.module.scss";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import FormInput from "../../common/formInput/FormInput";
import MainBtn from "../../common/buttons/mainBtn/MainBtn";
import Loading from "../../common/loading/Loading";
import { auth } from "../../../api/firebase";
import { sendEmailVerification, signOut } from "firebase/auth";

type FormInputs = {
  email: string;
  password: string;
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Not a valid email address!")
    .required("Email is required!"),
  password: yup.string().required("Password is required!"),
});

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [verificationEmailLink, setVerificationEmailLink] = useState(false);
  ///////////////// handle submit form

  const { handleSubmit, control } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormInputs) => {
    try {
      setIsLoading(true);
      setError("");
      setVerificationEmailLink(false);

      const userCredential = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );

      // Check if the user's email has been verified
      if (!userCredential.user.emailVerified) {
        // If not verified, display a message to the user
        setError(
          "Your e-mail could not be verified. Your verification link may have expired. "
        );
        try {
          setIsLoading(true);
          await sendEmailVerification(userCredential.user);
          setVerificationEmailLink(true);
          setIsLoading(false);
          await signOut(auth);
        } catch (error: any) {
          setIsLoading(false);
          setError("Could not verify your account. Please try again later.");
        }
        return;
      }

      ///////////////////////
      // console.log("handle login submit", data.email, data.password);
      // await signInWithEmailAndPassword(auth, data.email, data.password).then(
      //   (d) => {
      //     navigate("/activity");
      //   }
      // );

      navigate("/activity");
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError("Invalid email or password. Please try again");
    }
  };

  return (
    <div className={cls.root}>
      {isLoading && <Loading />}

      <div className={cls.title}>Log In</div>
      <form>
        <div className={cls.row}>
          <Controller
            name='email'
            defaultValue=''
            control={control}
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <FormInput
                type='email'
                label='Email'
                defaultValue=''
                onChange={(e) => [setError(""), onChange(e)]}
                onBlur={onBlur}
                inputRef={ref}
                isTouched={isTouched}
                isDirty={isDirty}
                error={error}
              />
            )}
          />
        </div>
        <div className={cls.row}>
          <Controller
            name='password'
            defaultValue=''
            control={control}
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <FormInput
                type='password'
                label='Password'
                defaultValue=''
                onChange={(e) => [setError(""), onChange(e)]}
                onBlur={onBlur}
                inputRef={ref}
                isTouched={isTouched}
                isDirty={isDirty}
                error={error}
              />
            )}
          />
        </div>
        <p className={cls.error}> {error && error} </p>
        {verificationEmailLink && (
          <p style={{ color: "white" }}>
            A new verification link has been sent to your email address. Please
            check your inbox.
          </p>
        )}
        <div className={`${cls.row} ${cls.actions}`}>
          <MainBtn
            label='Login now'
            width={174}
            height={44}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
