import "./CourseChat.scss";

import ChatContainer from "../../common/chatContainer/ChatContainer";
import { IChatService } from "../../../api/chat/chatService";

type Props = {
  classChatService: IChatService | null;
};

const CourseChat: React.FC<Props> = ({ classChatService }) => {
  return (
    <div className='course-chat'>
      <ChatContainer chatService={classChatService} />
    </div>
  );
};

export default CourseChat;
