import "./CourseOverviewAssignments.scss";
type Props = {
  html: string;
};
const CourseOverviewAssignments: React.FC<Props> = ({ html }) => {
  return (
    <div
      className='course-overview-assignments'
      dangerouslySetInnerHTML={{
        __html: html || "<p>No homework description.</p>",
      }}>
      {/* <h5>Assignments:</h5>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Scelerisque viverra

      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Scelerisque viverra

      </p> */}
    </div>
  );
};

export default CourseOverviewAssignments;
