import { IVideo } from "../../interfaces/IVideo";
import VideoItem from "../courses/videoItem/VideoItem";
import "./VideoPlaylist.scss";
import { IWeek } from "../../interfaces/IWeek";
import { ITerm } from "../../interfaces/ITerm";
import TermWeekSelector from "../courses/termWeekSelector/TermWeekSelector";
import { useUser } from "../../api/user/user";
import { useParams } from "react-router-dom";
import { UrlParams } from "../../interfaces/UrlParams";
import { useEffect, useState } from "react";
import {
  IProgress,
  LectureProgress,
  VideoProgress,
} from "../../interfaces/IProgress";

type Props = {
  weekList: IWeek[];
  termList: ITerm[];
  videoList: IVideo[];
  courseTitle: string;
  onSelectWeek: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const VideoPlaylist: React.FC<Props> = ({
  termList,
  weekList,
  videoList,
  courseTitle,
  onSelectWeek,
}) => {
  const { data: userDataProgress } = useUser();
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { termId, weekId } = routeParameters;

  const [videoProgress, setVideoProgress] = useState<LectureProgress>();

  useEffect(() => {
    if (userDataProgress && termId && weekId) {
      const termProgress: IProgress = userDataProgress.progress.find(
        (vProgress: IProgress) => vProgress.termId === termId
      );
      const weekProgress: LectureProgress =
        termProgress?.lectureProgress[weekId];
      setVideoProgress(weekProgress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataProgress, termId, weekId]);
  return (
    <div className='course-overview-playlist'>
      <div className='drop-menu-wrapper'>
        <TermWeekSelector
          terms={termList}
          weeks={weekList}
          onSelect={onSelectWeek}
        />
      </div>
      <div className='playlist-wrapper'>
        <div style={{ position: "absolute" }}>
          {videoList?.length === 0 && (
            <h3 style={{ textAlign: "center" }}> No videos found...</h3>
          )}

          {videoList?.map((video: IVideo, index: number) => {
            const progress: VideoProgress = videoProgress?.videoProgress[
              video.id
            ] as VideoProgress;

            return (
              <div key={index}>
                <VideoItem
                  data={video}
                  courseTitle={courseTitle}
                  videoProgress={progress?.secondsWatched || 0}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default VideoPlaylist;
