import { createContext, ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
};

type ContextProps = {
  collapsed: boolean;
  handleSidebarState: () => void;
};

const CollapsedSidebarContext = createContext<ContextProps>({} as ContextProps);

const CollapsedSidebarProvider: React.FC<Props> = ({ children }) => {
  const [collapsed, setIsCollapsed] = useState(false);

  const handleSidebarState = () => {
    setIsCollapsed(!collapsed);
  };

  return (
    <CollapsedSidebarContext.Provider value={{ collapsed, handleSidebarState }}>
      {children}
    </CollapsedSidebarContext.Provider>
  );
};

export { CollapsedSidebarContext, CollapsedSidebarProvider };
