import { ApiEndpoints } from "../../api/endpoints";
import { requestApi } from "../../api/requestApi";

export enum EReportType {
  SPAM = "SPAM",
  OFFENSIVE_LANGUAGE = "OFFENSIVE_LANGUAGE",
}

export enum EReportedContentType {
  CHAT_MESSAGE = "CHAT_MESSAGE",
  GLOBAL_CHAT_MESSAGE = "GLOBAL_CHAT_MESSAGE",
  CLASS_QUESTION = "CLASS_QUESTION",
  CLASS_QUESTION_COMMENT = "CLASS_QUESTION_COMMENT",
}

export interface IReport {
  questionId: string;
  messageId?: string;
  contentReported: EReportedContentType;
  reportTypes: EReportType[];
  classId: string;
  courseId: string;
  termId: string;
  weekId: string;
}

export const submitReport = (payload: IReport) => {
  return requestApi({
    url: ApiEndpoints.SUBMIT_REPORT,
    method: "post",
    data: payload,
  });
};
