import cls from "./MainBtn.module.scss";
import Icon from "../../../../assets/icons/Icon";

type Props = {
  children?: React.ReactNode;
  label: string;
  iconLeft?: string;
  iconRight?: string;
  svgViewBox?: string;
  iconSize?: number;
  iconColor?: string;
  iconPadding?: string;
  width?: number;
  height?: number;
  transparent?: boolean;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const MainBtn: React.FC<Props> = ({
  children,
  label,
  iconLeft,
  iconRight,
  width,
  height,
  transparent,
  svgViewBox,
  iconSize,
  iconColor,
  iconPadding,
  disabled,
  type,
  onClick,
}) => {
  return (
    <button
      className={`${cls.root} ${transparent ? cls.transparent : ""}`}
      type={type}
      disabled={disabled}
      style={{ width: width, height: height }}
      onClick={onClick}>
      {iconLeft && (
        <div className={cls.icon} style={{ padding: iconPadding }}>
          <Icon
            icon={iconLeft}
            viewBox={svgViewBox}
            color={iconColor}
            size={iconSize}
          />
        </div>
      )}

      <div className={cls.label}>{label}</div>

      {iconRight && (
        <div className={cls.icon} style={{ padding: iconPadding }}>
          <Icon
            icon={iconRight}
            viewBox={svgViewBox}
            color={iconColor}
            size={iconSize}
          />
        </div>
      )}
      {children}
    </button>
  );
};

export default MainBtn;
