import { IMaintenance } from "../../interfaces/IMaintenance";
import { Dispatcher } from "../../utils/dispatcher";
import { ApiEndpoints } from "../../api/endpoints";
import { requestApi } from "../../api/requestApi";
import { AxiosResponse } from "axios";

class MaintenanceServiceClass {
  private readonly eventDispatcher: Dispatcher<void> = new Dispatcher();

  private isMaintenanceInProgress: boolean = false;

  private nextMaintenanceScheduled: IMaintenance | null = null;
  public getMaintenancePromise: Promise<IMaintenance[]> | null = null;

  public async Initialize() {
    const upcomingMaintenance: IMaintenance[] =
      await this.GetScheduledMaintenance();
    this.nextMaintenanceScheduled =
      upcomingMaintenance.sort(
        (a: IMaintenance, b: IMaintenance) => a.startTime - b.startTime
      )[0] || null;
  }

  public GetScheduledMaintenanceIfExists(): IMaintenance | null {
    return this.nextMaintenanceScheduled;
  }

  public SubscribeForMessages(callback: () => unknown): () => void {
    const unsubscribeHandler: () => void =
      this.eventDispatcher.Subscribe(callback);

    if (this.isMaintenanceInProgress) this.eventDispatcher.Dispatch();

    return unsubscribeHandler;
  }

  public OnMaintenanceInProgress(): void {
    this.isMaintenanceInProgress = true;

    this.eventDispatcher.Dispatch();
  }

  public GetScheduledMaintenance(): Promise<IMaintenance[]> {
    if (this.getMaintenancePromise) return this.getMaintenancePromise;

    this.getMaintenancePromise = this.GetMaintenance();

    return this.getMaintenancePromise;
  }

  private async GetMaintenance(): Promise<IMaintenance[]> {
    const response: AxiosResponse = await requestApi({
      url: ApiEndpoints.GET_SCHEDULE_MAINTENANCE_LIST,
      method: "GET",
      data: null,
    });

    if (response.status !== 200) throw new Error(response.statusText);

    return response.data.maintenance;
  }
}

const MaintenanceService: MaintenanceServiceClass =
  new MaintenanceServiceClass();

export default MaintenanceService;
