import cls from "./TicketTabPanel.module.scss";
import MainBtn from "../common/buttons/mainBtn/MainBtn";

import { useNavigate } from "react-router-dom";

import {
  ISupportTicket,
  SupportTicketCategory,
} from "../../interfaces/ISupportTicket";
import TicketListItem from "../ticketListItem/TicketListItem";
import { IUser } from "../../interfaces/IUser";

type Props = {
  filter: string;
  tickets: ISupportTicket[];
  supportDepartments: SupportTicketCategory[];
  user: IUser;
};

const TicketTab: React.FC<Props> = ({
  filter,
  tickets,
  supportDepartments,
  user,
}) => {
  const navigate = useNavigate();

  const matchesFilter = (ticket: ISupportTicket, filter: string) => {
    return (
      ticket.message.toLowerCase().includes(filter.toLowerCase()) ||
      ticket.status.toLowerCase().includes(filter.toLowerCase()) ||
      ticket.priority?.toLowerCase().includes(filter.toLowerCase())
    );
  };

  return (
    <div className={cls.ticketTab}>
      <div className={cls.header}>
        <MainBtn
          label='Open Ticket'
          height={44}
          onClick={() => navigate("/help/support")}
        />
      </div>

      {tickets.length > 0 && (
        <div className={cls.tickets}>
          <div className={cls.filterBar}>
            <div className={cls.headRow}>
              <span className={cls.caseId}>Case id</span>
              <span className={cls.subject}>Subject</span>
            </div>

            <div className={cls.headRow}>
              <span className={cls.status}>Status</span>
              <span className={cls.priority}>Priority</span>
              <span className={cls.department}>Department</span>
              <span className={cls.date}>Date</span>
            </div>
            <span className={cls.icon}></span>
          </div>

          {tickets?.length === 0 && (
            <p style={{ textAlign: "center", color: "white", padding: "30px" }}>
              No tickets were found!
            </p>
          )}

          {(filter.length > 2
            ? tickets.filter((t: ISupportTicket) => matchesFilter(t, filter))
            : tickets
          )?.map((ticket: ISupportTicket, index: number) => (
            <TicketListItem
              key={index}
              data={ticket}
              supportDepartments={supportDepartments}
              user={user}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TicketTab;
