import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  // API_URL,
  auth,
} from "./firebase";
import { User } from "firebase/auth";
import MaintenanceService from "./maintenance/maintenanceService";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL!,
});

// Add a request interceptor
client.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    // console.log("Request interceptor called");
    const user: User | null = auth.currentUser;

    if (!config?.headers) {
      throw new Error(
        `Expected 'config' and 'config.headers' not to be undefined`
      );
    }
    if (user) {
      const token = await user.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
client.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const resp: AxiosResponse | undefined = error?.response;
    const errorCode: number | undefined = resp?.data.error.code;

    if (errorCode === 18) {
      MaintenanceService.OnMaintenanceInProgress();
      console.log("Maintenance in progress....");
    }

    return Promise.reject(error);
  }
);

export const requestApi = async ({ ...options }) => {
  return client(options);
};

// export const requestApi = async ({ ...options }) => {
//   const user: User | null = auth.currentUser;

//   if (user)
//     client.defaults.headers.common.Authorization = `Bearer ${await user.getIdToken()}`;

//   const onSuccess = (response: AxiosResponse) => response;
//   const onError = (error: AxiosError) => {
//     const resp: AxiosResponse | undefined = error?.response;
//     const errorCode: number | undefined = resp?.data.error.code;

//     if (errorCode && errorCode === 18) {
//       MaintenanceService.OnMaintenanceInProgress();
//       console.log("Maintenance in progress....");
//     }

//     throw new Error(error.message);
//   };

//   return client(options).then(onSuccess).catch(onError);
// };
