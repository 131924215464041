import cls from "./Settings.module.scss";
import SidebarMenu from "../../components/sidebarMenu/SidebarMenu";
import { CollapsedSidebarContext } from "../../context/collapsedSidebarContext";
import React, { ReactElement, useContext, useState } from "react";
import AppHeader from "../../components/appHeader/AppHeader";
import UserSettings from "../../components/userSettings/UserSettings";
import Invoices from "../../components/invoices/Invoices";
import MainBtn from "../../components/common/buttons/mainBtn/MainBtn";
import { useNavigate } from "react-router-dom";

import { signOut } from "firebase/auth";
import { auth } from "../../api/firebase";
// import { GlobalChatService } from "../../api/chat/chatService";
// import { useQueryClient } from "react-query";
import { useUser } from "../../api/user/user";
import Loading from "../../components/common/loading/Loading";

const Settings: React.FC = (): ReactElement => {
  const { collapsed } = useContext(CollapsedSidebarContext);

  const navigate = useNavigate();
  // const queryClient = useQueryClient();
  const { data: userData, isLoading: isLoadingUser } = useUser();

  const [activeTab, setActiveTab] = useState<number>(0);

  const handleClickTab = (index: number) => setActiveTab(index);

  const handleLogout = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    await signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={cls.root}>
      <SidebarMenu />
      <div className={`${cls.wrapper} ${collapsed ? cls.collapsed : ""}`}>
        <AppHeader />

        <div className={cls.container}>
          {isLoadingUser && <Loading />}

          <div className={cls.header}>
            <div className={cls.name}>
              <div className={cls.title}>Profile Settings</div>
              <div className={cls.subtitle}>
                {/* Lorem ipsum dolor amet. */}
              </div>
            </div>
            <MainBtn
              label='Log Out'
              iconLeft='LogOut'
              svgViewBox='0 0 512 512'
              transparent
              iconSize={20}
              height={44}
              onClick={handleLogout}
            />
          </div>

          <div className={cls.contents}>
            <div className={cls.tabs}>
              <div
                className={`${cls.tab} ${activeTab === 0 ? cls.active : ""}`}
                onClick={() => handleClickTab(0)}>
                User Settings
              </div>

              <div
                className={`${cls.tab} ${activeTab === 1 ? cls.active : ""}`}
                onClick={() => handleClickTab(1)}>
                Invoices
              </div>
            </div>

            {activeTab === 0 && (
              <>{userData && <UserSettings user={userData} />}</>
            )}

            {activeTab === 1 && (
              <>
                <Invoices />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
