import "./CourseOverviewDetails.scss";
type Props = {
  html: string;
};
const CourseOverviewDetails: React.FC<Props> = ({ html }) => {
  return (
    <div
      className='course-overview-details'
      dangerouslySetInnerHTML={{
        __html: html || "<p>No course description.</p>",
      }}></div>
  );
};

export default CourseOverviewDetails;
