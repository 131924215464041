import React, { useState } from "react";
import Icon from "../../assets/icons/Icon";
import cls from "./InvoiceItem.module.scss";

type Props = {
  invoice: any;
};

const InvoiceItem: React.FC<Props> = ({ invoice }) => {
  const [expanded, setExpanded] = useState(false);

  const _date = new Date(invoice.created * 1000).toDateString();
  const handleSetExpanded = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    setExpanded(!expanded);
  };
  return (
    <>
      <tr>
        <td>{invoice.id}</td>
        <td>{_date}</td>
        <td style={{ textTransform: "uppercase" }}>
          {invoice.amount / 100} {invoice.currency}
        </td>
        <td style={{ textTransform: "uppercase" }}>0 {invoice.currency}</td>
        <td style={{ textTransform: "uppercase" }}>
          {invoice.amount_received / 100} {invoice.currency}
        </td>

        <td>
          <span className={cls.arrow} onClick={handleSetExpanded}>
            <Icon
              icon='ArrowLeft'
              viewBox='0 0 320 512'
              className={expanded ? cls.expanded : ""}
            />
          </span>
        </td>
      </tr>
      {expanded && (
        <tr>
          <th colSpan={6}>
            <div>
              {invoice.items.map((item: any, i: number) => {
                return (
                  <div
                    key={i}
                    style={{
                      padding: "20px 5px",
                      borderBottom: "1px solid #494949",
                      display: "flex",
                      // justifyContent: "space-between",
                      gap: "20px",
                    }}>
                    <div>
                      Course:{" "}
                      <span style={{ color: "white" }}>{item.description}</span>
                    </div>
                    <div>
                      Price:{" "}
                      <span style={{ color: "white" }}>
                        {item.amount_total / 100} {item.currency}
                      </span>
                    </div>
                  </div>
                );

                // return <InvoiceItem invoice={invoice} key={invoice.id} />;
              })}
            </div>
          </th>
        </tr>
      )}
    </>
  );
};

export default InvoiceItem;
