// import { useState } from "react";
// import Icon from "../../assets/icons/Icon";
import "./index.scss";

import TimelineD3, { DataSeriesProps } from "./TimelineD3";

// const timelineIntervals: string[] = ["week", "month"];

type Props = {
  dataSeries: DataSeriesProps[];
};

const CourseTimeline: React.FC<Props> = ({ dataSeries }) => {
  // const chartRef = useRef(null);

  // const [timelineInterval, setTimelineInterval] = useState<string>("week");
  // const [dataSeries, setDataSeries] = useState<any[]>(dataSeriesWeekly);

  // const handleSetTimelineInterval = (
  //   e: React.MouseEvent<HTMLDivElement>,
  //   interval: string
  // ) => {
  //   e.preventDefault();
  //   // setTimelineInterval(interval);
  //   // if (interval === "week") {
  //   //   setDataSeries(dataSeriesWeekly);
  //   // } else if (interval === "month") {
  //   //   setDataSeries(dataSeriesWeekly);
  //   // }
  //   // console.log(timelineInterval);
  // };

  return (
    <div className='activity-timeline'>
      <div className='activity-timeline-header'>
        <div className='activity-timeline-header-wrapper'>
          <h2 className='activity-timeline-title'>Course Timeline</h2>

          {/* <div className='activity-timeline-intervals'>
            {timelineIntervals.map((intervalName, index) => (
              <div
                key={intervalName + index}
                className={`activity-timeline-interval ${
                  intervalName === timelineInterval ? "selected" : ""
                }`}
                onClick={(event) =>
                  handleSetTimelineInterval(event, intervalName)
                }>
                <Icon icon='Bars' viewBox='0 0 448 512' size={18} />
                <span> {intervalName}</span>
              </div>
            ))}
          </div> */}
        </div>
      </div>
      <TimelineD3 dataSeries={dataSeries} />
    </div>
  );
};

export default CourseTimeline;
