import { IChatMessage } from "../../../interfaces/IChatMessage";
import { useQueryClient } from "react-query";
import "./ChatMessage";
import { IPublicUser } from "../../../interfaces/IPublicUser";

import { GetUserId } from "../../../api/firebase";
import { IUser } from "../../../interfaces/IUser";

type Props = {
  comment: IChatMessage;
  // isEditAllowed?: boolean;
  // onMessageEdit?: (newText: string, messageId: string) => void;
};

type userTypeResponse = { data: { user: IUser } };

const ChatMessage: React.FC<Props> = ({ comment }): JSX.Element => {
  const queryClient = useQueryClient();
  const isSubmittedByThisUser: boolean = comment.userId === GetUserId();

  const user: userTypeResponse = (
    comment.userId === GetUserId()
      ? queryClient.getQueryData(["user-data"])
      : queryClient.getQueryData(["public-user", comment.userId])
  ) as userTypeResponse;

  // if (!user) return <Loading />;

  return (
    <div
      className={`chat-message ${isSubmittedByThisUser ? "focus" : ""} ${
        user?.data?.user?.role.name === "ADMIN" ? "fromAdmin" : ""
      }`}>
      <div className='avatar'>
        <img src={user?.data.user.avatarUrl || "/user-avatar.webp"} alt='' />
      </div>

      <div className='contents'>
        <div className='head'>
          <span className='name'>
            {user?.data.user.firstName} {user?.data.user.lastName}
            {user?.data.user.role.name === "ADMIN" ? " [Admin]" : ""}
          </span>
          <span className='time'>
            {new Date(comment.unixTimestamp).toLocaleString()}
          </span>
        </div>
        <div className='message'>{comment.content}</div>
      </div>
    </div>
  );
};

export default ChatMessage;
