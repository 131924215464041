import cls from "./AppHeader.module.scss";
import { useNavigate } from "react-router-dom";
import ProfileImage from "../userProfile/profileImage/ProfileImage";
import {
  useUser,
  // useUserProgress
} from "../../api/user/user";
// import { useCourses } from "../../api/course/course";
// import { useEnrolledClasses } from "../../api/class/class";

const AppHeader: React.FC = () => {
  const { data: user } = useUser();
  // useCourses();
  // useEnrolledClasses();
  // useUserProgress();

  const navigate = useNavigate();

  if (!user) return <></>;

  return (
    <div className={cls.root}>
      <div className={cls.user} onClick={() => navigate("/settings")}>
        <div className={cls.avatar}>
          <ProfileImage size={38} rounded={false} imgUrl={user?.avatarUrl} />
        </div>

        <div className={cls.info}>
          <div
            className={cls.name}>{`${user?.firstName} ${user?.lastName}`}</div>
          <div className={cls.role}>{user?.role.name}</div>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
