import { useState } from "react";
import Icon from "../../assets/icons/Icon";
import { IQuestion } from "../../interfaces/IQuestion";
import cls from "./HelpQuestionItem.module.scss";

type Props = {
  data: IQuestion;
};

const HelpQuestionItem: React.FC<Props> = ({ data }) => {
  const [expandedDetails, setExpandedDetails] = useState<boolean>(false);

  return (
    <div
      className={cls.root}
      onClick={() => setExpandedDetails(!expandedDetails)}>
      <div className={cls.wrapper}>
        <div className={`${cls.header} ${expandedDetails ? cls.expanded : ""}`}>
          <div className={cls.title}>{data.title}</div>

          <span className={cls.icon}>
            <Icon
              icon='ArrowLeft'
              className={cls.arrow}
              viewBox='0 0 320 512'
            />
          </span>
        </div>
        {expandedDetails && (
          <div className={`${cls.body} ${expandedDetails ? cls.expanded : ""}`}>
            {data?.content}
          </div>
        )}
      </div>
    </div>
  );
};

export default HelpQuestionItem;
