import { createContext, useEffect, useState, ReactNode } from "react";
import INotification from "../interfaces/INotification";
import { GlobalNotificationService } from "../api/notifications/notificationService";
import { FilterDuplicateElements } from "../utils/common";

type NotificationContextProps = {
  notifications: INotification[] | null;
  numberOfUnreadNotifications: number | null;
};

const NotificationContext = createContext<NotificationContextProps | null>(
  null
);

type Props = {
  children: ReactNode;
};

const NotificationContextProvider: React.FC<Props> = ({ children }) => {
  const [numberOfUnreadNotifications, setNumberOfUnreadNotifications] =
    useState<number>(0);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const isNotificationNotRead = (notification: INotification) =>
    !notification.isRead;

  const OnNotificationReceived = (newNotifications: INotification[]) => {
    // console.log("OnNotificationReceived....");

    setNotifications(GlobalNotificationService.GetNotifications());

    const unreadNotifications: INotification[] =
      GlobalNotificationService.GetNotifications().filter(
        isNotificationNotRead
      );

    setNumberOfUnreadNotifications(unreadNotifications.length);
  };

  useEffect(() => {
    GlobalNotificationService.SubscribeForNotifications(OnNotificationReceived);
  }, []);

  const notificationContextValue: NotificationContextProps = {
    notifications,
    numberOfUnreadNotifications,
  };

  return (
    <NotificationContext.Provider value={notificationContextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationContextProvider };
