import cls from "./WeekItem.module.scss";
import ProgressBar2 from "../../common/progressBars/progressBar2/ProgressBar2";
import { useNavigate } from "react-router-dom";
import { WeekProgress } from "../../../interfaces/IProgress";
import { useEffect, useState } from "react";
import { useVideos } from "../../../api/course/course";
import { IVideo } from "../../../interfaces/IVideo";
import { IClass } from "../../../interfaces/IClass";

type Props = {
  id: string;
  courseId: string;
  enrolledClass?: IClass;
  termId: string;
  title: string;
  lastVideoWatched: string | null;
  weekProgress?: WeekProgress;
};

const WeekItem: React.FC<Props> = ({
  id,
  title,
  courseId,
  enrolledClass,
  termId,
  lastVideoWatched,
  weekProgress,
}) => {
  const { data: videos } = useVideos(courseId, termId, id);
  const navigate = useNavigate();

  const [pathToLecture, setPathToLecture] = useState<string>("");

  useEffect(() => {
    if (!enrolledClass) return;

    if (lastVideoWatched) {
      setPathToLecture(
        `/course-overview/${courseId}/${enrolledClass.id}/${termId}/${id}/${lastVideoWatched}`
      );
    } else {
      if (videos) {
        const firstVideo: IVideo = videos.find(
          (v: IVideo) => v.orderIndex === 0
        );
        if (firstVideo)
          setPathToLecture(
            `/course-overview/${courseId}/${enrolledClass.id}/${termId}/${id}/${firstVideo.id}`
          );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastVideoWatched, videos]);
  return (
    <div className={cls.week} onClick={() => navigate(pathToLecture)}>
      <div className={cls.title}>{title}</div>
      <ProgressBar2
        total={80}
        percentage={weekProgress?.progressPercentage || 0}
      />
    </div>
  );
};

export default WeekItem;
