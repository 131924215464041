import cls from "./Login.module.scss";
import LoginBg from "../../assets/images/login-bg.jpeg";
import Logo from "../../assets/images/logo@2x.png";
import { useState } from "react";
import LoginForm from "../../components/auth/loginForm/LoginForm";
// import SignupForm from "../../components/auth/signupForm/SignupForm";
import ForgotPasswordForm from "../../components/auth/forgotPasswordForm/ForgotPasswordForm";

type Auth = "login" | "forgot" | "signup";

const Login: React.FC = () => {
  const [authState, setAuthState] = useState<Auth>("login");
  return (
    <div className={cls.root}>
      <div className={cls.left}>
        <img src={LoginBg} alt='Visign' />
        <div className={cls.title}>
          Welcome to <br /> Visign
        </div>
      </div>
      <div className={cls.right}>
        <img className={cls.logo} src={Logo} alt='Visign Logo' />

        {authState === "login" && (
          <div className={cls.form}>
            <LoginForm />
          </div>
        )}
        {/* {authState === "signup" && (
          <div className={cls.form}>
            <SignupForm />
          </div>
        )} */}
        {authState === "forgot" && (
          <div className={cls.form}>
            <ForgotPasswordForm />
          </div>
        )}

        <div className={cls.actions}>
          {/* LOGIN */}
          {authState === "login" && (
            <div className={cls.wrapper}>
              {/* <div className={cls.row}>
                <span>Don`t have an account?</span>
                <span
                  className={cls.link}
                  onClick={() => setAuthState("signup")}>
                  SignUp
                </span>
              </div> */}
              <div className={cls.row}>
                <span
                  className={cls.link}
                  onClick={() => setAuthState("forgot")}>
                  Forgot password?
                </span>
              </div>
            </div>
          )}
          {/* SIGNUP */}
          {/* {authState === "signup" && (
            <div className={cls.wrapper}>
              <div className={cls.row}>
                <span>Already have an account?</span>
                <span
                  className={cls.link}
                  onClick={() => setAuthState("login")}>
                  Login
                </span>
              </div>
            </div>
          )} */}
          {/* FORGOT PASSWORD */}
          {authState === "forgot" && (
            <div className={cls.wrapper}>
              {/* <div className={cls.row}>
                <span>Don`t have an account?</span>
                <span
                  className={cls.link}
                  onClick={() => setAuthState("signup")}>
                  SignUp
                </span>
              </div> */}
              <div className={cls.row}>
                <span>Already have an account?</span>
                <span
                  className={cls.link}
                  onClick={() => setAuthState("login")}>
                  Login
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
