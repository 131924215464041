import { useQuery, useQueryClient } from "react-query";
import { ApiEndpoints } from "../../api/endpoints";
import { requestApi } from "../../api/requestApi";

import { IUser } from "../../interfaces/IUser";
import { cacheTimes } from "../cacheTimesRQ";

export const fetchUser = () => {
  return requestApi({
    url: ApiEndpoints.USER,
    method: "get",
  });
};

export const useUser = () => {
  const queryClient = useQueryClient();
  return useQuery(["user-data"], fetchUser, {
    cacheTime: cacheTimes.USER_DATA_CACHE,
    staleTime: cacheTimes.USER_DATA_STALE,
    initialData: queryClient.getQueryData("user-data"),
    refetchOnWindowFocus: true,
    select: (data) => data.data.user,
    onError: (err) => {
      console.log(err);
    },
  });
};

// export const updateUser = (payload: Partial<IUser>) => {
//   return requestApi({
//     url: ApiEndpoints.USER,
//     method: "post",
//     data: payload,
//   });
// };

export const updateUserAccount = (user: Partial<IUser>) => {
  return requestApi({
    url: ApiEndpoints.USER,
    method: "post",
    data: user,
  });
};

export const fetchUserProgress = () => {
  return requestApi({
    url: ApiEndpoints.GET_PROGRESS,
    method: "post",
    data: {},
  });
};

export const useUserProgress = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(["user-activity-progress"], fetchUserProgress, {
    refetchOnWindowFocus: true,
    enabled: enabled,
    cacheTime: cacheTimes.USER_ACTIVITY_PROGRESS_CACHE,
    staleTime: cacheTimes.USER_ACTIVITY_PROGRESS_STALE,
    initialData: queryClient.getQueryData(["user-activity-progress"]),
    select: (data) => data.data.progress,
    onError: (err) => {
      console.log(err);
    },
  });
};

//

// public users list
// export const usePublicUsersList = (usersToFetch: string[]) => {
//   const queryClient = useQueryClient();
//   if (usersToFetch.length === 0) return [];
//   return requestApi({
//     url: ApiEndpoints.PUBLIC_MULTIPLE_USERS,
//     method: "post",
//     data: { users: usersToFetch },
//   }).then((resp) => {
//     queryClient.setQueryData(["public-users-list"], resp);
//   });
// };

// export const fetchListOfUsersRQ = ({ queryKey }: QueryFunctionContext) => {
//   const usersToFetch = queryKey[1] as string[];
//   // if (usersToFetch.length === 0) return [];
//   return requestApi({
//     url: ApiEndpoints.PUBLIC_MULTIPLE_USERS,
//     method: "post",
//     data: { users: usersToFetch },
//   });
// };

export const fetchListOfUsers = (usersToFetch: string[]) => {
  if (usersToFetch.length === 0) return [];

  return requestApi({
    url: ApiEndpoints.PUBLIC_MULTIPLE_USERS,
    method: "post",
    data: { users: usersToFetch },
  });
};

// public user
// export const fetchPublicUserRQ = ({ queryKey }: QueryFunctionContext) => {
//   const userId = queryKey[1];

//   return requestApi({
//     url: ApiEndpoints.PUBLIC_USER,
//     method: "post",
//     data: { userId: userId },
//   });
// };

export const fetchPublicUser = (userId: string) => {
  return requestApi({
    url: ApiEndpoints.PUBLIC_USER,
    method: "post",
    data: { userId: userId },
  });
};

export const updateUserNotificationSettings = (
  areNotificationsDisabled: boolean
) => {
  return requestApi({
    url: ApiEndpoints.UPDATE_NOTIFICATIONS_SETTINGS,
    method: "post",
    data: { areNotificationsDisabled: areNotificationsDisabled },
  });
};
