import "./ProgressBar2.scss";

type Props = {
  percentage: number;
  total: number;
  labelStyle?: React.CSSProperties;
  hideLabel?: boolean;
};

const ProgressBar2: React.FC<Props> = ({
  total,
  percentage,
  labelStyle,
  hideLabel,
}) => {
  const progress = Math.round((percentage / 100) * total);

  return (
    <div className='progress-bar-2'>
      {!hideLabel && (
        <div className='label' style={labelStyle}>
          Progress: <span>{Math.round(percentage) || 0}%</span>
        </div>
      )}

      <div className='progress-bar' style={{ width: total || 0 }}>
        <div
          className='progress-value'
          style={{
            width: progress || 0,
            backgroundColor:
              percentage < 21
                ? "#C31E1E"
                : percentage < 70
                ? "#CC33FF"
                : "#2B8E87",
          }}
        />
      </div>
    </div>
  );
};

export default ProgressBar2;
