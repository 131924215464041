import cls from "./TicketListItem.module.scss";
import React, { RefObject, useEffect, useRef, useState } from "react";
import Icon from "../../assets/icons/Icon";
import {
  ISupportTicket,
  SupportTicketCategory,
} from "../../interfaces/ISupportTicket";
import { useNavigate, useParams } from "react-router-dom";
import { UrlParams } from "../../interfaces/UrlParams";
import { IUser } from "../../interfaces/IUser";
import {
  useSupportTicketMessages,
  // useSupportTickets,
} from "../../api/support/support";
import { IChatMessage } from "../../interfaces/IChatMessage";
import { useQueries, useQueryClient } from "react-query";
import { fetchPublicUser } from "../../api/user/user";
import { cacheTimes } from "../../api/cacheTimesRQ";
// import MessageSubmitter from "../../common/messageSubmitter/MessageSubmitter";

// import {
//   ReplyPayload,
//   submitTicketReply,
//   updateSupportTicket,
//   UpdateTicketPayload,
//   useSupportTicketMessages,
// } from "../../../api/support/supportService";
// import { IChatMessage } from "../../../interfaces/IChatMessage";

type Props = {
  data: ISupportTicket;
  user: IUser;
  // userList: IUser[];
  supportDepartments: SupportTicketCategory[];
};

const TicketListItem: React.FC<Props> = ({
  data,
  supportDepartments,
  user,
}) => {
  // const { refetch: refetchTickets } = useSupportTickets();
  const { data: replies } = useSupportTicketMessages(data.id);
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { ticketId } = routeParameters;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const ticketRef: RefObject<HTMLDivElement> = useRef(null);

  const [userIds, setUserIds] = useState<string[]>();
  const [department, setDepartment] = useState<string>();

  const getDate = (time: number) => {
    const date = new Date(time);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const handleExpandDetails = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (ticketId) {
      navigate(
        ticketId !== data.id ? `/help/tickets/${data.id}` : "/help/tickets/"
      );
    }

    if (!ticketId) navigate(`/help/tickets/${data.id}`);

    // setExpandedDetails(!expandedDetails);
  };

  const loadUsers = async () => {
    const userIds: string[] = [];
    if (replies) {
      for (const reply of replies) userIds.push(reply.userId);
    }

    setUserIds(userIds);
  };

  // get user data 4 every reply in ticket
  useQueries(
    (userIds || []).map((userId: string) => {
      return {
        queryKey: ["public-user", userId],
        queryFn: () => fetchPublicUser(userId),
        enabled: !!userIds,
        cacheTime: cacheTimes.PUBLIC_USER_DATA_CACHE,
        staleTime: cacheTimes.PUBLIC_USER_DATA_STALE,
        initialData: queryClient.getQueryData(["public-user", userId]),
        refetchOnWindowFocus: false,
      };
    })
  );

  useEffect(() => {
    loadUsers().catch((e) => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replies]);

  const getUserAvatar = (userId: string) => {
    const user: any = queryClient.getQueryData(["public-user", userId]);
    return user?.data.user.avatarUrl;
  };

  // const addReplyToTicket = useMutation(submitTicketReply, {
  //   onSuccess: () => {
  //     // Invalidates cache and refetch
  //     queryClient.invalidateQueries(["ticket-messages", data.id]);
  //   },
  //   onError: (err) => {
  //     console.log("addCommentToQuestionRQ", err);
  //   },
  //   // onSettled: () => {

  //   // },
  // });

  // const handleSubmitReply = (textToSubmit: string) => {
  //   // e.preventDefault();
  //   if (textToSubmit.length === 0) return;
  //   console.log(textToSubmit);

  //   const payload: ReplyPayload = {
  //     ticketId: data.id,
  //     message: textToSubmit,
  //   };
  //   addReplyToTicket.mutate(payload);
  // };

  ///////////////
  useEffect(() => {
    setDepartment(
      supportDepartments.find(
        (sd: SupportTicketCategory) => sd.id === data.categoryId
      )?.name
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const ticketDiv: HTMLDivElement | null = ticketRef.current;

    if (ticketId === data.id) {
      // refetchTickets();
      // refetchTicketReplies();

      setTimeout(() => {
        ticketDiv?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }, 300);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId]);

  return (
    <div
      className={`${cls.root} ${
        replies?.length > 0 && data.status !== "CLOSED" ? cls.answered : ""
      }`}
      ref={ticketRef}>
      <div className={cls.wrapper} onClick={handleExpandDetails}>
        <div
          className={`${cls.header} ${
            data.id === ticketId ? cls.expanded : ""
          }`}>
          <div className={cls.headRow}>
            <span className={cls.caseId}>{data.id.slice(0, 6)}</span>

            <span className={cls.subject}>{data.message}</span>
          </div>
          <div className={cls.headRow}>
            <span
              className={`${cls.status} ${
                data.status === "OPEN" ? cls.open : ""
              } ${data.status === "CLOSED" ? cls.close : ""}`}>
              {data.status}
            </span>
            <span
              className={`${cls.priority} ${
                data.priority === "MEDIUM" ? cls.medium : ""
              } ${data.priority === "HIGH" ? cls.high : ""} ${
                data.priority === "URGENT" ? cls.urgent : ""
              }`}>
              {data.priority}
            </span>
            <span className={cls.department}>{department}</span>
            <span className={cls.date}>{getDate(data.timestamp)}</span>
          </div>
          <span className={cls.icon}>
            <Icon
              icon='ArrowLeft'
              className={cls.arrow}
              viewBox='0 0 320 512'
            />
          </span>
        </div>
        {data.id === ticketId && (
          <div
            className={`${cls.body} ${
              data.id === ticketId ? cls.expanded : ""
            }`}>
            <div className={cls.message}>
              <div className={cls.left}>
                <img src={user?.avatarUrl || "/user-avatar.webp"} alt='' />
              </div>
              <div className={cls.right}>
                <div className={cls.date}>{getDate(data.timestamp)}</div>
                <div className={cls.message}>{data.message}</div>
              </div>
            </div>
            {replies?.length > 0 && (
              <div className={cls.replies}>
                {replies?.map((reply: IChatMessage, index: number) => (
                  <div className={cls.reply} key={reply.unixTimestamp}>
                    <div className={cls.left}>
                      <img src={getUserAvatar(reply.userId)} alt='' />
                    </div>
                    <div className={cls.right}>
                      <div className={cls.date}>
                        {getDate(reply.unixTimestamp)}
                      </div>
                      <div className={cls.message}>{reply.content}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* <div className={cls.messageComposer}>
              {data.status !== "CLOSED" && (
                <MessageSubmitter
                  emojiEnabled={false}
                  submitMessage={handleSubmitReply}
                />
              )}
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketListItem;
