import cls from "./Invoices.module.scss";
import MainBtn from "../common/buttons/mainBtn/MainBtn";
import InvoiceItem from "../invoiceItem/InvoiceItem";

import { auth, firestore } from "../../api/firebase";

// import { ApiEndpoints } from "../../api/endpoints";
import { useEffect, useState } from "react";
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

const Invoices: React.FC = () => {
  const [invoices, setInvoices] = useState<DocumentData>([]);
  const [loading, setLoading] = useState(true);

  // const { post: getInvoices } = useFetch({
  //   path: ApiEndpoints.GET_TRANSACTIONS,
  // });

  useEffect(() => {
    // Check if a user is authenticated
    const user = auth.currentUser;
    if (user) {
      // const userDocRef = doc(firestore, "Users", user.uid);

      // // Fetch the data from the user's document
      // getDoc(userDocRef)
      //   .then((docSnapshot) => {
      //     if (docSnapshot.exists()) {
      //       // Document data exists, store it in state
      //       console.log("User document data:", docSnapshot.data());
      //     } else {
      //       // Document doesn't exist
      //       console.log("User document does not exist.");
      //     }
      //   })
      //   .catch((error) => {
      //     console.error("Error fetching user data:", error);
      //   });

      //////////////////////////

      // Access the Firestore document for the current user
      const paymentsRef = collection(firestore, "Users", user.uid, "payments");
      const paymentsQuery = query(
        paymentsRef,
        where("status", "==", "succeeded")
      );

      getDocs(paymentsQuery)
        .then((querySnapshot) => {
          const invoices = querySnapshot.docs.map((doc) => doc.data());
          invoices.sort((a, b) => b.created - a.created);
          // console.log("invoices: ", invoices);
          setInvoices(invoices);
          // setLoading(false);
        })
        .catch((error) => {
          console.log("Error getting invoices: ", error);
        });
    }
  }, []);

  return (
    <div className={cls.invoices}>
      <table style={{ width: "100%", textAlign: "left" }}>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Order Date</th>
            <th>Total Price</th>
            <th>Discount</th>
            <th>Paid</th>
            {/* <th>Download</th> */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice: any) => {
            const _date = new Date(invoice.created * 1000).toDateString();
            let collapsed = true;

            return <InvoiceItem invoice={invoice} key={invoice.id} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Invoices;
