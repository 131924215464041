import { useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  deleteQuestion,
  editQuestion,
} from "../../../api/classQuestions/classQuestions";
import {
  EReportedContentType,
  EReportType,
  IReport,
  submitReport,
} from "../../../api/report/reportService";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

import { IClass } from "../../../interfaces/IClass";
import { IClassQuestionTopic } from "../../../interfaces/IClassQuestionTopic";
import { QEditPayload, QPayload } from "../../../interfaces/IQuestion";
import { EServiceTier } from "../../../interfaces/IServiceTier";
import { UrlParams } from "../../../interfaces/UrlParams";
import CallToActionPopUp from "../../common/callToActionPopUp/CallToActionPopUp";
import ConfirmationPopUp from "../../common/confirmationPopUp/ConfirmationPopUp";
import EditQuestion from "./EditQuestion";
import cls from "./QuestionModMenu.module.scss";
import ReportTypeSelector from "./ReportTypeSelector";

type Props = {
  edit: boolean;
  remove: boolean;
  notify: boolean;

  // chatMessage?: IChatMessage;
  questionTopic: IClassQuestionTopic;
  classTier: EServiceTier;
};

const ModMenu: React.FC<Props> = ({
  edit,
  remove,
  notify,

  // chatMessage,
  questionTopic,
  classTier,
}) => {
  const routeParameters = useParams<keyof UrlParams>() as UrlParams;
  const { courseId, classId, termId, weekId } = routeParameters;

  const queryClient = useQueryClient();

  const [modMenu, showModMenu] = useState<boolean>(false);

  const modMenuRef = useRef(null);
  const handleClickOutsideModMenu = (e: MouseEvent) => {
    showModMenu(false);
  };
  useOnClickOutside(modMenuRef, handleClickOutsideModMenu);

  const handleShowModMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    showModMenu(!modMenu);
  };

  /////////////// POPUPS ////////////////
  const [editQuestionForm, showEditQuestionForm] = useState<boolean>(false);
  const [successConfirmationUpdated, showSuccessConfirmationUpdated] =
    useState<boolean>(false);

  const [confirmationPopUpDelete, showConfirmationPopUpDelete] =
    useState<boolean>(false);
  const [successConfirmationDeleted, showSuccessConfirmationDeleted] =
    useState<boolean>(false);

  const [submitReportForm, showSubmitReportForm] = useState<boolean>(false);
  const [successConfirmationReported, showSuccessConfirmationReported] =
    useState<boolean>(false);

  const [errorConfirmationRequest, showErrorConfirmationRequest] =
    useState<boolean>(false);

  const invalidateQueries = () => {
    return queryClient.invalidateQueries([
      "class-questions",
      weekId,
      termId,
      classId,
      courseId,
      classTier,
    ]);
  };

  //////////////////////// DELETE QUESTION ////////////////////////

  const deleteQuestionRQ = useMutation(deleteQuestion, {
    onSuccess: () => {
      console.log("Question deleted successfully!");
      showSuccessConfirmationDeleted(true);
    },
    onError: (err) => {
      console.log("error question deleted", err);
      showErrorConfirmationRequest(true);
    },
    onSettled: () => {
      //   setButtonDisabled(false);
    },
  });

  const handleConfirmDeleteQuestion = () => {
    const payload: QPayload = {
      questionId: questionTopic.id,
      classId,
      courseId,
      termId,
      weekId,
    };

    deleteQuestionRQ.mutate(payload);
    console.log(payload);
    showConfirmationPopUpDelete(false);
  };

  const handleDenyConfirmation = () => {
    // showConfirmationPopUp(false);
    showConfirmationPopUpDelete(false);
  };

  //////EDIT QUESTION////////

  const updateQuestionRQ = useMutation(editQuestion, {
    onSuccess: () => {
      console.log("Question updated successfully!");
      showSuccessConfirmationUpdated(true);
    },
    onError: (err) => {
      console.log("error question updated", err);
      showErrorConfirmationRequest(true);
    },
    onSettled: () => {
      //   setButtonDisabled(false);
    },
  });

  const handleEditQuestion = (data: string) => {
    if (!data) return;

    const payload: QEditPayload = {
      questionId: questionTopic.id,
      classId,
      courseId,
      termId,
      weekId,
      description: data,
    };
    updateQuestionRQ.mutate(payload);
    console.log(payload);
    showEditQuestionForm(false);
  };

  //////////////////////// SUBMIT REPORT ////////////////////////

  const handleSubmitReport = async (val: EReportType) => {
    const qID = questionTopic.id;

    let payload: IReport = {
      questionId: qID,
      contentReported: EReportedContentType.CLASS_QUESTION,
      reportTypes: [val],
      classId,
      courseId,
      termId,
      weekId,
    };

    showSubmitReportForm(false);

    await submitReport(payload)
      .then((res) => {
        showSuccessConfirmationReported(true);
      })
      .catch((err) => {
        console.log(err);
        showErrorConfirmationRequest(true);
      });
  };

  if (!notify && !remove && !edit) return null;

  return (
    <div className={cls.root} ref={modMenuRef}>
      {editQuestionForm && (
        <EditQuestion
          width={400}
          questionTopic={questionTopic}
          onSave={handleEditQuestion}
          onDeny={() => showEditQuestionForm(false)}
        />
      )}

      {confirmationPopUpDelete && (
        <CallToActionPopUp
          message='Are you sure you want to delete this question?'
          onConfirm={() => handleConfirmDeleteQuestion()}
          onDeny={() => handleDenyConfirmation()}
        />
      )}

      {successConfirmationDeleted && (
        <ConfirmationPopUp
          maxWidth={400}
          type='success'
          message='Question successfully deleted!'
          onClick={() => [
            showSuccessConfirmationDeleted(false),
            invalidateQueries(),
          ]}
        />
      )}

      {successConfirmationUpdated && (
        <ConfirmationPopUp
          maxWidth={400}
          type='success'
          message='Question successfully Updated!'
          onClick={() => [
            showSuccessConfirmationUpdated(false),
            invalidateQueries(),
          ]}
        />
      )}

      {submitReportForm && (
        <ReportTypeSelector
          onDeny={() => showSubmitReportForm(false)}
          onSubmit={handleSubmitReport}
        />
      )}

      {successConfirmationReported && (
        <ConfirmationPopUp
          maxWidth={400}
          type='success'
          message='Report successfully submitted!'
          onClick={() => showSuccessConfirmationReported(false)}
        />
      )}

      {errorConfirmationRequest && (
        <ConfirmationPopUp
          maxWidth={400}
          type='error'
          message='Something went wrong! Please, try again later!'
          onClick={() => showErrorConfirmationRequest(false)}
        />
      )}

      <div className={cls.dots} onClick={handleShowModMenu}>
        ...
      </div>
      {modMenu && (
        <div className={cls.modList}>
          {edit && (
            <span
              onClick={() => [showEditQuestionForm(true), showModMenu(false)]}>
              - Edit
            </span>
          )}
          {remove && (
            <span
              onClick={() => [
                showConfirmationPopUpDelete(true),
                showModMenu(false),
              ]}>
              - Delete
            </span>
          )}
          {notify && (
            <span
              onClick={() => [showSubmitReportForm(true), showModMenu(false)]}>
              - Notify Admin
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default ModMenu;
